import moment from 'moment';
import { Moment } from 'moment';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';

export class Driver {
  driversLicenseNumber: string;
  externalDriverFlag: boolean;
  id: number;
  object: string;

  private _callInPriority: Moment;
  get callInPriority(): Moment {
    return this._callInPriority;
  }
  set callInPriority(val) {
    this._callInPriority = convertIso8601ToDate(val);
  }

  private _driversLicenseExpireDate: Moment;
  get driversLicenseExpireDate(): Moment {
    return this._driversLicenseExpireDate;
  }
  set driversLicenseExpireDate(val) {
    this._driversLicenseExpireDate = convertIso8601ToDate(val);
  }

  private _preferredTime: Moment;
  get preferredTime(): Moment {
    return this._preferredTime;
  }
  set preferredTime(val) {
    this._preferredTime = convertIso8601ToDate(val);
  }

  private _scheduledCallInTime: Moment;
  get scheduledCallInTime(): Moment {
    return this._scheduledCallInTime;
  }
  set scheduledCallInTime(val) {
    this._scheduledCallInTime = convertIso8601ToDate(val);
  }

  constructor() {
    this.callInPriority = moment();
    this.driversLicenseExpireDate = moment();
    this.driversLicenseNumber = '';
    this.externalDriverFlag = false;
    this.preferredTime = moment();
    this.scheduledCallInTime = moment();
  }

  public getCopy(): Driver {
    return Object.assign(new Driver(), this);
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}
