export class LoginStatusModel {
  authToken: string;
  authTokenExpirationDate: string;
  database: string;
  legacyAccess: boolean;
  loggedIn: boolean;
  serverAccess: boolean;
  username: string;

  constructor() {
    this.clear();
  }

  public clear(): void {
    this.authToken = '';
    this.database = '';
    this.legacyAccess = false;
    this.loggedIn = false;
    this.serverAccess = false;
    this.username = '';
  }

  public copy(): LoginStatusModel {
    return Object.assign(new LoginStatusModel(), this);
  }
}
