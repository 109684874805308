import { AppConfig } from '@app/app.config';
import { HttpService } from '@app/security/shared/http.service';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Observable } from 'rxjs';
import { UserGroup } from '@app/user-groups/user-group.model';
import { publishReplay, refCount } from 'rxjs/operators';

const lgSfx = '\t(user-groups.service)';

@Injectable({ providedIn: 'root' })
export class UserGroupService {
  private loadUserGroupsObserver;

  constructor(
    private config: AppConfig,
    private http: HttpService,
    private log: Logger,
    private loginStatusService: LoginStatusService
  ) {
    this.loginStatusService.loginStatus.subscribe((ls) => {
      this.onLoginStatusChange(ls);
    });
  }

  public getUserGroups(): Observable<Array<UserGroup>> {
    if (typeof this.loadUserGroupsObserver === 'undefined') {
      this.loadUserGroups();
    }

    return this.loadUserGroupsObserver;
  }

  private loadUserGroups(): Observable<Array<UserGroup>> {
    const API_ENDPOINT = '/api/v1/user/groups';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;
    this.log.log(`getting user groups from ${endpoint} ${lgSfx}`);

    this.loadUserGroupsObserver = this.http
      .getList<UserGroup>(endpoint, UserGroup, undefined, true)
      .pipe(publishReplay(1), refCount());

    return this.loadUserGroupsObserver;
  }

  private cleanCache(): void {
    this.loadUserGroupsObserver = undefined;
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    if (loginStatus.loggedIn === false) {
      this.cleanCache();
    }
  }
}
