import { Schedule } from '@app/schedules/shared/schedule.model';
import { ProductEntryViewModel } from '@app/products/models/product-entry.model';

export class ScheduleForm {
  active: boolean;
  extraProducts: Array<ProductEntryViewModel>;
  object: string;
  primaryProduct: ProductEntryViewModel;
  schedule: Schedule;
  ticketIds: number[];
  get id(): number {
    return this.schedule.id || 1;
  }

  constructor() {
    this.active = false;
    this.object = 'scheduleForm';
    this.primaryProduct = new ProductEntryViewModel().getCopy();
    this.schedule = new Schedule().getCopy();
    this.extraProducts = [];
    this.ticketIds = [];
  }

  public getCopy(): ScheduleForm {
    return Object.assign(new ScheduleForm(), this);
  }

  public clearAndReturn(): ScheduleForm {
    this.active = false;
    this.primaryProduct = new ProductEntryViewModel().getCopy();
    this.extraProducts = [];
    this.ticketIds = [];

    return this;
  }

  validate(): string[] {
    let messages = [];
    messages = messages.concat(this.primaryProduct.validate(), this.schedule.validate());
    return messages;
  }
}

export class OriginalScheduleDataContainer {
  scheduleId: number;
  interval: number;
  onJobTime: moment.Moment;
  qtyHr: number;
}
