import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';

@Injectable({ providedIn: 'root' })
export class LoginStatusService {
  public readonly loginStatus: Observable<LoginStatusModel>;

  private loginStatusUpdater: BehaviorSubject<LoginStatusModel>;
  private currentLoginStatus: LoginStatusModel;

  public constructor() {
    this.currentLoginStatus = new LoginStatusModel();
    this.loginStatusUpdater = new BehaviorSubject<LoginStatusModel>(this.currentLoginStatus);
    this.loginStatus = this.loginStatusUpdater.asObservable();
  }

  public canActivate(): boolean {
    return this.currentLoginStatus.loggedIn;
  }

  /**
   * Get the current login status.
   *
   * This method is useful to get the current status of the system, but
   * where the consumer does not need to watch for changes.
   *
   * @return Returns the current status for the system
   */
  public getCurrent(): LoginStatusModel {
    return this.currentLoginStatus.copy();
  }

  /**
   * This method should only be called by the Security Service.
   *
   * Beware that calling this method from anywhere can have unintended
   * consequences if the login status does not represent the current
   * login state.
   *
   * @param loginStatus - current login status of
   *   the system.
   */
  public updateLoginStatus(loginStatus: LoginStatusModel): void {
    this.currentLoginStatus = loginStatus.copy();
    this.loginStatusUpdater.next(this.currentLoginStatus.copy());
  }
}
