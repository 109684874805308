export class ConcretePlant {
  plantId: number;
  numberOfAlleys: number;
  nextTicketNumber: number;
  object: string;

  constructor() {
    this.numberOfAlleys = 1;
    this.nextTicketNumber = 1;
    this.object = 'concretePlant';
  }
}
