<div mat-dialog-title class="d-flex row">
  <h2>{{ data.title }}</h2>
  <button class="ml-auto" mat-button (click)="onNoClick()">
    <mat-icon color="accent" fontIcon="fa-times"></mat-icon>
  </button>
</div>

<div mat-dialog-content style="padding: 4px 8px;">
  <p *ngIf="data.content?.length">{{ data.content }}</p>

  <div *ngIf="data.contentSeparated?.length">
    <p *ngFor="let item of data.contentSeparated">
      {{item}}
    </p>
  </div>
</div>

<div mat-dialog-actions class="px-2">
  <button
    (click)="onNoClick()"
    cdkFocusInitial
    class="ml-auto"
    color="primary"
    mat-raised-button>
    Ok
  </button>
</div>
