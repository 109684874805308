import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { ILegacy, LEGACY_EVENTS, LegacyStatusEventArgs } from '@app/legacy/legacy.interface';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';

const lgSfx = '\t(legacy-skipper.service)';

@Injectable({ providedIn: 'root' })
export class LegacySkipperService implements ILegacy {
  public readonly canOpenTabs = false;
  public connectionUri: string;
  public hubName: string;
  public legacySkippedEvent: Subject<void> = new Subject();
  public status$: Observable<LegacyStatusEventArgs>;

  private events: BehaviorSubject<LegacyStatusEventArgs>;

  constructor(private log: Logger) {
    this.events = new BehaviorSubject<LegacyStatusEventArgs>(
      new LegacyStatusEventArgs({
        event: LEGACY_EVENTS.EVENT_LIS_CONNECTED
      })
    );
    this.status$ = this.events.asObservable();
    this.events.next(new LegacyStatusEventArgs());
  }

  /********************************
   * Methods
   ********************************/

  public authPing(): void {
    this.onAuthPingback(true);
  }

  public authenticate(
    _server: string,
    _database: string,
    username: string,
    _password: string,
    _mqServer: string,
    _authToken: string
  ): Observable<boolean> {
    this.onUserIsAuthenticated(username, true);
    return of(true);
  }

  public authenticateWithToken(_authToken: string): Observable<boolean> {
    return of(true);
  }

  public connect(): Observable<string> {
    this.events.next(
      new LegacyStatusEventArgs({
        event: LEGACY_EVENTS.EVENT_LIS_CONNECTED
      })
    );
    return of('');
  }

  public createTicket(
    _orderId: number,
    _scheduleId: number,
    _scheduleLoadId: number,
    _truckId: number,
    _plantId: number
  ): void {}

  public getCurrentStatus(): LegacyStatusEventArgs {
    return this.events.value;
  }

  public logout(_forceLogout: boolean): void {
    this.onLogout();
  }

  public openForm(formId: string): void {
    this.log.warn(
      `user tried to open legacy form (${formId}) after skipping legacy integration ${lgSfx}`
    );
  }

  public openTab(_routePath: [string]): void {
    this.log.error(`legacySkipper does no support opening new tabs`);
  }

  public queryDirtyFormsCount(): void {
    this.onDirtyFormsCount(0);
  }

  public sendLegacySkippedEvent(): void {
    this.legacySkippedEvent.next();
  }

  public viewTicket(_orderId: number, _ticketId: number): void {}

  /********************************
   * Hub Event Methods
   ********************************/

  private onAuthPingback(userIsAuthenticated: boolean): void {
    this.log.log(`onAuthPingback received with auth status ${userIsAuthenticated} ${lgSfx}`);
    this.events.next(
      new LegacyStatusEventArgs({
        authenticated: userIsAuthenticated,
        event: LEGACY_EVENTS.EVENT_LIS_USER_AUTHENTICATED
      })
    );
  }

  private onDirtyFormsCount(dirtyFormsCount: number): void {
    this.log.log(`onDirtyFormsCount received with dirty forms ${dirtyFormsCount} ${lgSfx}`);
    this.events.next(
      new LegacyStatusEventArgs({
        dirtyFormsCount,
        event: LEGACY_EVENTS.EVENT_LIS_DIRTY_FORMS
      })
    );
  }

  private onLogout(): void {
    this.log.log(`onLogout, user logged out ${lgSfx}`);
    this.events.next(
      new LegacyStatusEventArgs({
        event: LEGACY_EVENTS.EVENT_LIS_USER_LOGGED_OUT
      })
    );
  }

  private onUserIsAuthenticated(username: string, success: boolean): void {
    this.log.log(`UserIsAuthenticated received for user ${username} ${success} ${lgSfx}`);
    this.events.next(
      new LegacyStatusEventArgs({
        authenticated: success,
        event: LEGACY_EVENTS.EVENT_LIS_USER_AUTHENTICATED,
        username
      })
    );
  }
}
