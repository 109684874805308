import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@app/security/shared/authentication.service';
import { AuthorizationService } from '@app/security/shared/authorization.service';
import { ConnectionsService } from '@app/server/shared/connections.service';
import { Injectable } from '@angular/core';
import { LegacyService } from '@app/legacy/legacy.service';
import { Location } from '@angular/common';
import { Logger } from '@app/logger/logger';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Observable, of } from 'rxjs';
import { SecurityService } from '@app/security/shared/security.service';
import {
  URL_PARAM_ADMIN_ONLY,
  URL_PARAM_CONNECT_TO_CLASSIC,
  URL_PARAM_EXPIRES,
  URL_PARAM_TOKEN,
  URL_PARAM_TOKEN_TYPE
} from '@app/shared/services/browser/window.service';
import { boolFromString } from '@app/shared/utilities/type-converter.utility';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private lastRequestedUrl: string;

  constructor(
    private authorizationService: AuthorizationService,
    private authService: AuthenticationService,
    private connectionsService: ConnectionsService,
    private legacyService: LegacyService,
    private location: Location,
    private log: Logger,
    private loginStatusService: LoginStatusService,
    private router: Router,
    private securityService: SecurityService
  ) {}

  public clearRequestedUrl(): void {
    this.lastRequestedUrl = '';
  }

  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.lastRequestedUrl = state.url;
    return this.isUserLoggedIn();
  }

  public canLoad(_route: Route): Observable<boolean> {
    return this.isUserLoggedIn();
  }

  public goToRequestedUrl(): void {
    this.log.log('sending back to last requested URL %s', this.lastRequestedUrl);
    if (this.authService.getOnlyAdminStatus()) {
      this.router.navigate(['/admin-only']);
    } else {
      if (this.lastRequestedUrl) {
        this.authorizationService.loadFormPermissions().subscribe(() => {
          this.router.navigate([this.lastRequestedUrl]);
        });
      } else {
        this.router.navigate(['/home']);
      }
    }
  }

  private isUserLoggedIn(): Observable<boolean> {
    if (this.loginStatusService.getCurrent().loggedIn) {
      return of(true);
    } else {
      const params: any = this.router.parseUrl(this.location.path(true)).queryParams;
      if (Object.keys(params).length !== 0) {
        return this.securityService
          .loginWithToken(
            params[URL_PARAM_TOKEN],
            params[URL_PARAM_TOKEN_TYPE],
            params[URL_PARAM_EXPIRES],
            boolFromString(params[URL_PARAM_ADMIN_ONLY])
          )
          .pipe(
            mergeMap((status: boolean) => {
              if (status && this.authService.token) {
                if (params[URL_PARAM_CONNECT_TO_CLASSIC] === '1') {
                  this.connectionsService.connectToClassic();
                } else {
                  this.legacyService.skipLegacyIntercom();
                }
                return this.authorizationService.loadFormPermissions();
              } else {
                this.goToLogin();
              }
            }),
            mergeMap(() => of(true))
          );
      } else {
        this.goToLogin();
      }
    }
  }

  private goToLogin(): Observable<boolean> {
    this.lastRequestedUrl = this.location.path(true);
    this.router.navigate(['/security/login']);
    return of(false);
  }
}
