import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export interface IForm {
  alwaysShow: boolean;
  availableInQuickMenu: boolean;
  enabled: boolean;
  enumId: string;
  icon: string;
  id: number;
  legacy: boolean;
  longName: string;
  name: string;
  path: string;
  isAdmin: boolean;
  isDirty: boolean;
  faIcon: IconDefinition;
  systemTypeId?: number;
  translationId?: string;
}

export interface FormContructorParams {
  alwaysShow?: boolean;
  availableInQuickMenu?: boolean;
  enabled?: boolean;
  enumId?: string;
  icon?: string;
  id?: number;
  legacy?: boolean;
  longName?: string;
  name?: string;
  path?: string;
  isAdmin?: boolean;
  isDirty?: boolean;
  faIcon?: IconDefinition;
  systemTypeId?: number;
  translationId?: string;
}

export class Form implements IForm {
  public alwaysShow: boolean;
  public availableInQuickMenu: boolean;
  public enabled: boolean;
  public enumId: string;
  public icon: string;
  public id: number;
  public legacy: boolean;
  public longName: string;
  public name: string;
  public path: string;
  public isAdmin: boolean;
  public isDirty: boolean;
  public faIcon: IconDefinition;
  public systemTypeId?: number;
  public translationId?: string;

  constructor(params: FormContructorParams) {
    this.alwaysShow = params.alwaysShow || false;
    this.availableInQuickMenu = params.availableInQuickMenu || false;
    this.enabled = params.enabled || false;
    this.enumId = params.enumId;
    this.icon = params.icon || '';
    this.id = params.id;
    this.legacy = params.legacy || false;
    this.longName = params.longName || params.name;
    this.name = params.name;
    this.path = params.path;
    this.isAdmin = params.isAdmin || false;
    this.isDirty = params.isDirty || false;
    this.faIcon = params.faIcon;
    this.translationId = params.translationId || this.name;
    this.systemTypeId = params.systemTypeId;
  }
}
