import moment from 'moment';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

export const validateNotNullFields = (
  object: Object,
  notNullFields: string[],
  outputName?: string
): string[] => {
  const messages = [];
  const name = outputName || object.constructor.name;

  for (const field in notNullFields) {
    if (!hasValue(object[notNullFields[field]])) {
      messages.push(`${name} contains a null value at ${notNullFields[field]}`);
    }
  }
  return messages;
};

export const isValidEmailCsv = (emailCsv: string): boolean =>
  emailCsv
    .split(',')
    .map((email: string) => Validators.email({ value: email?.trim() } as AbstractControl))
    .find((_: ValidationErrors) => _ !== null) === undefined;

export const isValidEmail = (email: string): boolean =>
  !hasValue(Validators.email({ value: email?.trim() } as AbstractControl));

export const notInPast = (control: AbstractControl): ValidationErrors | null => {
  const providedDate = control.value;
  const currentDate = moment();

  if (providedDate && providedDate.isBefore(currentDate, 'day')) {
    return { notInPast: true };
  }

  return null;
};
