/* eslint-disable  @typescript-eslint/no-restricted-imports */
import { Level } from '../app/logger/level';

export const environment = {
  autoLogin: false,
  envName: 'production',
  logger: {
    level: Level.WARN,
    global: true,
    globalAs: 'logger',
    store: false,
    storeAs: 'angular2.logger.level'
  },
  production: true,
  showFormsInDevelopment: false,
  version: '2.62.1'
};
