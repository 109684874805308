import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'acs-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html'
})
export class YesNoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      content: string;
      confirmText: string;
      denyText: string;
      includeCancel: boolean;
      disableClose: boolean;
    },
    private dialogRef: MatDialogRef<YesNoDialogComponent>
  ) {
    this.dialogRef.disableClose = this.data.disableClose;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
