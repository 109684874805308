export class UserGroup {
  id: number;
  object: string;
  userProfileId: number;
  groupName: string;

  constructor(id?: number, userProfileId?: number) {
    if (id) {
      this.id = id;
    }

    if (userProfileId) {
      this.userProfileId = userProfileId;
    }
  }
}
