export class TruckSequence {
  id: number;
  inYardSequenceNum: number;

  /**
   * The new index in the tiy grid for the truck this sequence object is related to.
   * This field is not used by AU2. It is sent in the MQ message so maybe classic
   * uses it?
   */
  newRowNum: number;

  object: string;
  wasMoved: boolean;

  constructor() {
    this.object = 'truckSequence';
    this.wasMoved = false;
  }
}

export class TruckSequencesUpdate {
  plantId: number;
  trucks: Array<TruckSequence>;
  object = 'truckSequenceUpdate';
}
