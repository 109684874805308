import { ISchedule } from '@app/schedules/shared/schedule.interface';
import { Moment } from 'moment';
import { ProductEntryViewModel } from '@app/products/models/product-entry.model';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';
import { validateNotNullFields } from '@app/shared/forms/validation-helpers.utility';

export class Schedule implements ISchedule {
  id: number; // int NOT NULL,
  adjustmentTypeId: number; // int NULL,
  addPlusLoad: boolean; // boolean NOT NULL,
  orderId: number; // int NOT NULL,
  loadIntervalTime: number; // int NOT NULL,
  numberOfLoads: number; // int NULL,
  numberOfTrucks: number; // int NOT NULL,
  onJobWaitMinutes: number; // smallint NOT NULL,
  onJobWashMinutes: number; // smallint NOT NULL
  orderStatusId: number; // int NULL,
  plantId: number; // int NOT NULL,
  quantityPerHour: number; // numeric(18, 2) NOT NULL,
  scheduleProducts: ProductEntryViewModel[];
  systemTypeId: number; // int NOT NULL,
  travelTimeToJob: number; // int NOT NULL,
  travelTimeToPlant: number; // int NOT NULL,
  truckTypeId: number; // int NULL,
  updateScheduleLoadsParamValue: number;

  private _firstLoadOnJobTime: Moment; // datetime NULL,
  get firstLoadOnJobTime(): Moment {
    return this._firstLoadOnJobTime;
  }
  set firstLoadOnJobTime(val) {
    this._firstLoadOnJobTime = convertIso8601ToDate(val);
  }

  constructor() {
    this.adjustmentTypeId = 1;
    this.onJobWaitMinutes = 0;
    this.onJobWashMinutes = 0;
    this.travelTimeToPlant = 0;
    this.truckTypeId = 0;
  }

  public getCopy(): Schedule {
    return Object.assign(new Schedule(), this);
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }

  validate(): string[] {
    return validateNotNullFields(
      this,
      [
        'loadIntervalTime',
        'numberOfTrucks',
        'onJobWaitMinutes',
        'onJobWashMinutes',
        'plantId',
        'quantityPerHour',
        'systemTypeId',
        'travelTimeToJob',
        'travelTimeToPlant'
      ],
      'Schedule'
    );
  }
}
