import { HttpErrorResponse } from '@angular/common/http';

export class ServerError {
  public data: any;
  public description: string;
  public message: string;
  public stacktrace: string;
  public errorTypeId: number;
  public responseError: HttpErrorResponse;

  constructor(props: any) {
    if (typeof props === 'undefined') {
      return;
    } else if (typeof props === 'string') {
      this.description = props;
    } else if (typeof props === 'object') {
      this.loadFromObject(props);
    }
  }

  private loadFromObject(props: any): void {
    if (props instanceof HttpErrorResponse) {
      this.responseError = props;
      this.message = props.message;
    } else if (props instanceof ErrorEvent) {
      this.message = props.message;
      this.stacktrace = `${props.filename} ln. ${props.lineno}`;
    } else {
      this.data = props.data;
      this.description = props.description;
      this.message = props.message;
      this.stacktrace = props.stacktrace;
      this.responseError = props.responseError;
      this.errorTypeId = props.errorTypeId;
    }
  }
}
