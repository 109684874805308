import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { Form } from '@app/forms/form.model';

// map for audit order form ids and system types
export const AO_SYSTEM_TYPE_FORM_MAP = new Map<string, Form>([
  ['1', AUJS_FORMS.FormNgAuditOrderConcrete],
  ['2', AUJS_FORMS.FormNgAuditOrderAggregate],
  ['3', AUJS_FORMS.FormNgAuditOrderBlock]
]);

export const ORDER_SYSTEM_TYPE_FORM_MAP = new Map<string, Form>([
  ['1', AUJS_FORMS.FormNgOrderEntryConcrete],
  ['2', AUJS_FORMS.FormNgOrderEntryAggregate],
  ['3', AUJS_FORMS.FormNgOrderEntryBlock]
]);
