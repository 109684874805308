import { IDb } from '@app/server/shared/db.interface';

export class Db implements IDb {
  database: string;
  server: string;
  data: any;

  constructor(database?: string, server?: string, data?: any) {
    this.database = database;
    this.server = server;
    this.data = data;
  }
}
