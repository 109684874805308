import moment from 'moment';
import { AuthenticationService } from '@app/security/shared/authentication.service';
import { DbService } from '@app/server/shared/db.service';
import { HttpOptionsService } from '@app/security/shared/http.service';
import { IConnectionInfo } from '@app/server/models/connection-info.model';
import { IDb } from '@app/server/shared/db.interface';
import { IForceLogoutMessage, LogoutTypeEnum } from '@app/dispatcher/dispatch.model';
import { Injectable } from '@angular/core';
import { LEGACY_EVENTS, LegacyStatusEventArgs } from '@app/legacy/legacy.interface';
import { LegacyService } from '@app/legacy/legacy.service';
import { LocalStorageRefService } from '@app/shared/services/browser/local-storage-ref.service';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, of, throwError } from 'rxjs';
import { YesNoDialogComponent } from '@app/shared/dialogs/yes-no-dialog.component';
import { filter, first, mergeMap, skip, timeout } from 'rxjs/operators';
import { DispatchService } from '@app/dispatcher/dispatch.service';

const lgSfx = '\t(security.service.ts)';

@Injectable({ providedIn: 'root' })
export class SecurityService {
  private _authToken: string | undefined;
  private _authTokenExpirationDate: string;
  private _database: string;
  private _loggedIn = false;
  private _programNameId: string;
  private _reconnectListener: Subscription;
  private _username: string;
  private _loginStatus: LoginStatusModel;

  get programName(): string {
    return this._programNameId;
  }

  constructor(
    private authService: AuthenticationService,
    private dispatchService: DispatchService,
    private dbService: DbService,
    private defaultRequestOptionsService: HttpOptionsService,
    private legacyService: LegacyService,
    private localStorageRefService: LocalStorageRefService,
    private log: Logger,
    private loginStatusService: LoginStatusService,
    private matDialog: MatDialog
  ) {
    this._loginStatus = new LoginStatusModel();

    this._database = this.localStorageRefService.localStorage.getItem('database');
    this._username = this.localStorageRefService.localStorage.getItem('username');

    this.legacyService.service.status$
      .pipe(
        filter(
          (args: LegacyStatusEventArgs) => args.event === LEGACY_EVENTS.EVENT_LIS_USER_LOGGED_OUT
        )
      )
      .subscribe(() => {
        this.clearOutUserLogin();
      });

    this.dispatchService.dispatchForceLogoutSub.subscribe((logoutMessage: IForceLogoutMessage) => {
      if (logoutMessage.logoutType === LogoutTypeEnum.seatId) {
        this.forceLogout(false, logoutMessage.identifier);
      } else if (logoutMessage.logoutType === LogoutTypeEnum.none) {
        this.forceLogout(false);
      }
    });
  }

  public clearOutUserLogin(clearLoginStatus = true): void {
    if (this._reconnectListener !== undefined) {
      this._reconnectListener.unsubscribe();
      this._reconnectListener = undefined;
    }
    this._loggedIn = false;
    this._authToken = undefined;
    this.setAuthorizationHeader('');

    if (clearLoginStatus) {
      this._loginStatus.clear();

      this.loginStatusService.updateLoginStatus(this._loginStatus);
    }
  }

  public getDatabase(): string {
    return this._database;
  }

  public getUserLoggedIn(): boolean {
    return this._loggedIn;
  }

  public getUsername(): string {
    return this._username;
  }

  public login(user, password, database): Observable<boolean> {
    this.log.info(`logging in user (${user}) ${lgSfx}`);

    if (!user || !database) {
      return throwError('Please provide a username and database to login with');
    }

    this.setUsername(user);
    this.setDatabase(database);

    // Authenticate with the IIS server.

    return this.authService
      .authenticate(this._username, password, database, this.dispatchService.getConnectionId())
      .pipe(
        mergeMap((status: boolean) => {
          if (status) {
            this.log.log(
              `User authenticated with authService; getting database (${database}) ${lgSfx}`
            );
            // Now get the database information to pass back to the legacy service.
            return this.dbService.getDatabase(database).pipe(
              mergeMap((dbData: IDb) => {
                if (dbData) {
                  this.onAuthenticationDatabaseReceived(dbData, password);
                  return of(true);
                } else {
                  return this.onAuthenticationDatabaseRetrievalErred(database);
                }
              })
            );
          } else {
            this.clearOutUserLogin();
            return throwError('User Login Failed');
          }
        })
      );
  }

  public loginWithToken(
    token: string,
    tokenType: string,
    expires: number,
    adminOnly: boolean
  ): Observable<any> {
    return this.authService.authenticateWithTokenInfo(token, tokenType, expires, adminOnly).pipe(
      first(),
      mergeMap((res: IConnectionInfo) => {
        if (res.loggedIn === true) {
          this._authToken = this.authService.token;
          this.setAuthorizationHeader(this.authService.tokenType + ' ' + this._authToken);

          this._loggedIn = true;

          this._loginStatus.username = res.username;
          this._loginStatus.loggedIn = true;
          this._loginStatus.authToken = token;
          this._loginStatus.authTokenExpirationDate = moment()
            .add(expires, 'seconds')
            .format('LLL');
          this._loginStatus.database = res.database;
          this._loginStatus.serverAccess = true;
          this._loginStatus.legacyAccess = false;

          this.loginStatusService.updateLoginStatus(this._loginStatus);
          this.log.log(`Watching for Legacy Status changed ${lgSfx}`);
          this.legacyService.service.status$
            .pipe(
              filter((args: LegacyStatusEventArgs) => {
                this.log.log(`Legacy Status changed to ${args.event} ${lgSfx}`);
                return args.event === LEGACY_EVENTS.EVENT_LIS_CONNECTED;
              }),
              first()
            )
            .subscribe(() => {
              this.legacyService.service
                .authenticateWithToken(this._authToken)
                .pipe(first())
                .subscribe();
            });
          return of(true);
        } else {
          this.clearOutUserLogin();
          return of(false);
        }
      })
    );
  }

  /**
   * Generic logout method. Should be called in most 'logout' cases such
   * as user selecting to logout from the toolbar.
   */
  public logout(): void {
    this.checkForDirtyFormsAndLogout();
  }

  /**
   * Forces a logout to occur by skipping any checks for
   * dirty forms and immediately navigating user to login
   * screen.
   */
  public forceLogout(logoutAll = false, seatToken?: string): void {
    if (seatToken) {
      if (this.localStorageRefService.seatToken === seatToken) {
        this.finalizeLogout(logoutAll);
      }
    } else {
      this.finalizeLogout(logoutAll);
    }
  }

  private setDatabase(db): void {
    this._database = !db ? '' : db.trim();
    this.localStorageRefService.localStorage.setItem('database', this._database);
  }

  private setUsername(user): void {
    this._username = user;
    this.localStorageRefService.localStorage.setItem('username', this._username);
  }

  private verifyUserIsAuthenticated(): void {
    this.log.info(`verifying LIS auth status ${lgSfx}`);
    if (this._loggedIn) {
      this.authService.authenticateWithCurrentTokenInfo().subscribe(() => {
        this.legacyService.service.status$.pipe(skip(1), timeout(5000), first()).subscribe(
          (args: LegacyStatusEventArgs) => {
            if (args.authenticated) {
              this.log.info(`user is authenticated with LIS ${lgSfx}`);
              this.legacyService.service.authPing();
            } else {
              this.logout();
            }
          },
          (error) => {
            this.log.error(`Authentication failed with an error of : ${error.message}`);
            this.logout();
          }
        );

        this.legacyService.service
          .authenticateWithToken(this._authToken)
          .pipe(first())
          .subscribe(() => {});
      });
    }
  }

  private onAuthenticationDatabaseRetrievalErred(database): Observable<any> {
    this.log.error(
      `The server had an error trying to retrieve the database (${database}) ${lgSfx}`
    );
    this.clearOutUserLogin();
    return throwError(
      'The server had an error trying to retrieve the database list. ' +
        'Please try again or contact Jonel for assistance.'
    );
  }

  private onAuthenticationDatabaseReceived(dbData: IDb, password): void {
    this.log.log(`database received; setting auth header ${lgSfx}`);
    this._authToken = this.authService.token;
    this._authTokenExpirationDate = this.authService.tokenExpirationDate;
    this.setAuthorizationHeader(this.authService.tokenType + ' ' + this._authToken);
    this.watchForAuthenticateWithLegacy();

    this.legacyService.service
      .authenticate(
        dbData.server,
        dbData.database,
        this._username,
        password,
        this.authService.mqServer,
        this._authToken
      )
      .pipe(first())
      .subscribe(() => {});
  }

  private watchForAuthenticateWithLegacy(): void {
    this.legacyService.service.status$.pipe(timeout(2000), skip(1), first()).subscribe(
      (args: LegacyStatusEventArgs) => {
        this.log.log(`legacy integrator verified credentials ${lgSfx}`);
        if (args.authenticated) {
          this.onUserLoggedInToLegacy({
            username: args.username,
            loggedIn: args.authenticated
          });
        } else {
          this.log.warn(`legacy integrator denied user credentials; resetting user info ${lgSfx}`);
          this.clearOutUserLogin();
          throwError('The server erred trying to get the database list');
        }
      },
      () => {
        this.log.log(`legacy integrator access timeout out; clearing credentials ${lgSfx}`);
        this.clearOutUserLogin();
        throwError('The connection to Classic AUJS time out. Please try again.');
      }
    );
  }

  private onUserLoggedInToLegacy(lisUserData): void {
    if (lisUserData.username.toLowerCase() === this._username.toLowerCase()) {
      this.log.log(`Legacy Intercom Service authenticated the user ${lgSfx}`);
      this._loggedIn = true;
      this.setUsername(this._username);

      this._loginStatus.authToken = this._authToken;
      this._loginStatus.authTokenExpirationDate = this._authTokenExpirationDate;
      this._loginStatus.database = this._database;
      this._loginStatus.legacyAccess = true;
      this._loginStatus.loggedIn = lisUserData.loggedIn;
      this._loginStatus.serverAccess = true;
      this._loginStatus.username = this._username;

      this.loginStatusService.updateLoginStatus(this._loginStatus);

      this._reconnectListener = this.legacyService.service.status$
        .pipe(
          filter((args: LegacyStatusEventArgs) => args.event === LEGACY_EVENTS.EVENT_LIS_CONNECTED)
        )
        .subscribe(() => {
          this.verifyUserIsAuthenticated();
        });
    } else {
      this.log.warn(
        `legacy integrator username doesn't match the provided; resetting credentials ${lgSfx}`
      );
      this.clearOutUserLogin();
      throwError('The server user does not match the provided username');
    }
  }

  private finalizeLogout(logoutAll = false): void {
    console.log('finalize logout called with', logoutAll);
    this.legacyService.service.logout(true);

    if (logoutAll) {
      this.authService.logoutAll().subscribe();
    }

    this.clearOutUserLogin();
  }

  private checkForDirtyFormsAndLogout(): void {
    this.legacyService.service.status$
      .pipe(
        timeout(5000),
        filter((args: LegacyStatusEventArgs) => args.event === LEGACY_EVENTS.EVENT_LIS_DIRTY_FORMS)
      )
      .pipe(first())
      .subscribe(
        (args: LegacyStatusEventArgs) => {
          if (args.dirtyFormsCount === 0) {
            this.finalizeLogout();
          } else {
            this.log.info(`logout canceled due to dirty forms ${lgSfx}`);

            this.matDialog
              .open(YesNoDialogComponent, {
                data: {
                  title: 'Confirm Logout',
                  content: 'You have unsaved windows open. Do you want to logoff without saving?'
                }
              })
              .afterClosed()
              .subscribe((res: boolean) => {
                if (res) {
                  this.finalizeLogout();
                }
              });
          }
        },
        (error: any) => {
          this.log.error('error getting dirty forms for user', error);
        }
      );

    this.legacyService.service.queryDirtyFormsCount();
  }

  private setAuthorizationHeader(auth): void {
    this.defaultRequestOptionsService.setAuthorization(auth);
  }
}
