/**
 * Convert a true/false string to an actual boolean value.
 * If an invalid value is provided false is returned.
 */
export const boolFromString = (toConvert: 'false' | 'true'): boolean => {
  if (toConvert === 'false') {
    return false;
  }

  if (toConvert === 'true') {
    return true;
  }

  return false;
};
