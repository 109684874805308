import { UnitOfMeasure } from '@app/products/models/unit-of-measure.model';

export class TicketProduct {
  aggDeliveryChargeTypeId: number;
  deliveredQuantity: number;
  object: string;
  price: number;
  productDescription: string;
  productId: number;
  productNumber: string;
  productTypeId: number;
  quantity: number;
  slump: number;
  taxableFlag: boolean;
  ticketId: number;
  unusedQuantity: number;
  unitOfMeasure: UnitOfMeasure;

  constructor() {
    this.object = 'ticketProduct';
    this.productDescription = '';
    this.productId = 0;
    this.productNumber = '';
  }
}
