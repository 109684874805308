import { AccountingExportResponse } from '@app/accounting/models/accounting-export-response.model';
import { Observable } from 'rxjs';
import { OrderUpdateModel } from '@app/dispatcher/models/order-update.model';
import { Plant } from '@app/plants/shared/plant.model';
import { QuickLaunchSetting } from '@app/shared/models/quick-launch-setting.model';
import { RecordLock } from '@app/record-locks/shared/record-locks.model';
import { ScheduleLoad } from '@app/schedules/shared/schedule-load.model';
import { TicketUpdateData } from '@app/ticketing/models/ticket-update-data.model';
import { TicketUpdateModel } from '@app/dispatcher/models/ticket-update.model';
import { TruckSequencesUpdate } from '@app/truck-tracking/trucks-in-yard/truck-sequence.model';
import { TruckUpdateModel } from '@app/dispatcher/models/truck-update.model';

export interface CustomReportNotification {
  data: {
    requestId: string;
    path: string;
    status: string;
    requestType: string;
    recipient: string;
    reportId: string;
  };
  message: string;
}

export enum DISPATCHER_EVENTS {
  CONNECTED = 'dispatcher:connected',
  CONNECTING = 'dispatcher:connecting',
  DISCONNECTED = 'dispatcher:disconnected'
}

export interface IDispatcher {
  loadEvents: Observable<LoadUpdate>;
  orderEvents: Observable<OrderUpdate>;
  recordLockEvents: Observable<RecordLock>;
  recordLockErrorEvents: Observable<any>;
  scheduleStatusAverageEvents: Observable<ScheduleStatusAveragesUpdate>;
  statusEvents: Observable<string>;
  ticketEvents: Observable<TicketUpdate>;
  truckEvents: Observable<TruckUpdate>;

  connect(): Observable<any>;
  getConnectionId(): string;
  getConnectionIdAsync(): Observable<string>;
  getStatus(): DISPATCHER_EVENTS;
  lockRecord(table: string, id: number, username: string): Observable<any>;
  logoff(): void;
  watchAllDispatchEvents(): Observable<any>;
}

export interface ScheduleStatusAveragesUpdate {
  data: any;
  message: string;
}

export interface PlantUpdate {
  data: Plant;
  message: string;
}

export interface LoadUpdate {
  data: ScheduleLoad;
  message: string;
}

export interface OrderUpdate {
  data: OrderUpdateModel;
  message: string;
}

export interface LoadScheduleUpdateNotification {
  data: number[];
  message: string;
}

export interface IScaleUpdate {
  data: {
    id: string;
    value: string;
    status: string;
  };
  message: string;
}

export interface IStringMessagePayload {
  data: string;
  message: string;
}

export interface IForceLogoutMessage {
  logoutType: LogoutTypeEnum;
  identifier: string;
}

export enum LogoutTypeEnum {
  none = 'none',
  seatId = 'seatId'
}

export enum ScaleStatus {
  ok = 'Ok',
  motion = 'Motion',
  unknown = 'Unknown',
  invalid = 'Invalid'
}

export interface TicketPrintReply {
  data: {
    ticketId: number;
    requestId: string;
    successFlag: boolean;
    responseMessage: string;
  };
  message: string;
}

export interface TicketUpdate {
  data: TicketUpdateModel;
  message: string;
}

export interface TruckUpdate {
  data: TruckUpdateModel;
  message: string;
}

export interface TruckSequenceUpdate {
  data: TruckSequencesUpdate;
  message: string;
}

export interface QuickLaunchFormUpdate {
  data: QuickLaunchSetting;
  message: string;
}

export interface AccountingExportComplete {
  data: AccountingExportResponse;
  message: string;
}

export interface AccountingExportFailed {
  data: {
    requestId: string;
    message: string;
  };
  message: string;
}

export interface TicketModifiedNotification {
  data: TicketUpdateData;
  message: string;
}
