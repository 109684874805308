<div mat-dialog-title class="d-flex align-items-center justify-content-between">
  <h2 class="text-left flex-grow-1">{{ data.title }}</h2>
  <button class="mat-button-close" mat-button (click)="onNoClick()" *ngIf="data.totalProgress === 100">
    <mat-icon color="accent" fontIcon="fa-times"></mat-icon>
  </button>
</div>



<div mat-dialog-content class="d-flex flex-column">
  <mat-list *ngIf="data.statusItems">
    <mat-list-item *ngFor="let item of data.statusItems; trackBy: getIdentifierForIterableItem">
      <!-- use matListItemAvatar here because matListItemIcon overwrites color styles! -->
      <mat-icon color="primary"
        matListItemAvatar
        class="d-flex justify-content-center align-items-center"
        [ngClass]="{
            'fa-check-square-o': item.status === 'success',
            'fa-circle-o-notch': item.status === 'waiting',
            'fa-exclamation mat-warn': item.status === 'error',
            'fa-exclamation-circle mat-warn': !item.status
        }">
      </mat-icon>
      <div matListItemTitle>{{ item.title }}</div>
    </mat-list-item>
  </mat-list>

  <div class="progress-text" [ngClass]="{ 'mat-warn': data.hasError }">{{ data.totalProgress }}%</div>

  <mat-progress-bar
    mode="determinate"
    value="{{ data.totalProgress }}"
    [ngClass]="{'mat-warn': data.hasError}">
  </mat-progress-bar>
</div>

<div mat-dialog-actions *ngIf="data.buttons.length" class="row justify-content-center">
  <button
    (click)="button.execute()"
    *ngFor="let button of data.buttons; trackBy: getIdentifierForIterableItem"
    mat-raised-button
    class="m-h"
    color="primary">{{ button.title | uppercase }}
  </button>
</div>
