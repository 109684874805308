import { Hub } from '@app/server/shared/hub.model';
import { HubOption } from '@app/server/shared/hub-option.model';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { filter } from 'rxjs/operators';

declare const $: any;

export const HUB_CONNECTION_STATES = {
  0: 'connecting',
  1: 'connected',
  2: 'reconnecting',
  4: 'disconnected'
};

export const HUB_CONNECTION_EVENTS = {
  change: 'hub:connection:change',
  error: 'hub:connection:error',
  disconnected: 'hub:connection:disconnected',
  reconnected: 'hub:connection:reconnected',
  reconnecting: 'hub:connection:reconnecting'
};

const lgSfx = '\t(signalr-hub.service)';

@Injectable({ providedIn: 'root' })
export class SignalRHubService {
  public hub: Hub;

  constructor(private log: Logger) {}

  public createHub(hubName: string, options: HubOption): Hub {
    this.hub = new Hub(hubName, options);

    // Create and set the connection property
    this.hub.connection = $.hubConnection(this.hub.settings.connectionPath);

    // Set Logging
    this.hub.connection.logging = this.hub.settings.loggingEnabled;

    // Create and set the Hub Proxy
    this.hub.proxy = this.hub.connection.createHubProxy(hubName);

    // Bind to connection events , this is only done once.
    this.bindConnectionEvents(this.hub);

    return this.hub;
  }

  /**
   * Bind to the connection events.
   * This is a private method, we use it to bind the hubs connection events when constructed.
   */
  bindConnectionEvents(hubInstance): void {
    hubInstance.connectionEvents
      .pipe(filter((event) => event === HUB_CONNECTION_EVENTS.change))
      .subscribe((_event: string, args: any) => {
        const state = args[2];
        const reconnecting = state.newState === $.signalR.connectionState.reconnecting;
        const disconnected = state.newState === $.signalR.connectionState.disconnected;

        // Update the connection status on the hub, when the state changes
        hubInstance.connectionStatus.setConnection(reconnecting, disconnected).subscribe();
      });

    // Bind to the connection reconnecting event
    hubInstance.connection.reconnecting(() => {
      this.broadcastConnectionEvent(
        hubInstance,
        HUB_CONNECTION_EVENTS.reconnecting,
        undefined,
        undefined,
        undefined
      );
    });

    // Bind to the connection reconnected event
    hubInstance.connection.reconnected(() => {
      this.broadcastConnectionEvent(
        hubInstance,
        HUB_CONNECTION_EVENTS.reconnected,
        undefined,
        undefined,
        undefined
      );
    });

    // Bind to the connection disconnected event
    hubInstance.connection.disconnected(() => {
      this.broadcastConnectionEvent(
        hubInstance,
        HUB_CONNECTION_EVENTS.disconnected,
        undefined,
        undefined,
        undefined
      );
    });

    // Bind to the connection error event
    hubInstance.connection.error((error, data) => {
      this.broadcastConnectionEvent(
        hubInstance,
        HUB_CONNECTION_EVENTS.error,
        undefined,
        data,
        error
      );
    });

    // Bind to the connection change event
    hubInstance.connection.stateChanged((state) => {
      this.log.info(
        `Hub (${hubInstance.connection.url}) changed from state (${
          HUB_CONNECTION_STATES[state.oldState]
        }) ${''} to (${HUB_CONNECTION_STATES[state.newState]}) ${lgSfx}`
      );
      this.broadcastConnectionEvent(
        hubInstance,
        HUB_CONNECTION_EVENTS.change,
        undefined,
        state,
        undefined
      );
    });
  }

  /**
   * Broadcast the desired connection event
   */
  public broadcastConnectionEvent(
    hubInstance: any,
    event: string,
    data: any,
    state: object,
    error: object
  ): void {
    hubInstance.connectionEvents.next({
      data,
      error,
      event,
      connectionId: hubInstance.connection.id,
      hubUrl: hubInstance.connection.url,
      state
    });
  }
}
