export const getIdentifierForIterableItem = (index, item): string | number => {
  if (item.id !== undefined) {
    return item.id;
  } else if (item.uuid !== undefined) {
    return item.uuid;
  }

  return index;
};

export const getObjectId = (item: any): string | number => item.id;

export const getObjectUuid = (item: any): string => item.uuid;
