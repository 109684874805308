import { Moment } from 'moment';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';

export class Ticket {
  aggregateMoistureGallons: number;
  alley: number;
  batchmanId: number;
  cartageLodRate: number;
  codAmount: number;
  cylinderTestFlag: boolean;
  driverNotes: string;
  dueOnJob: string;
  id: number;
  inventoryTransactionId: number;
  loadNumber: number;
  manual: boolean;
  manualTareWeightFlag: boolean;
  maxAllowableWaterGallons: number;
  nonWorkDayAmount: number;
  nonWorkDayFactor: number;
  number: number;
  object: string;
  orderId: number;
  plantId: number;
  preparedForBillingFlag: boolean;
  scheduleId: number;
  scaleId: number;
  specifiedDriverId: number;
  statusId: number;
  tareWeight: number;
  truckCompanyId: number;
  truckId: number;
  truckNumber: number;
  truckTypeAmount: number;
  truckTypeFactor: number;
  userName: string;
  userNameVoid: string;
  voidStatusId: number;
  waterAdded: number;
  waterAdded2: number;
  zoneReferenceNumber: string;

  private _arrivePlant: Moment;
  get arrivePlant(): Moment {
    return this._arrivePlant;
  }
  set arrivePlant(val) {
    this._arrivePlant = convertIso8601ToDate(val);
  }

  private _batch: Moment;
  get batch(): Moment {
    return this._batch;
  }
  set batch(val) {
    this._batch = convertIso8601ToDate(val);
  }

  private _beginTest: Moment;
  get beginTest(): Moment {
    return this._beginTest;
  }
  set beginTest(val) {
    this._beginTest = convertIso8601ToDate(val);
  }

  private _endTest: Moment;
  get endTest(): Moment {
    return this._endTest;
  }
  set endTest(val) {
    this._endTest = convertIso8601ToDate(val);
  }

  private _finishUnload: Moment;
  get finishUnload(): Moment {
    return this._finishUnload;
  }
  set finishUnload(val) {
    this._finishUnload = convertIso8601ToDate(val);
  }

  private _loaded: Moment;
  get loaded(): Moment {
    return this._loaded;
  }
  set loaded(val) {
    this._loaded = convertIso8601ToDate(val);
  }

  private _onJob: Moment;
  get onJob(): Moment {
    return this._onJob;
  }
  set onJob(val) {
    this._onJob = convertIso8601ToDate(val);
  }

  private _print: Moment;
  get print(): Moment {
    return this._print;
  }
  set print(val) {
    this._print = convertIso8601ToDate(val);
  }

  private _startUnload: Moment;
  get startUnload(): Moment {
    return this._startUnload;
  }
  set startUnload(val) {
    this._startUnload = convertIso8601ToDate(val);
  }

  private _suspend: Moment;
  get suspend(): Moment {
    return this._suspend;
  }
  set suspend(val) {
    this._suspend = convertIso8601ToDate(val);
  }

  private _tareWeightDateTime: Moment;
  get tareWeightDateTime(): Moment {
    return this._tareWeightDateTime;
  }
  set tareWeightDateTime(val) {
    this._tareWeightDateTime = convertIso8601ToDate(val);
  }

  private _ticket: Moment;
  get ticket(): Moment {
    return this._ticket;
  }
  set ticket(val) {
    this._ticket = convertIso8601ToDate(val);
  }

  private _ticketReceivedDate: Moment;
  get ticketReceivedDate(): Moment {
    return this._ticketReceivedDate;
  }
  set ticketReceivedDate(val) {
    this._ticketReceivedDate = convertIso8601ToDate(val);
  }

  private _toJob: Moment;
  get toJob(): Moment {
    return this._toJob;
  }
  set toJob(val) {
    this._toJob = convertIso8601ToDate(val);
  }

  private _toPlant: Moment;
  get toPlant(): Moment {
    return this._toPlant;
  }
  set toPlant(val) {
    this._toPlant = convertIso8601ToDate(val);
  }

  private _voidDateTime: Moment;
  get voidDateTime(): Moment {
    return this._voidDateTime;
  }
  set voidDateTime(val) {
    this._voidDateTime = convertIso8601ToDate(val);
  }

  constructor() {
    this.object = 'ticket';
    this.inventoryTransactionId = 0;
    this.truckId = undefined;
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}
