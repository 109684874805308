import { UnitOfMeasure } from '@app/products/models/unit-of-measure.model';
import { validateNotNullFields } from '@app/shared/forms/validation-helpers.utility';

export class ProductEntryViewModel {
  blockQuantityIsCurrent: boolean;
  deliveredQuantity: number;
  loadPlan: string;
  loadSize: number;
  orderId: number;
  price: number;
  productDescription: string;
  productId: number;
  productNumber: string;
  productTypeId: number;
  quantity: number;
  quantityOnHand: number;
  scheduleId: number;
  slump: number;
  systemTypeId: number;
  taxableFlag: boolean;
  ticketed: boolean;
  unitOfMeasure: UnitOfMeasure;
  unitsPerCube: number;
  usageTypeId: number;

  constructor() {
    this.slump = 0;
    this.deliveredQuantity = 0;
  }

  public getCopy(): ProductEntryViewModel {
    return Object.assign(new ProductEntryViewModel(), this);
  }

  validate(): string[] {
    return validateNotNullFields(this, ['productId', 'systemTypeId'], 'Product');
  }
}
