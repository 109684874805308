<div mat-dialog-title class="d-flex justify-content-between align-items-center">
  <h2 class="flex-grow-1">{{ data.title }}</h2>
  <button *ngIf="!data.disableClose" mat-icon-button (click)="onNoClick()" tabindex="-1">
    <mat-icon color="accent" fontIcon="fa-times"></mat-icon>
  </button>
</div>

<div mat-dialog-content class="p-3">
  <p [innerHTML]="data.content | htmlSafe"></p>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button [mat-dialog-close]="true" color="primary">
    {{ data.confirmText ? data.confirmText : 'Yes' }}
  </button>
  <button mat-raised-button [mat-dialog-close]="false">
    {{ data.denyText ? data.denyText : 'No' }}
  </button>
  <button mat-raised-button (click)="onNoClick()" *ngIf="data.includeCancel">Cancel</button>
</div>
