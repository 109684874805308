import { IDisplayItem } from '@app/shared/models/display-item.interface';
import { Moment } from 'moment';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';

export class Truck implements IDisplayItem {
  defaultDriverId: number;
  description: string;
  encoderNumber: string;
  equipmentId: number;
  id: number;
  identificationNumber: string;
  inServicePlantId: number;
  inYardSequenceNum: number;
  inactiveFlag: boolean;
  lastDriverId: number;
  licensePlate: string;
  manualTareWeightFlag: boolean;
  maxLoadQuantity: number;
  minutes: number;
  mobileSimulationFlag: boolean;
  object: string;
  outsideTruckFlag: boolean;
  plantId: number;
  scaleId: number;
  statusDescription: string;
  statusId: number;
  statusLocation: string;
  tareWeight: number;
  truckCompanyId: number;
  truckFlag: string;
  truckFlagId: number;
  truckTypeId: number;

  get isTareWeightValid(): boolean {
    return this.tareWeight && this.tareWeight > 0;
  }

  get autoCompleteDisplay(): string {
    return this.identificationNumber;
  }

  get identifier(): number {
    return this.id;
  }

  private _modifiedDateTime: Moment;
  get modifiedDateTime(): Moment {
    return this._modifiedDateTime;
  }
  set modifiedDateTime(val) {
    this._modifiedDateTime = convertIso8601ToDate(val);
  }

  private _statusDateTime: Moment;
  get statusDateTime(): Moment {
    return this._statusDateTime;
  }
  set statusDateTime(val) {
    this._statusDateTime = convertIso8601ToDate(val);
  }

  private _tareWeightDateTime: Moment; // datetime NULL,
  get tareWeightDateTime(): Moment {
    return this._tareWeightDateTime;
  }
  set tareWeightDateTime(val) {
    this._tareWeightDateTime = convertIso8601ToDate(val);
  }

  constructor() {
    this.inactiveFlag = false;
    this.outsideTruckFlag = false;
    this.mobileSimulationFlag = false;
    this.truckFlagId = 0;
    this.truckFlag = '';
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }

  public getCopy(): Truck {
    return Object.assign(new Truck(), this);
  }
}
