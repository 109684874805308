import { AuthenticationService } from '@app/security/shared/authentication.service';
import { ENUM_ID_Q_PARAM, FORM_ID_HEADER } from '@app/shared/constants/constants';
import { IForm } from '@app/forms/form.model';
import { Injectable } from '@angular/core';
import { LegacyService } from '@app/legacy/legacy.service';
import { Logger } from '@app/logger/logger';
import { Observable, fromEvent } from 'rxjs';
import { ScreenSizeTracker } from '@app/shared/models/screen-size-tracker.model';
import { UriService } from '@app/shared/services/uri.service';
import { WindowRefService } from '@app/shared/services/browser/window-ref.service';
import { debounceTime, map } from 'rxjs/operators';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

export const URL_PARAM_TOKEN = 'tk';
export const URL_PARAM_TOKEN_TYPE = 'tt';
export const URL_PARAM_EXPIRES = 'tkex';
export const URL_PARAM_HIDE_MENU = 'hm';
export const URL_PARAM_CONNECT_TO_CLASSIC = 'ctc';
/**
 * Parameter to mark user as admin only or not. Only used by the receiving window
 * so it doesn't have to wait on the server to get admin only info. This helps fix some
 * race conditions such as rendering the wrong set of menu items for admin only users.
 * (In the future this could also maybe be better done with a better use
 * of reactive programming to watch for `AuthenticationService.adminOnly` changes)
 *
 * The server still performs admin only auth on the requests so the user cannot spoof the value!
 */
export const URL_PARAM_ADMIN_ONLY = 'ao';
export const SMALL_SCREEN_PX = 576;
export const MEDIUM_SCREEN_PX = 768;

@Injectable({ providedIn: 'root' })
export class WindowService {
  public screenSize = new ScreenSizeTracker();
  private currentWindowPinned = false;

  public get isMobile(): boolean {
    const navigatorMobileCheck = navigator['userAgentData']?.mobile;

    if (hasValue(navigatorMobileCheck)) {
      return navigatorMobileCheck;
    }

    // assumes based on screen size
    return this.screenSize.screenWidth <= MEDIUM_SCREEN_PX;
  }

  public get isSmallDevice(): boolean {
    return this.screenSize.screenWidth <= MEDIUM_SCREEN_PX;
  }

  public get hasTouchCapabilities(): boolean {
    return !!navigator.maxTouchPoints;
  }

  constructor(
    private authService: AuthenticationService,
    private legacyService: LegacyService,
    private log: Logger,
    private uriService: UriService,
    private windowRef: WindowRefService
  ) {}

  public appendTokenCredentialsToUrl(uri: string): string {
    let newUri = uri;

    newUri = this.uriService.appendParameterToUrl(newUri, URL_PARAM_TOKEN, this.authService.token);

    newUri = this.uriService.appendParameterToUrl(
      newUri,
      URL_PARAM_ADMIN_ONLY,
      this.authService.adminOnly.toString()
    );

    newUri = this.uriService.appendParameterToUrl(
      newUri,
      URL_PARAM_TOKEN_TYPE,
      this.authService.tokenType
    );

    newUri = this.uriService.appendParameterToUrl(
      newUri,
      URL_PARAM_EXPIRES,
      this.authService.expires.toString()
    );

    newUri = this.uriService.appendParameterToUrl(
      newUri,
      URL_PARAM_CONNECT_TO_CLASSIC,
      this.legacyService.isConnectingToClassic() ? '1' : '0'
    );

    return newUri;
  }

  public openCurrentViewInNewTab(form: IForm): void {
    const currentUrl = form.path;
    let currentUri: string;

    if (currentUrl) {
      currentUri = currentUrl;
    } else {
      currentUri = this.windowRef.window.location.href;
    }

    this.log.info(`user: request to open new window`, currentUri);

    currentUri = this.formatUri(currentUri, form);
    this.windowRef.window.open(currentUri);
  }

  public openUrlInNewWindow(currentUrl: string, form: IForm): void {
    let currentUri: string;

    if (currentUrl) {
      currentUri = currentUrl;
    } else {
      currentUri = this.windowRef.window.location.href;
    }

    this.log.info(`user: request to open new window`, currentUri);
    currentUri = this.formatUri(currentUri, form);
    this.windowRef.window.open(currentUri);
  }

  public open(url: string): void {
    this.log.info(`user: request to open new window`, url);
    this.windowRef.window.open(url);
  }

  public formatUri(currentUri: string, form: IForm): string {
    if (form) {
      currentUri = this.uriService.appendParameterToUrl(currentUri, FORM_ID_HEADER, `${form.id}`);
      currentUri = this.uriService.appendParameterToUrl(
        currentUri,
        ENUM_ID_Q_PARAM,
        `${form.enumId}`
      );
    }

    if (
      !currentUri.includes(`${URL_PARAM_TOKEN}=`) &&
      !currentUri.includes(`${URL_PARAM_EXPIRES}=`) &&
      !currentUri.includes(`${URL_PARAM_CONNECT_TO_CLASSIC}=`)
    ) {
      currentUri = this.appendTokenCredentialsToUrl(currentUri);
      currentUri = this.uriService.appendParameterToUrl(currentUri, URL_PARAM_HIDE_MENU, '1');
    }

    return currentUri;
  }

  public toggleWindowPin(): void {
    this.currentWindowPinned = !this.currentWindowPinned;
    this.log.info(`current window set to ${this.currentWindowPinned ? 'pinned' : 'unpinned'}`);
  }

  public isCurrentWindowPinned(): boolean {
    return this.currentWindowPinned;
  }

  public onWindowResize(): Observable<any> {
    return fromEvent(window, 'resize')
      .pipe(
        map(() => {
          this.screenSize.screenHeight = document.documentElement.clientHeight;
          this.screenSize.screenWidth = document.documentElement.clientWidth;
          return document.documentElement.clientWidth + ',' + document.documentElement.clientHeight;
        })
      )
      .pipe(debounceTime(250));
  }
}
