import { AppConfig } from '@app/app.config';
import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { BooleanResponse } from '@app/shared/models/boolean-response.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Form } from '@app/forms/form.model';
import {
  FORM_PERMISSIONS,
  FormPermission,
  IFormPermission,
  FORM_PERMISSIONS_SYMBOL
} from '@app/security/shared/form-permission.model';
import { HttpService } from '@app/security/shared/http.service';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { MatDialog } from '@angular/material/dialog';
import { OkDialogComponent } from '@app/shared/dialogs/ok-dialog.component';

import { SystemType } from '@app/shared/services/system-type/system-type.service';
import { first, map, publishReplay, refCount } from 'rxjs/operators';

const lgSfx = '\t(authorization.service)';
const PERMISSION_DIALOG_ID = 'pdid';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  public readonly formPermissionsLoadedObserver: Observable<boolean>;

  private formPermissions = Array<IFormPermission>();
  private formPermissionsLoadedUpdater: BehaviorSubject<boolean>;
  private isPermissionsLoaded: boolean;
  private loadFormsObserver: Observable<Array<IFormPermission>>;

  public get waitFormPermissionsLoaded$(): Observable<boolean> {
    return this.formPermissionsLoadedObserver.pipe(first((isLoaded) => isLoaded));
  }

  constructor(
    private config: AppConfig,
    private http: HttpService,
    private log: Logger,
    private loginStatusService: LoginStatusService,
    private matDialog: MatDialog
  ) {
    this.isPermissionsLoaded = false;
    this.formPermissionsLoadedUpdater = new BehaviorSubject<boolean>(this.isPermissionsLoaded);
    this.formPermissionsLoadedObserver = this.formPermissionsLoadedUpdater.asObservable();

    this.loginStatusService.loginStatus.subscribe((loginStatus: LoginStatusModel) => {
      this.onLoginStatusChange(loginStatus);
    });
  }

  public getFormPermission(formId: number): IFormPermission {
    const permission = this.formPermissions.find(
      (formPermission) => formPermission.formId === formId
    );

    if (typeof permission === 'undefined' || permission === null) {
      return this.createFormPermission(formId, FORM_PERMISSIONS.None);
    } else {
      return permission;
    }
  }

  public loadFormPermissions(): Observable<Array<IFormPermission>> {
    this.log.log(`Loading user form permissions from the server  ${lgSfx}`);

    const API_ENDPOINT = '/api/formauthorization';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    if (this.loadFormsObserver) {
      return this.loadFormsObserver;
    }

    this.loadFormsObserver = this.http.getAny(endpoint).pipe(
      map((data) => {
        // Clear the old permissions and copy in the new permissions.
        this.formPermissions.splice(0, this.formPermissions.length);
        this.formPermissions = data.map((forms) =>
          this.createFormPermission(forms.FormId, forms.PermissionId)
        );

        this.isPermissionsLoaded = true;
        this.formPermissionsLoadedUpdater.next(this.isPermissionsLoaded);

        return this.formPermissions;
      }),
      publishReplay(1),
      refCount()
    );

    return this.loadFormsObserver;
  }

  public resolve(): Observable<IFormPermission[]> {
    return this.loadFormPermissions();
  }

  public getAdministrationStatus(): Observable<boolean> {
    const API_ENDPOINT = '/api/v1/sysadmin';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http
      .get<BooleanResponse>(endpoint, BooleanResponse)
      .pipe(map((resp) => resp.value));
  }

  public getFormPermissions(formId: number): IPermission {
    const formPermission = this.getFormPermission(formId).permission;
    let readOnlyMode = false;
    let hidePrice = false;
    let disablePrice = false;
    let none = false;
    let full = false;

    if (
      formPermission === FORM_PERMISSIONS.ReadOnlyNoPrices ||
      formPermission === FORM_PERMISSIONS.ReadOnlyWithPrices
    ) {
      readOnlyMode = true;
    }
    if (
      formPermission === FORM_PERMISSIONS.ReadOnlyNoPrices ||
      formPermission === FORM_PERMISSIONS.FullNoPrices
    ) {
      hidePrice = true;
    }
    if (
      formPermission === FORM_PERMISSIONS.ReadOnlyWithPrices ||
      formPermission === FORM_PERMISSIONS.FullWithReadOnlyPrices
    ) {
      disablePrice = true;
    }
    if (formPermission === FORM_PERMISSIONS.None) {
      none = true;
    }

    full = !readOnlyMode && !none;

    return { formPermission, readOnlyMode, hidePrice, disablePrice, none, full };
  }

  public getMaxPermissionForm(formIds: number[]): IFormPermission {
    let maxPermission: IFormPermission;
    for (const formId of formIds) {
      const foundPermission = this.getFormPermission(formId);

      if (foundPermission.permission === FORM_PERMISSIONS_SYMBOL.FP) {
        return foundPermission;
      }

      if (!maxPermission || maxPermission.permission < foundPermission.permission) {
        maxPermission = foundPermission;
      }
    }

    return maxPermission;
  }

  public getOrderFormForSystemType(systemTypeId: number): Form {
    return systemTypeId === SystemType.Concrete.id
      ? AUJS_FORMS.FormOrderEntryConcrete
      : systemTypeId === SystemType.Aggregate.id
      ? AUJS_FORMS.FormNgOrderEntryAggregate
      : AUJS_FORMS.FormOrderEntryBlock;
  }

  public getAuditOrderFormForSystemType(systemTypeId: number): Form {
    return systemTypeId === SystemType.Concrete.id
      ? AUJS_FORMS.FormNgAuditOrderConcrete
      : systemTypeId === SystemType.Aggregate.id
      ? AUJS_FORMS.FormNgAuditOrderAggregate
      : AUJS_FORMS.FormNgAuditOrderBlock;
  }

  public getTicketingFormForSystemType(systemTypeId: number): Form {
    return systemTypeId === SystemType.Concrete.id
      ? AUJS_FORMS.FormNgConcreteTicketing
      : systemTypeId === SystemType.Aggregate.id
      ? AUJS_FORMS.FormNgAggregateTicketing
      : AUJS_FORMS.FormNgBlockTicketing;
  }

  public hasGreaterThanNone(formPermission: IFormPermission): boolean {
    return formPermission.permission !== FORM_PERMISSIONS.None;
  }

  public displayInsufficientPermissionDialog(info = ''): void {
    if (!this.matDialog.getDialogById(PERMISSION_DIALOG_ID)) {
      this.matDialog.open(OkDialogComponent, {
        data: {
          title: 'Warning',
          content: `You do not have permissions to access this form ${info}`
        },
        id: PERMISSION_DIALOG_ID
      });
    }
  }

  private createFormPermission(formId: number, permissionId: number): IFormPermission {
    if (permissionId < 0 || permissionId > 6) {
      this.log.warn(`Trying to create a permission with an invalid permission ID ${lgSfx}`);
      permissionId = FORM_PERMISSIONS.None;
    }

    return new FormPermission(formId, permissionId);
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    if (loginStatus.loggedIn === true) {
      this.loadFormPermissions().pipe(first()).subscribe();
    } else {
      this.cleanCache();
    }
  }

  private cleanCache(): void {
    this.formPermissions = [];
    this.isPermissionsLoaded = false;
    this.loadFormsObserver = undefined;
    this.formPermissionsLoadedUpdater.next(this.isPermissionsLoaded);
  }
}

export interface IPermission {
  formPermission: number;
  readOnlyMode: boolean;
  hidePrice: boolean;
  disablePrice: boolean;
  none: boolean;
  full: boolean;
}
