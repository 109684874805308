import moment from 'moment';
import { IOrder } from '@app/orders/models/order.interface';
import { ISelectable } from '@app/shared/models/selectable.interface';
import { Job } from '@app/jobs/models/job.model';
import { Moment } from 'moment';
import { ORDER_STATUS } from '@app/orders/shared/order-status.service';
import { Schedule } from '@app/schedules/shared/schedule.model';
import { SystemParameter } from '@app/system-parameters/shared/parameter.model';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';
import { validateNotNullFields } from '@app/shared/forms/validation-helpers.utility';
import { SystemType } from '@app/shared/services/system-type/system-type.service';

export class Order implements IOrder, ISelectable {
  id: number; // int NOT NULL,
  aggDeliveryChargeTypeId: number; // int NULL,
  authorizedPurchaserId: number; // int NULL,
  batchIdentifier: string; // varchar(30) NULL,
  batchNotes: string; // varchar(255) NULL,
  blockReturnOrderFlag: number; // smallint NOT NULL,
  cartageRate: number; // numeric(18, 2) NULL,
  changeAuthorizerName: string; // varchar(50) NULL,
  chargeOrderFlag: boolean; // bit NOT NULL,
  creditNotes: string; // varchar(255) NULL,
  customerId: number; // int NOT NULL,
  customerName: string; // varchar(100) NULL,
  customerPoNumber: string; // varchar(50) NULL,
  dispatchNotes: string; // varchar(255) NULL,
  emailAddressCsv: string; // varchar (300)
  freeShortLoadId: number; // int NOT NULL,
  hoursToJob: number; // smallint NULL,
  isReviewedForBilling: boolean; // bit NOT NULL,
  imperialFlag: boolean; // bit NOT NULL,
  invoicedFlag: boolean; // bit NOT NULL,
  job: Job;
  jobId: number; // int NOT NULL,
  milesToJob: number; // smallint NULL,
  notes: string; // varchar(255) NULL,
  number: number; // int NOT NULL,
  orderFlagId: number; // int NULL, this is used to store the color
  orderLotOrBlockNumber: string; // varchar(100) NULL,
  orderStatusId: number; // int NOT NULL,
  orderTypeId: number; // int NOT NULL CONSTRAINT DF_Customer_Order_Order_Type_Id  DEFAULT ((1)),
  orderVerifiedFlag: boolean; // bit NOT NULL CONSTRAINT DF__Customer___Order__55F58EC9  DEFAULT ((0)),
  orderedByName: string; // varchar(100) NULL,
  originalInvoice: number; // int NULL,
  plantId: number; // int NOT NULL,
  printMaterialTypeId: number; // int NOT NULL,
  pumperId: number; // int NULL,
  publicWorksOrderConfirmationFlag: boolean;
  reasonId: number; // int NOT NULL CONSTRAINT DF__customer___Reaso__3AAC9BB0  DEFAULT ((0)),
  receiverName: string; // varchar(100) NULL,
  schedules: Schedule[];
  shortLoadTypeId: number; // int NOT NULL,
  sourceTaxingType: number; // int NOT NULL CONSTRAINT DF__Customer___Sourc__52CF095B  DEFAULT ((0)),
  standbyChargeTypeId: number; // int NOT NULL,
  suburbId: number; // int NULL,
  systemTypeId: number; // int NULL,
  ticketNotes: string; // varchar(255) NULL,
  uploadMixToBatchPanel: number; // int NOT NULL CONSTRAINT Customer_Order_DC_UploadMixToBatchPanel  DEFAULT ((0)),
  zoneReferenceNumber: string; // varchar(100) NULL,

  // optional
  selected?: boolean;

  private _orderDate: Moment; // datetime NOT NULL,
  get orderDate(): Moment {
    return this._orderDate;
  }
  set orderDate(val) {
    this._orderDate = convertIso8601ToDate(val);
  }

  private _shipDate: Moment; // datetime NOT NULL,
  get shipDate(): Moment {
    return this._shipDate;
  }
  set shipDate(val) {
    this._shipDate = convertIso8601ToDate(val);
  }

  constructor() {
    this.aggDeliveryChargeTypeId = 1;
    this.authorizedPurchaserId = 0;
    this.batchNotes = '';
    this.blockReturnOrderFlag = 0;
    this.cartageRate = 0;
    this.changeAuthorizerName = '';
    this.chargeOrderFlag = true;
    this.customerPoNumber = '';
    this.dispatchNotes = '';
    this.freeShortLoadId = 1;
    this.hoursToJob = 0;
    this.imperialFlag = true;
    this.invoicedFlag = false;
    this.milesToJob = 0;
    this.notes = '';
    this.orderDate = moment();
    this.orderLotOrBlockNumber = '';
    this.orderStatusId = 0;
    this.orderTypeId = 0;
    this.orderVerifiedFlag = false;
    this.orderedByName = '';
    this.printMaterialTypeId = 1;
    this.publicWorksOrderConfirmationFlag = false;
    this.pumperId = 0;
    this.reasonId = 0;
    this.schedules = [];
    this.shortLoadTypeId = 0;
    this.sourceTaxingType = 0;
    this.standbyChargeTypeId = 1;
    this.suburbId = 0;
    this.uploadMixToBatchPanel = 0;
    this.zoneReferenceNumber = '';
  }

  public getStatusForCopy(): number {
    if (this.orderStatusId === ORDER_STATUS.WILL_CALL) {
      return ORDER_STATUS.WILL_CALL;
    }

    return ORDER_STATUS.TO_BE_SHIPPED;
  }

  public getCopy(): Order {
    return Object.assign(new Order(), this);
  }

  public toJSON(): Object {
    return convertDateToIsoString(this);
  }

  public validate(): string[] {
    const nonBlockFields = ['freeShortLoadId', 'shortLoadTypeId', 'printMaterialTypeId'];

    let orderFieldsToValidate = [
      'blockReturnOrderFlag',
      'chargeOrderFlag',
      'customerId',
      'customerPoNumber',
      'imperialFlag',
      'invoicedFlag',
      'orderDate',
      'orderStatusId',
      'orderTypeId',
      'orderVerifiedFlag',
      'plantId',
      'reasonId',
      'shipDate',
      'sourceTaxingType',
      'standbyChargeTypeId',
      'uploadMixToBatchPanel'
    ];

    if (this.systemTypeId !== 3) {
      orderFieldsToValidate = [...orderFieldsToValidate, ...nonBlockFields];
    }

    return validateNotNullFields(this, orderFieldsToValidate, 'Order');
  }

  public static createForOrderEntry(
    params: SystemParameter,
    systemTypeId: number,
    currentShipDate: Moment,
    statusSetting?: number,
    shipDateSetting?: any,
    plantSetting?: any,
    deliveryChargeType?: number
  ): Order {
    const order = new Order();

    order.systemTypeId = systemTypeId;
    order.orderStatusId = statusSetting || ORDER_STATUS.TO_BE_SHIPPED;

    if (systemTypeId === SystemType.Aggregate.id && deliveryChargeType) {
      order.aggDeliveryChargeTypeId = deliveryChargeType;
    }

    if (params.forceUserToEnterOeShipDate) {
      order.shipDate = undefined;
    } else if (shipDateSetting) {
      order.shipDate = moment();
    } else {
      order.shipDate = currentShipDate;
    }

    if (plantSetting) {
      order.plantId = plantSetting;
    }

    if (params.sourceTaxingFlag) {
      order.sourceTaxingType = 1;
    }

    order.printMaterialTypeId = params.coePrintMaterialTypeDefault;

    return order;
  }
}
