import { Observable } from 'rxjs';

export enum LEGACY_EVENTS {
  EVENT_LIS_USER_AUTHENTICATED = 'legacy:userAuthenticated',
  EVENT_LIS_USER_LOGGED_OUT = 'legacy:userLoggedOut',
  EVENT_LIS_CONNECTED = 'legacy:connected',
  EVENT_LIS_CONNECTING = 'legacy:connecting',
  EVENT_LIS_DISCONNECTED = 'legacy:disconnected',
  EVENT_LIS_DIRTY_FORMS = 'legacy:dirtyForms'
}

export class LegacyStatusEventArgs {
  authenticated: boolean;
  dirtyFormsCount: number;
  event: LEGACY_EVENTS;
  username: string;

  constructor(init?: Partial<LegacyStatusEventArgs>) {
    Object.assign(this, init);
  }
}

export interface ILegacy {
  readonly canOpenTabs: boolean;
  connectionUri: string;
  hubName: string;
  status$: Observable<LegacyStatusEventArgs>;

  authenticate(
    server: string,
    database: string,
    username: string,
    password: string,
    mqServer: string,
    authToken: string
  ): Observable<boolean>;
  authenticateWithToken(authToken: string): Observable<boolean>;
  authPing(): void;
  connect(): Observable<string>;
  createTicket(
    orderId: number,
    scheduleId: number,
    scheduleLoadId: number,
    truckId: number,
    plantId: number
  ): void;
  getCurrentStatus(): LegacyStatusEventArgs;
  logout(forceLogout: boolean): void;
  openForm(formId: string): void;
  openTab(routePath: [string]): void;
  queryDirtyFormsCount(): void;
  viewTicket(orderId: number, ticketId: number): void;
}
