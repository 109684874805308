import { Component, DoCheck, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { IStatusItem } from '@app/shared/dialogs/status-item.interface';
import { LegacySkipperService } from '@app/legacy/legacy-skipper.service';
import { Logger } from '@app/logger/logger';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

const lgSfx = '\tstatus-modal.service';

@Component({
  selector: 'acs-status-modal',
  templateUrl: 'status-modal.component.html',
  styleUrls: ['./status-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusModalComponent implements DoCheck, OnInit {
  public dismissObservable: any;
  public getIdentifierForIterableItem = getIdentifierForIterableItem;
  private localProgress = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<StatusModalComponent>,
    private legacySkipperService: LegacySkipperService,
    private log: Logger
  ) {}

  public ngOnInit(): void {
    this.data.hasError = false;
    this.data.running = true;
    this.data.statusType = 'info';
    this.data.totalProgress = 0;
  }

  public ngDoCheck(): void {
    this.reCheckStatus();
  }

  public reCheckStatus(): void {
    const hasErrors = this.evaluateProgress(this.data.statusItems);
    if (this.data.totalProgress === 100 && this.data.totalProgress !== this.localProgress) {
      this.localProgress = this.data.totalProgress;
      this.data.running = false;

      if (hasErrors) {
        this.data.hasError = true;
        if (this.dismissObservable) {
          this.close();
          return;
        }
      }

      if (this.canCloseModal()) {
        this.dismissObservable = interval(this.data.autoCloseDelay).pipe(
          takeWhile(() => this.data.parent.isOpen)
        );
        this.close();
      }
    }
  }

  public canCloseModal(): boolean {
    const hasErrors = this.evaluateProgress(this.data.statusItems);
    const values: Array<number> = this.data.statusItems.map((item: IStatusItem) =>
      this.getStatusItemProgressValue(item)
    );
    const sum = values.reduce((acc: number, current: number) => acc + current);
    const total = sum / this.data.statusItems.length;

    return (!hasErrors || (this.data.buttons && this.data.buttons.length === 0)) && total === 100;
  }

  public close(): void {
    this.dismissObservable.subscribe(() => {
      this.dismissObservable = null;
      if (this.canCloseModal()) {
        this.log.log(`Closing modal with title '${this.data.title}' ${lgSfx}`);
        this.data.parent.close();
      }
    });
  }

  public evaluateProgress(items: Array<IStatusItem>): boolean {
    if (items.length === 0) {
      this.data.totalProgress = 0;
      return false;
    }

    const hasErrors = items.reduce(this.findIfAnyItemHasAnError, false);
    const values: Array<number> = items.map((item: IStatusItem) =>
      this.getStatusItemProgressValue(item)
    );
    const total = values.reduce((acc: number, current: number) => acc + current);
    this.data.totalProgress = Math.floor(total / items.length);

    return hasErrors;
  }

  public findIfAnyItemHasAnError(hasErrors: boolean, item: IStatusItem): boolean {
    return hasErrors || item.progress === -1;
  }

  public getStatusItemProgressValue(item: IStatusItem): number {
    if (item.progress < 0) {
      this.data.statusType = 'danger';
      return 100;
    } else if (item.progress > 100) {
      if (this.data.statusType !== 'danger') {
        this.data.statusType = 'success';
      }
      return 100;
    } else {
      this.data.statusType = 'info';
    }

    return item.progress;
  }

  public onNoClick(): void {
    if (this.data.parent?.legacyFailed) {
      this.legacySkipperService.sendLegacySkippedEvent();
    }

    if (hasValue(this.data?.parent?.isOpen)) {
      this.data.parent.isOpen = false;
    }

    this.dialogRef.close();
  }
}
