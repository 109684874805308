export const hasValue = (value) => typeof value !== 'undefined' && value !== null;

export const hasDate = (value) =>
  hasValue(value) && typeof value === 'object' && value._isAMomentObject;

export const areFalseyEqual = (compare1, compare2) => {
  const value1 = compare1 || undefined;
  const value2 = compare2 || undefined;

  return value1 === value2;
};
