import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export const getLocalStorage = (): Storage => localStorage;

export const REPORT_REFRESH_STORAGE_KEY = 'reportRefresh';
export const LOG_TYPE_CHANGE_KEY = 'pltc'; // production log type change

const LANGUAGE = 'language';
const SEAT_TOKEN = 'seatToken';
const SEAT_TOKEN_TIME_STAMP = 'seatTokenTimeStamp';

@Injectable({ providedIn: 'root' })
export class LocalStorageRefService {
  public storageEventSubjectContainer: Map<string, Subject<any>> = new Map();
  private storageEventListenerKeyMap: Map<string, any> = new Map();

  get localStorage(): Storage {
    return getLocalStorage();
  }

  get language(): string {
    const language = getLocalStorage().getItem(LANGUAGE);

    if (!language) {
      return '';
    }

    return language;
  }

  set language(lang: string) {
    getLocalStorage().setItem(LANGUAGE, lang);
  }

  get seatToken(): string {
    const seatToken = getLocalStorage().getItem(SEAT_TOKEN);

    if (!seatToken) {
      return '';
    }

    return seatToken;
  }

  set seatToken(token: string) {
    getLocalStorage().setItem(SEAT_TOKEN, token);
  }

  get seatTokenTimeStamp(): string {
    return getLocalStorage().getItem(SEAT_TOKEN_TIME_STAMP);
  }

  set seatTokenTimeStamp(token: string) {
    getLocalStorage().setItem(SEAT_TOKEN_TIME_STAMP, token);
  }

  set reportRefresh(value: any) {
    getLocalStorage().setItem(REPORT_REFRESH_STORAGE_KEY, value);
  }

  public setGenericStorageValue(key: string, value: any): void {
    if (key) {
      getLocalStorage().setItem(key, value);
    }
  }

  public getGenericStorageValue<T>(key: string): T {
    return getLocalStorage().getItem(key) as T;
  }

  public addListenerForKey(key: string): void {
    const eventListenerHandler = this.keyEventListener(key);
    this.storageEventSubjectContainer.set(key, new Subject());
    this.storageEventListenerKeyMap.set(key, eventListenerHandler);
    window.addEventListener('storage', eventListenerHandler);
  }

  public removeEventListenerForKey(key: string): void {
    const handlerToRemove = this.storageEventListenerKeyMap.get(key);

    if (handlerToRemove) {
      this.storageEventListenerKeyMap.delete(key);
      window.removeEventListener('storage', handlerToRemove);
    }

    this.storageEventSubjectContainer.delete(key);
  }

  private keyEventListener(key: string): (event: StorageEvent) => void {
    const handler = (event: StorageEvent) => {
      if (event.key === key) {
        this.storageEventSubjectContainer.get(key)?.next(event.newValue);
      }
    };

    return handler;
  }
}
