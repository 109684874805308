import moment from 'moment';
import { AppConfig } from '@app/app.config';
import { ConnectionInfo, IConnectionInfo } from '@app/server/models/connection-info.model';
import { Injectable } from '@angular/core';
import { HttpOptionsService, HttpService } from '@app/security/shared/http.service';
import { LocalStorageRefService } from '@app/shared/services/browser/local-storage-ref.service';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Observable, Subject, throwError } from 'rxjs';
import { TokenLoginRaw } from '@app/security/token-login.model';
import { catchError, map } from 'rxjs/operators';

const lgSfx = '\t(auth.service.js)';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private retryAuthCount = 0;
  public adminOnly: boolean;
  public expires: number;
  public mqServer: string;
  public retryAuth = new Subject<void>();
  public token: string;
  public tokenExpirationDate: string;
  public tokenType: string;

  private localLogoutNotifier = new Subject<void>();

  constructor(
    private http: HttpService,
    private config: AppConfig,
    private localStorageService: LocalStorageRefService,
    private log: Logger,
    private loginStatusService: LoginStatusService,
    private optionsService: HttpOptionsService
  ) {
    this.loginStatusService.loginStatus.subscribe((loginStatus: LoginStatusModel) => {
      if (loginStatus.loggedIn === false) {
        this.cleanCache();
      }
    });
  }

  public authenticateWithTokenInfo(
    newToken: string,
    newTokenType: string,
    newExpires: number,
    adminOnly: boolean
  ): Observable<IConnectionInfo> {
    this.expires = newExpires;
    this.token = newToken;
    this.tokenType = newTokenType;
    this.adminOnly = adminOnly;

    this.optionsService.setAuthorization(`${this.tokenType} ${this.token}`);

    const API_ENDPOINT = '/api/v1/connection';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http.get<IConnectionInfo>(endpoint, ConnectionInfo);
  }

  public authenticateWithCurrentTokenInfo(): Observable<boolean> {
    return this.authenticateWithTokenInfo(
      this.token,
      this.tokenType,
      this.expires,
      this.adminOnly
    ).pipe(
      map(
        (result) =>
          result.database &&
          result.database.trim().length > 0 &&
          result.username &&
          result.username.trim().length > 0
      )
    );
  }

  public authenticate(
    userId: string,
    password: string,
    database: string,
    connectionId: string
  ): Observable<boolean> {
    if (!userId || !database) {
      return throwError('Please provide a user ID and select a database.');
    }

    const requestData = {
      client_id: userId,
      client_secret: password,
      grant_type: 'client_credentials'
    };

    const API_ENDPOINT = '/token';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;
    const body = this.transformRequest(requestData);

    const options = this.optionsService.get();
    options.headers = options.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    options.headers = options.headers.set('database', database);
    options.headers = options.headers.set('connection_id', connectionId);
    options.headers = options.headers.set('seat_token', this.localStorageService.seatToken);

    return this.http.postAny(endpoint, body, options, true).pipe(
      map((resp: TokenLoginRaw) => {
        this.adminOnly = resp.adminOnly;
        this.token = resp.access_token;
        this.expires = resp.expires_in;
        this.mqServer = resp.mqServer;
        this.tokenExpirationDate = moment().add(this.expires, 'seconds').format('LLL');
        this.tokenType = resp.token_type;
        if (this.token) {
          this.log.info(`User login success ${lgSfx}`);
          this.retryAuthCount = 0;
          return true;
        } else {
          this.log.warn(`User login failed. Status: ${status} ${lgSfx}`);
          return false;
        }
      }),
      catchError((err) => {
        const errorType = err.responseError?.error.error;
        if (errorType === 'missing_token_error' || errorType === 'token_error') {
          if (this.retryAuthCount === 0) {
            this.retryAuth.next();
            this.retryAuthCount++;
            return throwError('Attempting Authentication...');
          } else {
            return throwError(
              'An error ocurred communicating with the server. Verify credentials and try again'
            );
          }
        } else {
          const error =
            err && err.responseError?.error && err.responseError.error.error_description
              ? err.responseError.error.error_description
              : undefined;
          this.log.warn(`User login failed. Status: ${err.status}  Message: ${error} ${lgSfx}`);
          return throwError(
            error ||
              'The username and password or MQ Server are not valid. Please re-enter them and try again.'
          );
        }
      })
    );
  }

  public logoutAll(): Observable<any> {
    const API_ENDPOINT = '/api/v1/auth/logout/all';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http.postAny(endpoint, undefined).pipe(
      map((resp) => {
        this.localLogoutNotifier.next();
        return resp;
      })
    );
  }

  public getOnlyAdminStatus(): boolean {
    return this.adminOnly;
  }

  private cleanCache(): void {
    this.expires = null;
    this.token = null;
    this.tokenExpirationDate = null;
    this.tokenType = null;
  }

  private transformRequest(obj: any): string {
    const str = [];
    for (const p in obj) {
      if ({}.hasOwnProperty.call(obj, p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
}
