import { AuthenticationService } from '@app/security/shared/authentication.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  HUB_CONNECTION_EVENTS,
  HUB_CONNECTION_STATES,
  SignalRHubService
} from '@app/server/shared/signalr-hub.service';
import { Hub, HubConnectionEventArgs } from '@app/server/shared/hub.model';
import { ILegacy, LEGACY_EVENTS, LegacyStatusEventArgs } from '@app/legacy/legacy.interface';
import { Injectable, NgZone } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { TokenLogin } from '@app/security/token-login.model';
import { filter, map, publishReplay } from 'rxjs/operators';

const HUB_CALLS = {
  AUTH_PING: 'AuthPing',
  AUTHENTICATE: 'Authenticate',
  AUTH_WITH_TOKEN: 'AuthenticateWithToken',
  CREATE_TICKET: 'CreateTicket',
  OPEN_FORM: 'OpenForm',
  LOGOUT: 'Logout',
  QUERY_DIRTY_FORMS_COUNT: 'QueryDirtyFormsCount',
  VIEW_TICKET: 'ViewTicket'
};

const TokenAuthenticateResultCode = {
  SUCCESS: 200,
  TOKEN_UNACCEPTED: 401,
  NO_USER_LOGGED_IN: 409
};

export class HubResponse<T> {
  public data: T;
  public message: string;
  public success: boolean;
  public userMessage: string;
}

const lgSfx = '\t(legacy-intercom.service)';
const MAX_TOKEN_LOGIN_ATTEMPTS = 10;

@Injectable({
  providedIn: 'root'
})
export class LegacyIntercomService implements ILegacy {
  public readonly canOpenTabs = false;
  public connectionUri: string;
  public hub: Hub;
  public hubName: string;
  public legacyErrorLoggingInEvent: Subject<string> = new Subject();
  public status$: Observable<LegacyStatusEventArgs>;
  public tokenLogin: Observable<TokenLogin>;

  private events: BehaviorSubject<LegacyStatusEventArgs>;
  private tokenLoginAttempt: number;

  constructor(
    private authService: AuthenticationService,
    private log: Logger,
    private ngZone: NgZone,
    private signalRHubProxy: SignalRHubService
  ) {
    this.tokenLoginAttempt = MAX_TOKEN_LOGIN_ATTEMPTS + 1;
    this.events = new BehaviorSubject<LegacyStatusEventArgs>(
      new LegacyStatusEventArgs({
        event: LEGACY_EVENTS.EVENT_LIS_DISCONNECTED
      })
    );
    this.status$ = this.events.asObservable();

    this.hubName = 'IntegratorHub';
  }

  /********************************
   * Methods
   ********************************/

  public addWatchEventsToHub(): void {
    this.hub.on('AuthPingback', (userIsAuthenticated) => this.onAuthPingback(userIsAuthenticated));
    this.hub.on('AuthTokenAccepted', (authResponse) =>
      this.onAuthTokenAccepted(JSON.parse(authResponse))
    );
    this.hub.on('ErrorLoggingIn', (errorMsg) => this.onErrorLoggingIn(errorMsg));
    this.hub.on('DirtyFormsCount', (dirtyFormsCount) => this.onDirtyFormsCount(dirtyFormsCount));
    this.hub.on('LoggedOut', () => this.onLogout);
    this.hub.on('UserIsAuthenticated', (username, success) =>
      this.onUserIsAuthenticated(username, success)
    );
  }

  public getCurrentStatus(): LegacyStatusEventArgs {
    return this.events.value;
  }

  public watchConnectionEvents(): void {
    this.hub.connectionEvents
      .pipe(filter((args: HubConnectionEventArgs) => args.event === HUB_CONNECTION_EVENTS.change))
      .subscribe((args: HubConnectionEventArgs) => {
        this.log.log(
          `Intercom has new connection status (${
            HUB_CONNECTION_STATES[args.state.newState]
          }) ${lgSfx}`
        );
        if (
          args.hubUrl &&
          this.connectionUri &&
          args.hubUrl.lastIndexOf(this.connectionUri, 0) === 0
        ) {
          this.ngZone.run(() => {
            const newState = HUB_CONNECTION_STATES[args.state.newState];

            switch (newState) {
              case 'connecting':
                this.events.next(
                  new LegacyStatusEventArgs({
                    event: LEGACY_EVENTS.EVENT_LIS_CONNECTING
                  })
                );
                break;
              case 'connected':
                this.events.next(
                  new LegacyStatusEventArgs({
                    event: LEGACY_EVENTS.EVENT_LIS_CONNECTED
                  })
                );
                break;
              case 'reconnecting':
                this.events.next(
                  new LegacyStatusEventArgs({
                    event: LEGACY_EVENTS.EVENT_LIS_CONNECTING
                  })
                );
                break;
              case 'disconnected':
                this.events.next(
                  new LegacyStatusEventArgs({
                    event: LEGACY_EVENTS.EVENT_LIS_DISCONNECTED
                  })
                );
                break;
              default:
                this.log.error(
                  `Unrecognized hub connection state: ${args.state.newState} ${lgSfx}`
                );
            }
          });
        }
      });

    if (this.hub.connectionStatus.isConnected()) {
      this.log.log(`Connection status is 'connecting' ${lgSfx}`);
      this.events.next(
        new LegacyStatusEventArgs({
          event: LEGACY_EVENTS.EVENT_LIS_CONNECTED
        })
      );
    } else if (this.hub.connectionStatus.isReconnecting()) {
      this.log.log(`Connection status is 'reconnecting' ${lgSfx}`);
      this.events.next(
        new LegacyStatusEventArgs({
          event: LEGACY_EVENTS.EVENT_LIS_CONNECTING
        })
      );
    } else {
      this.log.log(`Connection status is 'disconneted' ${lgSfx}`);
      this.events.next(
        new LegacyStatusEventArgs({
          event: LEGACY_EVENTS.EVENT_LIS_DISCONNECTED
        })
      );
    }
  }

  public authPing(): void {
    this.hub.send(HUB_CALLS.AUTH_PING).subscribe();
  }

  public authenticate(
    server: string,
    database: string,
    username: string,
    password: string,
    mqServer: string,
    authToken: string
  ): Observable<boolean> {
    if (!server || !database || !username) {
      throw Error('Server, database, and username are required fields.');
    }
    if (!this.hub) {
      throw Error('The Classic Hub must be started before trying to authenticate.');
    }

    const authObservable = this.hub
      .send(HUB_CALLS.AUTHENTICATE, server, database, username, password, mqServer, authToken)
      .pipe(map(() => true))
      .pipe(publishReplay(1));

    authObservable.subscribe(() => {
      this.hub.addQueryParameter('bearer', this.authService.token);
    });

    return authObservable;
  }

  public authenticateWithToken(authToken: string): Observable<boolean> {
    if (!authToken || !authToken.trim()) {
      throw Error('No token provided to login with.');
    } else {
      this.log.log(`Authenticating Legacy Service with token, connectionStatus: ${lgSfx}`);
    }

    this.tokenLoginAttempt = 0;
    return this.tryTokenAuthentication(authToken);
  }

  public connect(): Observable<string> {
    if (!this.connectionUri) {
      throw Error('Legacy Intercom connection cannot be started without a server URL.');
    } else {
      this.log.log(`Connecting to legacy service ${lgSfx}`);
    }

    if (!this.hub || this.hub.settings.connectionPath !== this.connectionUri) {
      this.hub = this.signalRHubProxy.createHub(this.hubName, {
        connectionPath: this.connectionUri,
        loggingEnabled: false
      });
      this.addWatchEventsToHub();
      this.watchConnectionEvents();
    }

    return this.hub.start();
  }

  public disconnect(async?: boolean, notifyServer?: boolean): void {
    if (this.hub) {
      this.hub.stop(async, notifyServer);
    }
  }

  public createTicket(
    orderId: number,
    scheduleId: number,
    scheduleLoadId: number,
    truckId: number,
    plantId: number
  ): void {
    this.hub.send(HUB_CALLS.CREATE_TICKET, orderId, scheduleId, scheduleLoadId, truckId, plantId);
  }

  public logout(forceLogout: boolean): void {
    this.hub.send(HUB_CALLS.LOGOUT, forceLogout === true);
    this.onLogout();
  }

  public openTab(_routePath: [string]): void {
    this.log.error(`legacyIntercom does no support opening new tabs ${lgSfx}`);
  }

  public openForm(formId: string): void {
    this.hub.send(HUB_CALLS.OPEN_FORM, formId).subscribe();
  }

  public queryDirtyFormsCount(): void {
    this.hub.send(HUB_CALLS.QUERY_DIRTY_FORMS_COUNT);
  }

  public viewTicket(orderId: number, ticketId: number): void {
    this.hub.send(HUB_CALLS.VIEW_TICKET, orderId, ticketId);
  }

  /********************************
   * Hub Event Methods
   ********************************/

  private onAuthPingback(userIsAuthenticated: boolean): void {
    this.ngZone.run(() => {
      this.log.log(
        `onAuthPingback received from hub with auth status: ${userIsAuthenticated} ${lgSfx}`
      );
      this.events.next(
        new LegacyStatusEventArgs({
          authenticated: userIsAuthenticated,
          event: LEGACY_EVENTS.EVENT_LIS_USER_AUTHENTICATED
        })
      );
    });
  }

  private onAuthTokenAccepted(response: HubResponse<number>): void {
    switch (response.data) {
      case TokenAuthenticateResultCode.SUCCESS:
        this.tokenLoginAttempt = -1;
        this.log.info(`Token authentication successful (${response.data}) ${lgSfx}`);
        this.events.next(
          new LegacyStatusEventArgs({
            authenticated: true,
            event: LEGACY_EVENTS.EVENT_LIS_USER_AUTHENTICATED
          })
        );
        break;
      case TokenAuthenticateResultCode.NO_USER_LOGGED_IN:
      case TokenAuthenticateResultCode.TOKEN_UNACCEPTED:
        this.tokenLoginAttempt = -1;
        this.log.warn(
          `Token authentication failed with status (${response.data}) with status (${
            response.message
          }) for user ${this.hub.getConnectionId()} ${lgSfx}`
        );
        break;
      default:
        this.log.warn(
          `Unexpected status (${response.data}) from auth token request, will retry if attempts allowed ${lgSfx}`
        );
    }
  }

  private onDirtyFormsCount(dirtyFormsCount: number): void {
    this.ngZone.run(() => {
      this.log.log(
        `onDirtyFormsCount received from hub with dirty forms: ${dirtyFormsCount} ${lgSfx}`
      );
      this.events.next(
        new LegacyStatusEventArgs({
          dirtyFormsCount,
          event: LEGACY_EVENTS.EVENT_LIS_DIRTY_FORMS
        })
      );
    });
  }

  private onErrorLoggingIn(message: string): void {
    this.ngZone.run(() => {
      this.log.log(`onErrorLoggingIn received from hub with message: ${message} ${lgSfx}`);
      this.legacyErrorLoggingInEvent.next(message);
      this.onLogout();
    });
  }

  private onLogout(): void {
    this.ngZone.run(() => {
      this.events.next(
        new LegacyStatusEventArgs({
          event: LEGACY_EVENTS.EVENT_LIS_USER_LOGGED_OUT
        })
      );
    });
  }

  private onUserIsAuthenticated(username: string, success: boolean): void {
    this.ngZone.run(() => {
      this.log.log(`UserIsAuthenticated received from hub for user: ${username} ${lgSfx}`);
      this.events.next(
        new LegacyStatusEventArgs({
          event: LEGACY_EVENTS.EVENT_LIS_USER_AUTHENTICATED,
          authenticated: success,
          username
        })
      );
    });
  }

  private tryTokenAuthentication(authToken: string): Observable<boolean> {
    this.log.log(`Attempting login with authToken ${lgSfx}`);

    this.tokenLoginAttempt += 1;
    const authObservable = this.hub
      .send(HUB_CALLS.AUTH_WITH_TOKEN, authToken.trim())
      .pipe(map(() => true))
      .pipe(publishReplay(1));

    authObservable.subscribe((x) => {
      this.log.log(`Logging in with auth token ${x}... ${lgSfx}`);
    });

    setTimeout(() => {
      if (this.tokenLoginAttempt > -1 && this.tokenLoginAttempt < MAX_TOKEN_LOGIN_ATTEMPTS) {
        this.tryTokenAuthentication(authToken);
      }
    }, 2000);

    return authObservable;
  }
}
