import { ConnectionStatus } from '@app/server/shared/connection-status.model';
import { HubOption } from '@app/server/shared/hub-option.model';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

declare const $: any;

export interface HubConnectionEventArgs {
  data: any;
  connectionId: string;
  event: string;
  error: any;
  state: any;
  hubUrl: string;
}

export class Hub {
  public connection: any;
  public connectionEvents: Subject<HubConnectionEventArgs>;
  public connectionStatus: ConnectionStatus;
  public hubName: string;
  public proxy: any;
  public settings: HubOption;

  constructor(hubName: string, options: any) {
    // Ensure a hub name was passed
    if (!hubName) {
      throw new Error(
        'Hub name was not specified, be sure to pass it in when invoking the Hub class'
      );
    }

    // Hub Connection Events
    this.connectionEvents = new Subject<HubConnectionEventArgs>();

    // Hub Connection Status
    this.connectionStatus = new ConnectionStatus();

    // Hub Settings
    this.settings = Object.assign(new HubOption(), options);
  }

  /**
   * Starts the hub connection
   */
  public start(): Observable<any> {
    return of(this.connection.start());
  }

  public stop(async?: boolean, notifyServer?: boolean): Observable<any> {
    return of(this.connection.stop(async, notifyServer));
  }

  /**
   * Hub.on
   */
  public on(evt, fn): Observable<Hub> {
    this.proxy.on(evt, (...args: any) => {
      fn.apply(fn, args);
    });

    return of(this); // Return for chaining
  }

  /**
   * Hub.off
   * Stops listening to passed in event
   *
   * @example
   * Hub.off('getDataFromHub', getDataFromHubCallback);
   */
  public off(_evt: string): void {
    /*eslint-disable */
    this.proxy.off.apply(this.proxy, arguments);
  }

  /**
   * Hub.invoke
   * Method will ensure that a connection has been established before
   *  calling to the hub
   *
   * @example
   * Hub.invoke('sendMessage', 'Message to Send');
   */
  public invoke(...args: any[]): Observable<any> {
    // Resolve the method immediately if the connection is established
    if (this.connection.state === $.signalR.connectionState.connected) {
      return this.resolve(this, args);
    }

    // In the event that we're disconnected
    if (this.connection.state === $.signalR.connectionState.disconnected) {
      // Start the connection, then resolve once we're connected
      return this.start().pipe(mergeMap(() => this.resolve(this, args)));
    }
  }

  // Resolve the invoke call
  public resolve(self: Hub, args: any[]): Observable<any> {
    return of(self.proxy.invoke(...args));
  }

  /**
   * Alias for invoke
   */
  public send(...args: any[]): Observable<any> {
    return of(this.invoke(...args));
  }

  public addQueryParameter(id: string, value: string): void {
    if (typeof this.connection.qs === 'undefined' || this.connection.qs === null) {
      this.connection.qs = {};
    }
    this.connection.qs[id] = value;
  }

  public removeQueryParameter(id: string): void {
    if (typeof this.connection.qs === 'undefined' || this.connection.qs === null) {
      return;
    }
    delete this.connection.qs[id];
  }

  public getConnectionId(): string {
    return this.connection.id;
  }
}
