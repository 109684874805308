import { IModal } from '@app/shared/dialogs/modal.interface';
import { IModalButton } from '@app/shared/dialogs/modal-button.interface';

export class ModalButton implements IModalButton {
  actionMethod: any;
  parentModal: IModal;
  siblings: Array<IModalButton>;
  title: string;

  constructor(actionMethod?: any, title?: string) {
    this.actionMethod = actionMethod;
    this.parentModal = null;
    this.siblings = [];
    this.title = title;
  }

  public addSibling(button: IModalButton): void {
    this.siblings.push(button);
  }

  public execute(): void {
    if (this.actionMethod) {
      this.actionMethod();
    }
    if (this.parentModal) {
      this.parentModal.removeButton(this);
      this.siblings.map((sibling) => this.parentModal.removeButton(sibling));
    }
  }
}
