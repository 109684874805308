import { RawMaterialList } from '@app/products/models/raw-material-list.model';

export class FormulaProduct {
  aggregateSize: string;
  air: string;
  applyAdmixDosageFactor: boolean;
  cementType: string;
  description: string;
  id: number;
  inactiveFlag: boolean;
  maxExtraWaterGallons: number;
  maxLoadSize: number;
  mixerSeconds: number;
  object: string;
  plantId: number;
  rawMaterials: RawMaterialList[];
  sackEquivalent: number;
  slump: number;
  strength: number;
  waterToCementRatio: number;
  // plant: PartialPlant; // ! not available on server

  constructor() {}
}
