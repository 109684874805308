import { Moment } from 'moment';
import { Truck } from '@app/trucks/shared/truck.model';
import { convertIso8601ToDate } from '@app/shared/utilities/date-helpers.utility';

export class TruckUpdateModel {
  id: number; // truckId
  plantId: number;
  truckFlagId: number;
  statusId: number;
  truckFlag: string;
  identificationNumber: string;
  minutes: number;
  inYardSequenceNum: number;
  statusDescription: string;
  statusLocation: string;

  private _statusDateTime: Moment;
  get statusDateTime(): Moment {
    return this._statusDateTime;
  }
  set statusDateTime(val) {
    this._statusDateTime = convertIso8601ToDate(val);
  }

  constructor(truck?: Truck) {
    if (truck) {
      this.id = truck.id;
      this.plantId = truck.plantId;
      this.truckFlag = truck.truckFlag;
      this.truckFlagId = truck.truckFlagId;
      this.statusId = truck.statusId;
      this.minutes = truck.minutes;
      this.inYardSequenceNum = truck.inYardSequenceNum;
      this.statusDateTime = truck.statusDateTime;
      this.identificationNumber = truck.identificationNumber;
      this.statusDescription = truck.statusDescription;
      this.statusLocation = truck.statusLocation;
    }
  }
}
