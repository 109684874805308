export class BooleanResponse {
  object = 'booleanResponse';
  value: boolean;
  message: string;

  constructor(value?: boolean, message?: string) {
    if (value) {
      this.value = value;
    }

    if (message) {
      this.message = message;
    }
  }
}
