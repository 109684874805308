import { AppConfigModel, LSSAppConfig } from '@app/shared/models/app-config.model';
import { AccountingInterfaceEnum } from '@app/accounting/models/accounting-interfaces.enum';
import { HttpService } from '@app/security/shared/http.service';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { Observable, of } from 'rxjs';
import { ServerConfig } from '@app/shared/models/server-config.model';
import { UriService } from '@app/shared/services/uri.service';
import { WINDOW_PROP_LOG_HTTP_REQ_TIMES } from '@app/shared/constants/constants';
import { catchError, map } from 'rxjs/operators';
import { serverConfigError, localhostDispatcherWarning } from '@app/shared/constants/const-strings';

const lgSfx = '\t(app.config)';

const localConfigurationUrl = 'configuration.local.json';
const configurationUrl = 'configuration.json';
const serverConfigurationEndpoint = '/api/v1/settings/config';
const defaultServerPort = '8484';
const defaultDispatchPort = '8123';
const defaultLegacyPort = '8080';

@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  public lssSettings: LSSAppConfig;

  private dispatchUri: string;
  private enableSignalRLogging: boolean;
  private _enableErrorReporting: boolean;
  private legacyUri: string;
  private serverUri: string;
  private mapApiScriptUri: string;
  private serverConfig: ServerConfig;

  constructor(private http: HttpService, private log: Logger) {}

  public load(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.setDefaultUris();
      this.getClientConfigurations().subscribe((response: AppConfigModel) => {
        this.populateClientConfigurations(response);
        this.getServerConfiguration().subscribe((serverResponse: ServerConfig) => {
          if (serverResponse) {
            this.populateServerConfigurations(serverResponse);
          } else {
            this.log.warn(serverConfigError);
          }
          this.cleanUris();
          resolve();
        });
      });
    });
  }

  public getDispatchUri(): string {
    return this.dispatchUri || '';
  }

  public getEnableSignalRLogging(): boolean {
    return this.enableSignalRLogging || false;
  }

  public getMapApiScriptUri(): string {
    return this.mapApiScriptUri + '&callback=initMap' || '';
  }

  public getLegacyUri(): string {
    return this.legacyUri || '';
  }

  public getServerUri(): string {
    return this.serverUri || '';
  }

  public get enableErrorReporting(): boolean {
    return this._enableErrorReporting;
  }

  public get serverVersionNo(): string {
    return this.serverConfig.versionNo;
  }

  public get accountingInterface(): AccountingInterfaceEnum {
    return this.serverConfig.accountingInterface;
  }

  public get useLegacyIntegrator(): boolean {
    return this.serverConfig.useLegacyIntegrator;
  }

  public get emailAvailable(): boolean {
    return this.serverConfig.emailAvailable;
  }

  public get useLssCache_server(): boolean {
    return this.serverConfig.useLssCache;
  }

  public get useLssCacheLocalOnly_server(): boolean {
    return this.serverConfig.lssCacheLocalOnly;
  }

  public get nlqEnabled(): boolean {
    return this.serverConfig.nlqEnabled;
  }

  public get externalUsersEnabled(): boolean {
    return this.serverConfig.externalUsersEnabled;
  }

  public get disabledForms(): string[] {
    return this.serverConfig.disabledForms || [];
  }

  /**
   * Temp config item. Used to make use of cache storage on client when
   * the server side cache is config item is also true
   */
  public get useLssCache_localStorage(): boolean {
    const lsItem = localStorage.getItem('useLssCache');
    return Boolean(lsItem);
  }

  public setLegacyUriPort(port: string): void {
    const url = UriService.getUrlFromString(this.legacyUri);
    if (url) {
      url.port = port;
      this.legacyUri = url.origin;
      if (this.legacyUri?.endsWith('/')) {
        this.legacyUri = this.legacyUri.slice(0, this.legacyUri.length - 1);
      }
    }
  }

  private getClientConfigurations(): Observable<AppConfigModel> {
    this.log.log(`loading configuration from ${localConfigurationUrl} ${lgSfx}`);
    return this.http.getAny(localConfigurationUrl).pipe(
      map((response) => {
        if (Array.isArray(response) || !response) {
          throw Error('Config not found or valid');
        } else {
          return response;
        }
      }),
      catchError(() => {
        this.log.log(
          `Local Config file not found, now loading configuration from ${configurationUrl} ${lgSfx}`
        );
        return this.http.getAny(configurationUrl);
      })
    );
  }

  private getServerConfiguration(): Observable<ServerConfig> {
    return this.http.getAny(`${this.serverUri}${serverConfigurationEndpoint}`).pipe(
      map((response) => {
        if (Array.isArray(response) || !response) {
          throw Error('Config not found or valid');
        } else {
          return response;
        }
      }),
      catchError(() => {
        this.log.warn(
          `Server Config file not found, if url overwrites are required app will be unuseable ${lgSfx}`
        );
        return of(undefined);
      })
    );
  }

  /**
   * Set the default uri values for accessing the LIT, Server, and Dispatcher(signalr)
   * using the protocol and hostname (subdomain.domain.toplevel) for the current window
   * and the default port values defined in this file.
   */
  private setDefaultUris(): void {
    const windowUri = `${window.location.protocol}//${window.location.hostname}`;
    this.dispatchUri = `${windowUri}:${defaultDispatchPort}`;
    this.legacyUri = `${windowUri}:${defaultLegacyPort}`;
    this.serverUri = `${windowUri}:${defaultServerPort}`;
  }

  /**
   * This function sets configuration data set in configuration.local.json
   * or configuration.json if .local doesn't exist. Can be used to set
   * uri value for Server to non default.
   */
  private populateClientConfigurations(response: AppConfigModel): void {
    this.serverUri = response.SERVER_URI || this.serverUri;
    this.enableSignalRLogging = response.ENABLE_SIGNALR_LOGGING;
    this.mapApiScriptUri = response.MAP_API_SCRIPT_URI || '';
    this.lssSettings = response.LSS;
    this._enableErrorReporting = response.ENABLE_CLIENT_ERROR_REPORTING;

    if (this.mapApiScriptUri.trim() && !this.mapApiScriptUri.includes('&libraries=places')) {
      this.mapApiScriptUri = this.mapApiScriptUri + '&libraries=places';
    }

    window[WINDOW_PROP_LOG_HTTP_REQ_TIMES] = 0;

    this.log.info(`AppConfig configuration loaded with: ${lgSfx}`, this);
  }

  private populateServerConfigurations(response: ServerConfig): void {
    this.serverConfig = response;

    if (
      this.dispatchUri.includes('localhost') &&
      !this.serverConfig.dispatchUri.includes('localhost')
    ) {
      this.log.log(localhostDispatcherWarning);
      this.dispatchUri = this.serverConfig.dispatchUri;
    } else if (this.serverConfig.overrideDispatcherPort) {
      this.setPortFromConfigUri(this.serverConfig.dispatchUri, this.dispatchUri);
    } else if (this.serverConfig.overrideDispatcherUri) {
      this.dispatchUri = this.serverConfig.dispatchUri;
    }

    if (this.serverConfig.overrideLegacyUri) {
      this.legacyUri = this.serverConfig.legacyUri;
    }
  }

  private cleanUris(): void {
    if (this.dispatchUri?.endsWith('/')) {
      this.dispatchUri = this.dispatchUri.slice(0, this.dispatchUri.length - 1);
    }
    if (this.legacyUri?.endsWith('/')) {
      this.legacyUri = this.legacyUri.slice(0, this.legacyUri.length - 1);
    }
    if (this.serverUri?.endsWith('/')) {
      this.serverUri = this.serverUri.slice(0, this.serverUri.length - 1);
    }
  }

  private setPortFromConfigUri(uriFromConfig: string, currentUri: string): string {
    if (uriFromConfig) {
      const currentURLObject = UriService.getUrlFromString(currentUri);
      currentURLObject.port =
        UriService.getUrlFromString(uriFromConfig)?.port || currentURLObject.port;
      return currentURLObject.origin;
    }

    return currentUri;
  }
}
