import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'acs-ok-dialog',
  templateUrl: './ok-dialog.component.html'
})
export class OkDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; content: string; contentSeparated: string[] },
    private dialogRef: MatDialogRef<OkDialogComponent>
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
