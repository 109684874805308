import { AppConfig } from '@app/app.config';
import { DISPATCHER_EVENTS } from '@app/dispatcher/dispatch.model';
import { DispatchService } from '@app/dispatcher/dispatch.service';
import { HttpService } from '@app/security/shared/http.service';
import { IModal } from '@app/shared/dialogs/modal.interface';
import { IModalButton } from '@app/shared/dialogs/modal-button.interface';
import { IStatusItem } from '@app/shared/dialogs/status-item.interface';
import { Injectable } from '@angular/core';
import { LEGACY_EVENTS, LegacyStatusEventArgs } from '@app/legacy/legacy.interface';
import { LegacyService } from '@app/legacy/legacy.service';
import { LegacySkipperService } from '@app/legacy/legacy-skipper.service';
import { Logger } from '@app/logger/logger';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, of, Subscription } from 'rxjs';
import { StatusModalComponent } from '@app/shared/dialogs/status-modal.component';
import { StatusModalService } from '@app/shared/dialogs/status-modal.service';
import { catchError } from 'rxjs/operators';

const API_PING_ENDPOINT = '/api/v1/ping/anonymous';

const lgSfx = '\t(connections.service)';
const maxReconnectionAttempts = 2;

@Injectable({ providedIn: 'root' })
export class ConnectionsService {
  private clientSubscription: Subscription;
  private connectingToClassic = false;
  private connectingToDispatcher = false;
  private dispatcherButton: IModalButton;
  private dispatcherStatus: IStatusItem;
  private dispatcherSubscription: Subscription;
  private dispatcherReconnectAttempts = 0;
  private legacyButton: IModalButton;
  private legacySkipButton: IModalButton;
  private legacySkipConfirmButtonNo: IModalButton;
  private legacySkipConfirmButtonYes: IModalButton;
  private legacyStatus: IStatusItem;
  private legacyReconnectAttempts = 0;
  private modal: IModal;

  constructor(
    private configurationService: AppConfig,
    private dispatcherService: DispatchService,
    private http: HttpService,
    private legacyService: LegacyService,
    private legacySkipperService: LegacySkipperService,
    private log: Logger,
    private statusModalService: StatusModalService
  ) {
    this.dispatcherStatus = statusModalService.getNewStatusItem('Connecting to the Dispatcher', 0);
    this.legacyStatus = statusModalService.getNewStatusItem('Connecting to the Intercom', 0);

    this.modal = statusModalService.getNewModal('Connecting');
    this.modal.statusItems.push(this.dispatcherStatus);

    this.initDispatcherButtons();
    this.initLegacyButtons();
  }

  public connectToServer(): void {
    if (this.dispatcherService.getStatus() === DISPATCHER_EVENTS.CONNECTED) {
      return;
    }

    if (this.modal.isOpen) {
      this.modal.close();
    }
    this.statusModalService.open(this.modal);

    this.connectToDispatcher();
  }

  public connectToClassic(): IModal {
    this.legacyStatus.progress = 0;
    this.legacyService.useLegacyIntercom();
    if (this.modal.statusItems.length === 1) {
      this.modal.statusItems.push(this.legacyStatus);
    }

    if (this.modal.isOpen) {
      this.modal.close();
    }
    this.statusModalService.open(this.modal);

    this.connectToLegacyServer();

    return this.modal;
  }

  public disconnectFromClassic(): void {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = undefined;
    }
    this.legacyStatus.progress = 100;
    this.legacyService.skipLegacyIntercom();

    if (this.modal.statusItems.length > 1) {
      this.modal.statusItems.splice(1);
    }

    this.modal.reCheckStatus();
  }

  public getSignalrConnections(): Observable<any[]> {
    const endpoint = this.configurationService.getServerUri() + '/api/v1/connection/signalr-info';

    return this.http.getAny(endpoint);
  }

  private pingToWakeupServer(): Observable<any> {
    const endpoint = this.configurationService.getServerUri() + API_PING_ENDPOINT;
    this.log.log(`sending ping to server ${endpoint} ${lgSfx}`);

    return this.http.getAny(endpoint);
  }

  private getStartStatus(): number {
    return Math.round(Math.floor(Math.random() * 20 + 1));
  }

  // region Dispatcher *******

  private initDispatcherButtons(): void {
    this.dispatcherButton = this.statusModalService.getNewButton(
      () => this.connectToDispatcher(),
      'Retry Dispatcher'
    );
  }

  private connectToDispatcher(): void {
    if (this.connectingToDispatcher) {
      return;
    }

    this.connectingToDispatcher = true;
    this.dispatcherStatus.updateProgress(this.getStartStatus());
    this.dispatcherService.connect().subscribe(
      () => {
        this.connectingToDispatcher = false;
        this.onDispatcherConnected();
        this.subscribeToDispatcherEvents();
      },
      () => {
        this.connectingToDispatcher = false;
        this.dispatcherStatus.updateProgress(-1);
        this.modal.addButton(this.dispatcherButton);
      }
    );
  }

  private subscribeToDispatcherEvents(): void {
    if (this.dispatcherSubscription) {
      this.dispatcherSubscription.unsubscribe();
    }

    this.dispatcherSubscription = this.dispatcherService.statusEvents.subscribe((event: string) => {
      switch (event) {
        case DISPATCHER_EVENTS.CONNECTED: {
          this.onDispatcherConnected();
          break;
        }
        case DISPATCHER_EVENTS.CONNECTING: {
          this.onDispatcherConnecting();
          break;
        }
        case DISPATCHER_EVENTS.DISCONNECTED: {
          this.onDispatcherDisconnect();
          break;
        }
      }
    });
  }

  private onDispatcherConnected(): void {
    this.log.info(`Dispatcher hub connected ${lgSfx}`);
    this.dispatcherReconnectAttempts = 0;
    this.dispatcherStatus.updateProgress(100);

    this.modal.reCheckStatus();
  }

  private onDispatcherDisconnect(): void {
    setTimeout(() => {
      this.log.warn(`Dispatcher hub disconnected ${lgSfx}`);
      this.dispatcherReconnectAttempts++;
      this.dispatcherStatus.updateProgress(-1);

      if (this.dispatcherReconnectAttempts <= maxReconnectionAttempts) {
        this.log.log(
          `Dispatcher attempting auto reconnection ${this.dispatcherReconnectAttempts} ${lgSfx}`
        );
        this.dispatcherService.connect().subscribe();
      } else {
        this.modal.addButton(this.dispatcherButton);
      }

      if (!this.modal.isOpen) {
        this.statusModalService.open(this.modal);
      }

      this.modal.reCheckStatus();
    });
  }

  private onDispatcherConnecting(): void {
    this.log.info(`Dispatcher hub connecting ${lgSfx}`);
    this.dispatcherStatus.updateProgress(this.getStartStatus());

    setTimeout(() => {
      this.pingToWakeupServer()
        .pipe(
          catchError(() => {
            setTimeout(() => {
              this.pingToWakeupServer().subscribe();
            }, 2000);

            return of(false);
          })
        )
        .subscribe();
    }, 500);

    if (!this.modal.isOpen) {
      this.statusModalService.open(this.modal);
    }
  }

  // endregion Dispatcher

  // region Legacy *******

  private cancelSkipLegacyConnection(): void {
    this.log.log(`user is canceled skipping connection to the legacy service ${lgSfx}`);
    this.legacySkipConfirmButtonNo.parentModal.close();
    this.connectToLegacyServer();
  }

  private confirmSkipLegacyConnection(): MatDialogRef<StatusModalComponent> {
    const confirmModal = this.statusModalService.getNewModal('Confirm Skip Classic Intercom');
    confirmModal.addButton(this.legacySkipConfirmButtonYes);
    confirmModal.addButton(this.legacySkipConfirmButtonNo);

    const legacySkipStatus = this.statusModalService.getNewStatusItem(
      `Skipping connection to Classic AUJS will prevent the opening of its forms.
      Do you want to proceed`,
      0
    );
    confirmModal.statusItems = [legacySkipStatus];

    return this.statusModalService.open(confirmModal);
  }

  private connectToLegacyServer(): Observable<string> {
    if (this.connectingToClassic) {
      return of('Already connecting');
    }

    this.connectingToClassic = true;
    this.legacyStatus.updateProgress(this.getStartStatus());

    this.legacyService.useLegacyIntercom();
    this.legacyService.service.connectionUri =
      this.configurationService.getLegacyUri() + '/signalr';

    const legacyConnect$ = this.legacyService.service.connect();
    legacyConnect$.subscribe(
      () => {
        this.connectingToClassic = false;
        this.onLegacyConnected();
        this.subscribeToLegacyEvents();
      },
      () => {
        this.connectingToClassic = false;
        this.onLegacyConnectionFailed();
      }
    );

    this.modal.reCheckStatus();

    return legacyConnect$;
  }

  private initLegacyButtons(): void {
    // Confirm skipping connection to legacy service
    this.legacySkipConfirmButtonYes = this.statusModalService.getNewButton(
      () => this.skipLegacyConnection(),
      'Yes'
    );
    this.legacySkipConfirmButtonNo = this.statusModalService.getNewButton(
      () => this.cancelSkipLegacyConnection(),
      'No'
    );
    this.legacySkipConfirmButtonYes.addSibling(this.legacySkipConfirmButtonNo);
    this.legacySkipConfirmButtonNo.addSibling(this.legacySkipConfirmButtonYes);

    // Skip the connection to the legacy service
    this.legacySkipButton = this.statusModalService.getNewButton(
      () => this.confirmSkipLegacyConnection(),
      'Skip Intercom'
    );
    this.legacyButton = this.statusModalService.getNewButton(
      () => this.connectToLegacyServer(),
      'Retry Intercom'
    );
    this.legacySkipButton.addSibling(this.legacyButton);
    this.legacyButton.addSibling(this.legacySkipButton);
  }

  private onLegacyConnected(): void {
    this.log.info(`Legacy hub connected ${lgSfx}`);
    this.legacyStatus.updateProgress(100);
    this.legacyReconnectAttempts = 0;
    this.modal.reCheckStatus();
  }

  private onLegacyConnecting(): void {
    this.log.info(`Legacy hub connecting ${lgSfx}`);
    this.legacyStatus.updateProgress(this.getStartStatus());

    if (!this.modal.isOpen) {
      this.statusModalService.open(this.modal);
    }
  }

  private onLegacyConnectionFailed(): void {
    this.legacyStatus.updateProgress(-1);

    if (this.legacyReconnectAttempts < maxReconnectionAttempts) {
      this.log.log(`Legacy attempting auto reconnection ${this.legacyReconnectAttempts} ${lgSfx}`);
      this.legacyReconnectAttempts++;
      this.legacyService.service.connect().subscribe();
    } else {
      this.modal.addButton(this.legacySkipButton);
      this.modal.addButton(this.legacyButton);
      this.modal.legacyFailed = true;
    }

    if (!this.modal.isOpen) {
      this.statusModalService.open(this.modal);
    }

    this.modal.reCheckStatus();
  }

  private onLegacyDisconnect(): void {
    this.log.warn(`Legacy hub disconnected ${lgSfx}`);
    this.onLegacyConnectionFailed();
  }

  private skipLegacyConnection(): void {
    this.log.log(`user is skipping connection to the legacy service ${lgSfx}`);
    this.legacySkipConfirmButtonYes.parentModal.close();

    this.legacyStatus.updateProgress(100);
    this.modal.modalInstance.close();

    if (this.modal.statusItems.length > 1) {
      this.modal.statusItems.splice(1);
    }

    this.statusModalService.open(this.modal);
    this.legacyService.skipLegacyIntercom();
    this.legacySkipperService.sendLegacySkippedEvent();
  }

  private subscribeToLegacyEvents(): void {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }

    this.clientSubscription = this.legacyService.service.status$.subscribe(
      (args: LegacyStatusEventArgs) => {
        switch (args.event) {
          case LEGACY_EVENTS.EVENT_LIS_CONNECTED: {
            this.onLegacyConnected();
            break;
          }
          case LEGACY_EVENTS.EVENT_LIS_CONNECTING: {
            this.onLegacyConnecting();
            break;
          }
          case LEGACY_EVENTS.EVENT_LIS_DISCONNECTED: {
            this.onLegacyDisconnect();
            break;
          }
        }
      }
    );
  }

  // endregion Legacy
}
