import { AppConfig } from '@app/app.config';
import { Db } from '@app/server/shared/db.model';
import { HttpService } from '@app/security/shared/http.service';
import { IDb } from '@app/server/shared/db.interface';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const lgSfx = '\t(db.service)';

@Injectable({ providedIn: 'root' })
export class DbService {
  constructor(private config: AppConfig, private http: HttpService, private log: Logger) {}

  public getDatabases(): Observable<Array<IDb>> {
    const API_ENDPOINT = '/api/servers';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http.getAny(endpoint).pipe(
      catchError((err) => {
        this.log.error(`Error retrieving database list. Status: ${err.status} ${lgSfx}`, err);
        return throwError('Error retrieving database list.');
      })
    );
  }

  public getDatabase(database: string): Observable<IDb> {
    // Verify that the database name is legit.
    if (
      typeof database === 'undefined' ||
      database === null ||
      database.constructor !== String ||
      database.trim().length === 0
    ) {
      const rejectReason = {
        logMsg: 'A database name must be provided to dbService.getDatabase(dbName).'
      };
      return throwError(rejectReason);
    }

    const dbName = database
      .replace(/[%]/g, '%25')
      .replace(/\\/g, '%5C')
      .replace(/[/]/g, '%2F')
      .replace(/[*]/g, '%2A')
      .replace(/[:]/g, '%3A')
      .replace(/[<]/g, '%3C')
      .replace(/[>]/g, '%3E')
      .replace(/[+]/g, '%2B')
      .replace(/[?]/g, '%3F')
      .replace(/[.]/g, '%2E')
      .replace(/[%]/g, '');
    const API_ENDPOINT = '/api/servers/';
    const endpoint = this.config.getServerUri() + API_ENDPOINT + encodeURIComponent(dbName.trim());

    return this.http
      .getAny(endpoint)
      .pipe(
        map((resp) => {
          const dbServer = new Db(resp.database, resp.server);
          if (
            typeof dbServer.server === 'undefined' ||
            dbServer.server === null ||
            dbServer.server.length < 1 ||
            typeof dbServer.database === 'undefined' ||
            dbServer.database === null ||
            dbServer.database.length < 1
          ) {
            throw {
              logMsg: 'The server returned an invalid database.'
            };
          }
          return dbServer;
        })
      )
      .pipe(
        catchError((err: any) => {
          let rejectReason: any = {};
          switch (err.status) {
            case 404:
              rejectReason = {
                logMsg: 'The database was not found on the server.',
                error: err.status
              };
              break;
            default:
              rejectReason = {
                logMsg: 'The server erred when retrieving the database.',
                error: err.data
              };
          }
          return throwError(rejectReason);
        })
      );
  }
}
