export const localhostDispatcherWarning = `
The app is being accessed from localhost and no override was
set in settings.json for the dispatcher uri. This can cause issues
when trying to access the app both externally and internally on the server.
By default the app has had the dispatcher uri set to the server settings.json
value.
`;

export const serverConfigError = `
Unable to load server config. Check logs or network tab. If uri overrides are required
app may be in an unuseable state.
`;

export const dataResolverIssueWarning = `
Resolvers for the current route/form did not sucessfully complete. This could lead
to uncorrect behavior for the form. Notify a developer. Check the console for additional errors or the
api log for server errors.
`;

export const refreshReportDataQueryParameter = `refreshReportData`;
