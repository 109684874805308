import { Injectable, Optional } from '@angular/core';
import { Level } from '@app/logger/level';
import {
  LocalStorageRefService,
  LOG_TYPE_CHANGE_KEY
} from '@app/shared/services/browser/local-storage-ref.service';
import { environment } from '@env/environment';

/**
 * Logger options.
 * See {@link Logger}.
 *
 * level - How much detail you want to see in the logs, 0 being off, 1 being the less detailed, 5 being the most. Defaults to WARN.
 * global - Whether you want the created logger object to be exposed in the global scope. Defaults to true.
 * globalAs - The window's property name that will hold the logger object created. Defaults to 'logger'.
 * store - Whether you want the level config to be saved in the local storage so it doesn't get lost when you refresh. Defaults to false.
 * storeAs - The local storage key that will be used to save the level config if the store setting is true. Defaults to 'angular2.logger.level'.
 *
 * Production systems will always default to the level set in environment.prod.ts regardles of any existing value in localStorage.
 * To change the level for prod and dev systems at runtime add a localStorage item with the key defined by the const `LOG_TYPE_CHANGE_KEY`.
 * After refreshing you can then change the log level by modifying the localStorage item defined with `storeAs` in the env files.
 * Note: For Firefox the log level will need to be changed in another tab. Changing localStorage values won't fire the storage event for
 * the tab they are changed on.
 *
 */
export class Options {
  level: Level;
  global: boolean;
  globalAs: string;
  store: boolean;
  storeAs: string;
}

// For browsers that don't implement the debug method, log will be used instead. Fixes #62.
/*eslint-disable no-console*/
const CONSOLE_DEBUG_METHOD = console['debug'] ? 'debug' : 'log';

@Injectable({ providedIn: 'root' })
export class Logger {
  public Level: any = Level;

  private _level: Level;
  private _globalAs: string;
  private _store: boolean;
  private _storeAs: string;

  constructor(private localStorageRef: LocalStorageRefService, @Optional() options?: Options) {
    this.initialize(options);
  }

  public error(_message?: any, ..._optionalParams: any[]): void {
    if (this.isErrorEnabled()) {
      console.error(_message, ..._optionalParams);
    }
  }

  public warn(_message?: any, ..._optionalParams: any[]): void {
    if (this.isWarnEnabled()) {
      console.warn(_message, ..._optionalParams);
    }
  }

  public info(message?: any, ...optionalParams: any[]): void {
    if (this.isInfoEnabled()) {
      if (typeof console.info === 'function') {
        console.info(message, ...optionalParams);
      } else {
        this.log(message, optionalParams);
      }
    }
  }

  public debug(_message?: any, ..._optionalParams: any[]): void {
    if (this.isDebugEnabled()) {
      (console as any)[CONSOLE_DEBUG_METHOD](_message, ..._optionalParams);
    }
  }

  public log(_message?: any, ..._optionalParams: any[]): void {
    if (this.isLogEnabled()) {
      console.log(_message, ..._optionalParams);
    }
  }

  public logColor(message: any, _optionalParams?: any, _color?: 'red' | 'blue' | 'green') {
    if (this.isLogEnabled()) {
      const logColor = _color ? `color:${_color}` : `color:green`;
      console.log(`%c${message}`, logColor);
    }
  }

  global = () => ((window as any)[this._globalAs] = this);

  public store(): Logger {
    this._store = true;
    const storedLevel = this._loadLevel();
    if (storedLevel && !environment.production) {
      this._level = storedLevel;
    } else {
      this._storeLevel(this.level);
    }

    return this;
  }

  public unstore(): Logger {
    this._store = false;
    localStorage.removeItem(this._storeAs);
    return this;
  }

  public isErrorEnabled = (): boolean => this.level >= Level.ERROR;
  public isWarnEnabled = (): boolean => this.level >= Level.WARN;
  public isInfoEnabled = (): boolean => this.level >= Level.INFO;
  public isDebugEnabled = (): boolean => this.level >= Level.DEBUG;
  public isLogEnabled = (): boolean => this.level >= Level.LOG;

  public get level(): Level {
    return this._level;
  }

  public set level(level: Level) {
    if (this._store) {
      this._storeLevel(level);
    }
    this._level = level;
  }

  private _loadLevel = (): Level => Number(localStorage.getItem(this._storeAs));

  private _storeLevel(level: Level): void {
    localStorage[this._storeAs] = level;
  }

  private initialize(options: Options): void {
    const { level, global, globalAs, store, storeAs } = Object.assign(
      {},
      environment.logger,
      options
    );

    this._level = level;
    this._globalAs = globalAs;
    this._storeAs = storeAs;

    if (global) {
      this.global();
    }

    if (store || this._loadLevel()) {
      this.store();
    }

    this.setupLevelOverride();
  }

  private setupLevelOverride(): void {
    if (this.localStorageRef.getGenericStorageValue<string>(LOG_TYPE_CHANGE_KEY)) {
      this.localStorageRef.addListenerForKey(this._storeAs);
      this.localStorageRef.storageEventSubjectContainer.get(this._storeAs).subscribe(() => {
        this.level = this._loadLevel();
      });
    }
  }
}
