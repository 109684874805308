import { IJob } from '@app/jobs/models/job.interface';
import { Moment } from 'moment';
import { Quote } from '@app/quotes/models/quote.model';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';
import { validateNotNullFields } from '@app/shared/forms/validation-helpers.utility';

export class Job implements IJob, ISimpleJob {
  object = 'job';
  id: number; // int NOT NULL,
  address1: string; // varchar(100) NULL,
  address2: string; // varchar(100) NULL,
  address3: string; // varchar(100) NULL,
  address4: string; // varchar(100) NULL,
  address5: string; // varchar(100) NULL,
  address6: string; // varchar(100) NULL,
  address7: string; // varchar(100) NULL,
  createdInOrderEntry: boolean; // bit NOT NULL CONSTRAINT Job_DC_CreatedInOrderEntry  DEFAULT ((0)),
  creditLimit: number; // numeric(18, 2) NULL,
  customerId: number; // int NOT NULL,
  customerJobNumber: string; // varchar(18) NULL,
  customerPoNumber: string; // varchar(50) NULL,
  faxNumber: string; // varchar(18) NULL,
  gpsLat: string; // varchar(100) NOT NULL CONSTRAINT DF_Job_GPS_Lat  DEFAULT ((0)),
  gpsLon: string; // varchar(100) NOT NULL CONSTRAINT DF_Job_GPS_Lon  DEFAULT ((0)),
  importSetupKey: number; // int NULL CONSTRAINT DF__Job__Import_Setu__1A7EF7E9  DEFAULT ((1)),
  inactiveFlag: boolean; // bit NOT NULL,
  jobLimit: number; // numeric(18, 2) NULL CONSTRAINT DF__Job__Job_Limit__2690946A  DEFAULT ((0)),
  number: number; // int NOT NULL,
  jobTypeId: number; // int NOT NULL,
  lockedFlag: boolean; // bit NOT NULL CONSTRAINT DF__Job__Locked_Flag__5E8AD4CA  DEFAULT ((0)),
  lotOrBlockNumber: string; // varchar(100) NULL,
  mapReferenceId: number; // int NULL,
  nontaxableFlag: boolean; // bit NOT NULL,
  phoneExtension: string; // varchar(18) NULL,
  phoneNumber: string; // varchar(18) NULL,
  plantId: number; // int NULL,
  publicWorksDirNumber: string; // varchar(15) NULL CONSTRAINT Job_DC_PublicWorksDirNumber  DEFAULT ('')
  quoteId: number; // int NULL,
  quoteNumber: number; // only used with ISimpleJob interface which is not used as of 2023-12-22
  quoteDescription: string;
  suburbId: number; // int NULL,
  quote: Quote;
  taxScheduleId: number; // does not exist on database model - is derived from Job_Tax_Location_Item

  clientUseTaxFlag: boolean;

  get quoteName(): string {
    return this.quote?.name;
  }

  private _modifiedDateTime: Moment; // datetime NOT NULL,
  get modifiedDateTime(): Moment {
    return this._modifiedDateTime;
  }
  set modifiedDateTime(val) {
    this._modifiedDateTime = convertIso8601ToDate(val);
  }

  constructor() {
    this.createdInOrderEntry = false;
    this.gpsLat = '';
    this.gpsLon = '';
    this.inactiveFlag = false;
    this.jobTypeId = 0;
    this.lockedFlag = false;
    this.nontaxableFlag = false;
  }

  public static copyExisting(job: Job): Job {
    const target = new Job();
    for (const key of Object.keys(job)) {
      if (key !== 'quoteName') {
        target[key] = job[key];
      }
    }
    return target;
  }

  public getCopy(): Job {
    return Object.assign(new Job(), this);
  }

  /**
   * Sets quote fields on job object to undefined.
   *
   * Does not affect ISimpleJob quote related fields.
   * It could but those aren't used as of 2023-12-22.
   */
  public clearQuoteFields(): void {
    this.quoteId = undefined;
    this.quote = undefined;
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }

  validate(): string[] {
    return validateNotNullFields(
      this,
      [
        'createdInOrderEntry',
        'customerId',
        'gpsLat',
        'gpsLon',
        'inactiveFlag',
        'jobTypeId',
        'lockedFlag',
        'modifiedDateTime',
        'nontaxableFlag'
      ],
      'Job'
    );
  }
}

export class SimpleJob implements ISimpleJob {
  object = 'simpleJob';
  number: number;
  address1: string;
  address2: string;
  address3: string;
  customerJobNumber: string;
  customerPoNumber: string;
  lotOrBlockNumber: string;
  quoteNumber: number;
  quoteName: string;
}

export interface ISimpleJob {
  object: string;
  number: number;
  address1: string;
  address2: string;
  address3: string;
  customerJobNumber: string;
  customerPoNumber: string;
  lotOrBlockNumber: string;
  quoteNumber: number;
  quoteName: string;
}
