export class UnitOfMeasure {
  conversionFactorTypeId: number;
  conversionFactorValue: number;
  id: number;
  imperialDescription: string;
  imperialToMetricConvFactor: number;
  metricDescription: string;
  object: string;

  constructor() {}

  public getCopy(): UnitOfMeasure {
    return Object.assign(new UnitOfMeasure(), this);
  }
}

export enum UnitOfMeasureIds {
  CUYards = 1,
  Pounds = 2,
  Gallons = 3,
  FluidOz = 4,
  Each = 5,
  Tons = 6,
  PerYard = 7,
  PerPecent = 8,
  CUFeet = 9,
  Feet = 10,
  Ounces = 11,
  TonPercent = 12,
  Yard = 13,
  Hours = 14,
  RailCars = 15,
  EachQty = 30
}
