import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';
import { Moment } from 'moment';

export class ScheduleLoad {
  driverId: number;
  id: number;
  loadNumber: number;
  object: string;
  orderId: number;
  quantity: number;
  scheduleId: number;
  statusId: number;
  ticketId: number;

  private _onJobTime: Moment; // datetime NULL,
  get onJobTime(): Moment {
    return this._onJobTime;
  }
  set onJobTime(val) {
    this._onJobTime = convertIso8601ToDate(val);
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}
