import moment from 'moment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  checkResponseAndReturnList,
  checkResponseAndReturnSingle
} from '@app/security/shared/http.utility';
import { Logger } from '@app/logger/logger';
import { Moment } from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SearchResult } from '@app/shared/models/search-result.model';
import { ServerError } from '@app/shared/models/server-error.model';
import { WINDOW_PROP_LOG_HTTP_REQ_TIMES } from '@app/shared/constants/constants';
import { catchError, finalize, map } from 'rxjs/operators';

const HEADER_LABEL_AUTH = 'Authorization';

@Injectable({
  providedIn: 'root'
})
export class HttpOptionsService {
  private httpOptions: { body: any; headers: HttpHeaders };

  constructor() {
    this.httpOptions = {
      body: undefined,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: '',
        database: '',
        seat_token: ''
      })
    };
  }

  public get(): { body: any; headers: HttpHeaders } {
    return Object.assign({}, this.httpOptions);
  }

  public setAuthorization(authorization): void {
    this.httpOptions.headers = this.httpOptions.headers.set(HEADER_LABEL_AUTH, authorization);
  }
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private _loadingSub = new BehaviorSubject<boolean>(false);

  public get isLoading$(): Observable<boolean> {
    return this._loadingSub.asObservable();
  }

  constructor(
    private http: HttpClient,
    private log: Logger,
    private optionsService: HttpOptionsService
  ) {}

  public delete<T>(
    url: string,
    C: new () => T,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<T> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.delete<SearchResult<T>>(url, options).pipe(
      map((resp) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }

        return checkResponseAndReturnSingle(C, resp, rethrowErrors);
      }),
      catchError((err) => this.handleError(err, false, rethrowErrors))
    );
  }

  public get<T>(
    url: string,
    C: new () => T,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<T> {
    const options = requestOptions || this.optionsService.get();

    return this.getList<T>(url, C, options, rethrowErrors).pipe(
      map((resp) => {
        if (resp.length > 0) {
          return resp[0];
        } else {
          return undefined;
        }
      })
    );
  }

  public getAny(url, requestOptions?: object, rethrowErrors = false): Observable<any> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.get(url, options).pipe(
      map((x) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }

        return x;
      }),
      catchError((err) => this.handleError(err, true, rethrowErrors))
    );
  }

  public getAnyWithType<T>(
    url,
    object: new () => T,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<any> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.get(url, options).pipe(
      map((x: any[]) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }

        if (object) {
          return x.map((o) => Object.assign(new object(), o));
        }

        return x;
      }),
      catchError((err) => this.handleError(err, true, rethrowErrors))
    );
  }

  /**
   * Make and HTTP get request to the server. This method will make sure that
   * the request will have any required headers attached.
   *
   * @param url             The URL that we are making the request to.
   * @param C               The object class for the returned data. This is the
   *                        same as T defined on the method call.
   * @param requestOptions  Any options that you want. This will also override
   *                        any of the default included headers.
   * @param rethrowErrors   If `true` any errors that arise will throw through
   *                        to the caller. If `false`, errors are handled
   *                        internally to the service and an empty list is
   *                        returned.
   */
  public getList<T>(
    url: string,
    C: new () => T,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<Array<T>> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.get<SearchResult<T>>(url, options).pipe(
      map((resp) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }

        return checkResponseAndReturnList(C, resp, rethrowErrors);
      }),
      catchError((err) => this.handleError(err, true, rethrowErrors))
    );
  }

  public patch<T>(
    url: string,
    data: any,
    C: new () => T,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<T> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.patch<SearchResult<T>>(url, data, options).pipe(
      map((resp) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }

        return checkResponseAndReturnSingle(C, resp, rethrowErrors);
      }),
      catchError((err) => this.handleError(err, false, rethrowErrors))
    );
  }

  public patchList<T>(
    url: string,
    data: any,
    C: new () => T,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<Array<T>> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.patch<SearchResult<T>>(url, data, options).pipe(
      map((resp) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }
        return checkResponseAndReturnList(C, resp, rethrowErrors);
      }),
      catchError((err) => this.handleError(err, true, rethrowErrors))
    );
  }

  public post<T>(
    url: string,
    data: any,
    C: new () => T,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<T> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    this._loadingSub.next(true);

    return this.http.post<SearchResult<T>>(url, data, options).pipe(
      map((resp) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }

        return checkResponseAndReturnSingle(C, resp, rethrowErrors);
      }),
      catchError((err) => this.handleError(err, false, rethrowErrors)),
      finalize(() => {
        this._loadingSub.next(false);
      })
    );
  }

  public postAny(
    url: string,
    data: any,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<any> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.post(url, data, options).pipe(
      map((x) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }

        return x;
      }),
      catchError((err) => this.handleError(err, true, rethrowErrors))
    );
  }

  public put<T>(
    url: string,
    data: any,
    C: new () => T,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<T> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.put<SearchResult<T>>(url, data, options).pipe(
      map((resp) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }

        return checkResponseAndReturnSingle(C, resp, rethrowErrors);
      }),
      catchError((err) => this.handleError(err, false, rethrowErrors))
    );
  }

  public putAny(
    url: string,
    data: any,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<any> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.put(url, data, options).pipe(
      map((x) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }
        return x;
      }),
      catchError((err) => this.handleError(err, false, rethrowErrors))
    );
  }

  public putList<T>(
    url: string,
    data: any,
    C: new () => T,
    requestOptions?: object,
    rethrowErrors = false
  ): Observable<Array<T>> {
    const options = requestOptions || this.optionsService.get();
    let logTime;
    if (window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      logTime = moment();
    }

    return this.http.put<SearchResult<T>>(url, data, options).pipe(
      map((resp) => {
        if (logTime) {
          this.runLogTime(logTime, url);
        }
        return checkResponseAndReturnList(C, resp, rethrowErrors);
      }),
      catchError((err) => this.handleError(err, true, rethrowErrors))
    );
  }

  private handleError(
    error: HttpErrorResponse | ServerError,
    isList = false,
    rethrowError = false
  ): Observable<[]> {
    let e: ServerError;

    if (error instanceof HttpErrorResponse) {
      e = new ServerError(error);
    } else {
      e = error;
    }

    if (rethrowError === true) {
      throw e;
    }

    console.error(e);

    if (isList === true) {
      return of([]);
    } else {
      return undefined;
    }
  }

  private runLogTime(logTime: Moment, url: string): void {
    const duration = moment().diff(logTime, 'milliseconds');
    if (duration >= window[WINDOW_PROP_LOG_HTTP_REQ_TIMES]) {
      this.log.warn(
        `${moment().format('HH:mm:ss.SSS')}> Request at (${logTime.format(
          'HH:mm:ss.SSS'
        )}) took excessive time of (${duration / 1000}s) for (${url})`
      );
    }
  }
}
