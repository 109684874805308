export class AggregatePit {
  plantId: number;
  zone: string;
  fuelSurChage: boolean;
  nextTicketNumber: number;
  nextBillOfLadingNumber: number;
  railScheduleEdiStoredProcedureName: string;
  object: string;

  constructor() {
    this.nextTicketNumber = 1;
    this.nextBillOfLadingNumber = 1;
    this.object = 'aggregatePit';
  }
}
