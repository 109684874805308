import { IPlant } from '@app/plants/shared/plant.interface';
import { Moment } from 'moment';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';
import moment from 'moment';

export class Plant implements IPlant {
  id: number;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  address6: string;
  address7: string;
  batchmanId: number;
  batchTime: number;
  callInSpaceTime: number;
  closeoutTime: number;
  description: string;
  divisionId: number;
  faxNumber: string;
  fixedCostQty: number;
  fleetSizeMonday: number;
  fleetSizeFriday: number;
  fleetSizeSaturday: number;
  fleetSizeSunday: number;
  fleetSizeThursday: number;
  fleetSizeTuesday: number;
  fleetSizeWednesday: number;
  gsprintCommandLineOptions: string;
  importSetupKey: number;
  inYardWashTime: number;
  inactiveFlag: boolean;
  isRail: boolean;
  latitude: string;
  longitude: string;
  nextWorkOrderNumber: string;
  object: string;
  onJobWashTime: number;
  phoneExtension: string;
  phoneNumber: string;
  plantColorId: number;
  priceAdjustment: number;
  printTicketMethod: number;
  radius: number;
  subAccountNumber: string;
  systemTypeId: number;
  ticketPrintQueue: string;
  truckCostHour: number;
  truckProductivityFactor: number;
  useBatchPanelFlag: boolean;
  warmupTime: number;

  private _lastBatchPanelInventory: Moment;
  get lastBatchPanelInventory(): Moment {
    return this._lastBatchPanelInventory;
  }
  set lastBatchPanelInventory(val) {
    this._lastBatchPanelInventory = convertIso8601ToDate(val);
  }

  private _modifiedOn: Moment;
  get modifiedOn(): Moment {
    return this._modifiedOn;
  }
  set modifiedOn(val) {
    this._modifiedOn = convertIso8601ToDate(val);
  }

  get gpsLat(): string {
    return this.latitude;
  }

  get gpsLon(): string {
    return this.longitude;
  }

  constructor() {
    this.address1 = '';
    this.address2 = '';
    this.address3 = '';
    this.address4 = '';
    this.address5 = '';
    this.address6 = '';
    this.address7 = '';
    this.batchTime = 0;
    this.batchmanId = 0;
    this.callInSpaceTime = 0;
    this.closeoutTime = 0;
    this.divisionId = 0;
    this.faxNumber = '';
    this.fixedCostQty = 0;
    this.fleetSizeFriday = 0;
    this.fleetSizeMonday = 0;
    this.fleetSizeSaturday = 0;
    this.fleetSizeSunday = 0;
    this.fleetSizeThursday = 0;
    this.fleetSizeTuesday = 0;
    this.fleetSizeWednesday = 0;
    this.gsprintCommandLineOptions = '';
    this.id = 0;
    this.importSetupKey = 0;
    this.inYardWashTime = 0;
    this.inactiveFlag = false;
    this.isRail = false;
    this.lastBatchPanelInventory = moment();
    this.modifiedOn = moment();
    this.nextWorkOrderNumber = '';
    this.object = 'plant';
    this.onJobWashTime = 0;
    this.phoneExtension = '';
    this.phoneNumber = '';
    this.plantColorId = 0;
    this.printTicketMethod = 0;
    this.latitude = '0';
    this.longitude = '0';
    this.radius = 0;
    this.subAccountNumber = '';
    this.ticketPrintQueue = '';
    this.truckCostHour = 0;
    this.truckProductivityFactor = 0;
    this.useBatchPanelFlag = false;
    this.warmupTime = 0;
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}
