/**
 * Created by Langley on 3/13/2016.
 */

/**
 * The available options to set the Level of the Logger.
 * See {@link Logger}.
 */
export enum Level {
  OFF = 0,
  ERROR = 1,
  WARN = 2,
  INFO = 3,
  DEBUG = 4,
  LOG = 5
}
