import moment from 'moment';
import { IDisplayItem } from '@app/shared/models/display-item.interface';
import { Moment } from 'moment';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';

export class Employee implements IDisplayItem {
  emailTicketOnSubmit: boolean;
  inactiveFlag: boolean;
  punchOutFlag: boolean;
  payRate: number;
  homePlantId: number;
  id: number;
  employeeTypeId: number;
  userProfileId: number;
  object: string;
  licenseNumber: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  address6: string;
  address7: string;
  businessPhone: string;
  cellPhone: string;
  completeName: string;
  emailAddress: string;
  employeeNumber: string;
  firstName: string;
  homePhone: string;
  lastName: string;
  middleName: string;
  pager: string;
  socialSecurityNumber: string;
  ticketWriterFormatFileAggregate: string;
  ticketWriterFormatFileBlock: string;
  ticketWriterFormatFileConcrete: string;

  private _modifiedDateTime: Moment;
  get modifiedDateTime(): Moment {
    return this._modifiedDateTime;
  }
  set modifiedDateTime(val) {
    this._modifiedDateTime = convertIso8601ToDate(val);
  }

  get autoCompleteDisplay(): string {
    return this.completeName;
  }

  get identifier(): number {
    return this.id;
  }

  private _hireDate: Moment;
  get hireDate(): Moment {
    return this._hireDate;
  }
  set hireDate(val) {
    this._hireDate = convertIso8601ToDate(val);
  }

  private _birthDate: Moment;
  get birthDate(): Moment {
    return this._birthDate;
  }
  set birthDate(val) {
    this._birthDate = convertIso8601ToDate(val);
  }

  constructor() {
    this.modifiedDateTime = moment();
    this.hireDate = moment();
    this.inactiveFlag = false;
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}
