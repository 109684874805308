import { Injectable } from '@angular/core';

export const getWindow = (): Window => window;

@Injectable({ providedIn: 'root' })
export class WindowRefService {
  get window(): Window {
    return getWindow();
  }
}
