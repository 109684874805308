import { BehaviorSubject, Observable, of } from 'rxjs';
import { ISystemType } from '@app/shared/services/system-type/system-type.interface';
import { Injectable } from '@angular/core';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { SubQuoteType } from '@app/quotes/models/quote.model';
import { UserGroup } from '@app/user-groups/user-group.model';
import { UserGroupService } from '@app/user-groups/user-group.service';
import { concatMap, first, map } from 'rxjs/operators';

export const SystemType: { Concrete: ISystemType; Aggregate: ISystemType; Block: ISystemType } = {
  Concrete: { id: 1, name: 'Concrete', groupId: 1001 },
  Aggregate: { id: 2, name: 'Aggregate', groupId: 1002 },
  Block: { id: 3, name: 'Block', groupId: 1003 }
};

export const SYSTEM_TYPE_ARRAY: ISystemType[] = [
  { id: 1, name: 'Concrete', groupId: 1001 },
  { id: 2, name: 'Aggregate', groupId: 1002 },
  { id: 3, name: 'Block', groupId: 1003 }
];

export const getQuoteProp = (systemTypeId: number): SubQuoteType =>
  systemTypeId === SystemType.Concrete.id
    ? 'concreteQuote'
    : systemTypeId === SystemType.Aggregate.id
    ? 'aggregateQuote'
    : 'blockQuote';

@Injectable({ providedIn: 'root' })
export class SystemTypeService {
  private loaded = false;
  private systemTypesLoadedUpdater: BehaviorSubject<boolean>;
  private _userSystemTypes: Set<number>;

  public get userSystemTypes(): Set<number> {
    return this._userSystemTypes;
  }

  public get waitUserSystemTypesLoaded$(): Observable<boolean> {
    return this.systemTypesLoadedUpdater.pipe(first((isLoaded) => isLoaded));
  }

  public get waitAndGetSystemTypes$(): Observable<Set<number>> {
    return this.waitUserSystemTypesLoaded$.pipe(
      concatMap(() => this.getSystemTypesForUserGroups$())
    );
  }

  constructor(
    private loginStatusService: LoginStatusService,
    private userGroupService: UserGroupService
  ) {
    this.systemTypesLoadedUpdater = new BehaviorSubject<boolean>(false);

    this.loginStatusService.loginStatus.subscribe((loginStatus: LoginStatusModel) => {
      this.onLoginStatusChange(loginStatus);
    });
  }

  public getSystemTypesForUserGroups(userGroups: Array<UserGroup>): Array<ISystemType> {
    const userGroupIds = userGroups.map((userGroup) => userGroup.id);
    return SYSTEM_TYPE_ARRAY.filter((systemType) => userGroupIds.indexOf(systemType.groupId) > -1);
  }

  public getSystemTypesForUserGroups$(): Observable<Set<number>> {
    const userGroupIds = this.userGroupService
      .getUserGroups()
      .pipe(map((userGroups) => userGroups.map((x) => x.id)));

    if (this.loaded) {
      return of(this.userSystemTypes);
    }

    return userGroupIds.pipe(
      map((ids: number[]) => {
        const availableSystemTypes = SYSTEM_TYPE_ARRAY.filter(
          (systemType) => ids.indexOf(systemType.groupId) > -1
        );

        this._userSystemTypes = new Set<number>();

        availableSystemTypes.forEach((systemType) => {
          this._userSystemTypes.add(systemType.id);
        });

        this.loaded = true;
        this.systemTypesLoadedUpdater.next(true);
        return this._userSystemTypes;
      })
    );
  }

  public getAllSystemTypes(): Array<ISystemType> {
    return SYSTEM_TYPE_ARRAY;
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    if (loginStatus.loggedIn === true) {
      this.getSystemTypesForUserGroups$().pipe(first()).subscribe();
    } else {
      this.cleanCache();
    }
  }

  private cleanCache(): void {
    this.loaded = false;
    this._userSystemTypes = new Set<number>();
    this.systemTypesLoadedUpdater.next(false);
  }
}
