export interface IConnectionInfo {
  database: string;
  loggedIn: boolean;
  object: string;
  programNameId: string;
  username: string;
}

export class ConnectionInfo implements IConnectionInfo {
  public database: string;
  public loggedIn: boolean;
  public object: string;
  public programNameId: string;
  public username: string;
}
