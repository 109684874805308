import { Form } from '@app/forms/form.model';
import { SystemType } from '@app/shared/services/system-type/system-type.service';
import {
  faCalendarDays,
  faEnvelopeOpenText,
  faEnvelopes,
  faPersonWalkingArrowRight,
  faFileLines,
  faPrint,
  faReceipt,
  faSlidersH,
  faTools,
  faTruckContainer
} from '@fortawesome/pro-solid-svg-icons';

export const AUJS_FORMS = {
  SystemForm: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'SystemForm',
    icon: '',
    id: 999999,
    legacy: false,
    name: '',
    longName: '',
    path: ''
  }),
  FormNgLetter: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgLetter',
    icon: 'fa-envelope',
    id: 69000,
    legacy: false,
    name: 'Form Letter',
    longName: 'Form Letter',
    path: '/credit/form-letter',
    translationId: 'acs-menu.credit.form-letter'
  }),
  FormNgLetterMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgLetterMaintenance',
    faIcon: faEnvelopes,
    id: 70000,
    legacy: false,
    name: 'Form Letter Maintenance',
    longName: 'Form Letter Maintenance',
    path: '/credit/form-letter-maintenance',
    translationId: 'acs-menu.credit.form-letter-maintenance'
  }),
  FormNgLoadSchedulingScreen: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgLoadSchedulingScreen',
    icon: 'fa-road',
    id: 17000,
    legacy: false,
    name: 'Load Scheduling',
    longName: 'Load Scheduling',
    path: '/load-scheduling',
    systemTypeId: SystemType.Concrete.id,
    translationId: 'acs-menu.operations.load-scheduling'
  }),
  // BEGIN PRODUCT MAINTENANCE
  FormNgProductMaintenance: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgProductMaintenance',
    icon: 'fa-cogs',
    id: 11000,
    legacy: false,
    longName: '',
    name: '',
    path: '',
    translationId: ''
  }),
  FormNgAggregateProductMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgAggregateProductMaintenance',
    icon: 'fa-cogs',
    id: 11000,
    legacy: false,
    longName: 'Aggregate Product Maintenance',
    name: 'Aggregate Products',
    path: '/products/maintenance/aggregate',
    systemTypeId: SystemType.Aggregate.id,
    translationId: 'acs-menu.sales.product-maintenance.aggregate.label'
  }),
  FormNgBlockProductMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgBlockProductMaintenance',
    icon: 'fa-cogs',
    id: 11000,
    legacy: false,
    longName: 'Block Product Maintenance',
    name: 'Block Products',
    path: '/products/maintenance/block',
    systemTypeId: SystemType.Block.id,
    translationId: 'acs-menu.sales.product-maintenance.block.label'
  }),
  FormNgConcreteProductMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgConcreteProductMaintenance',
    icon: 'fa-cogs',
    id: 11000,
    legacy: false,
    longName: 'Concrete Product Maintenance',
    name: 'Concrete Products',
    path: '/products/maintenance/concrete',
    systemTypeId: SystemType.Concrete.id,
    translationId: 'acs-menu.sales.product-maintenance.concrete.label'
  }),
  FormNgOtherProductMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgOtherProductMaintenance',
    icon: 'fa-cogs',
    id: 11000,
    legacy: false,
    longName: 'Other Product Maintenance',
    name: 'Other Products',
    path: '/products/maintenance/other',
    translationId: 'acs-menu.sales.product-maintenance.other.label'
  }),
  FormNgRawMaterialProductMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgRawMaterialProductMaintenance',
    icon: 'fa-cogs',
    id: 11000,
    legacy: false,
    longName: 'Raw Material Product Maintenance',
    name: 'Raw Material Products',
    path: '/products/maintenance/raw-material',
    translationId: 'acs-menu.sales.product-maintenance.raw-material.label'
  }),
  // END PRODUCT MAINTENANCE
  FormNgCustomerMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgCustomerMaintenance',
    icon: 'fa-address-card-o',
    id: 2000,
    legacy: false,
    longName: 'Customer Maintenance',
    name: 'Customer Maintenance',
    path: '/customers/maintenance',
    translationId: 'acs-menu.credit.customer-maintenance'
  }),
  FormNgPreliminaryNoticeInput: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgPreliminaryNoticeInput',
    faIcon: faEnvelopeOpenText,
    id: 71000,
    legacy: false,
    longName: 'Preliminary Notice Input',
    name: 'Preliminary Notice',
    path: '/credit/preliminary-notice-input'
  }),
  FormNgPreliminaryNoticePrint: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgPreliminaryNoticePrint',
    faIcon: faPrint,
    id: 72000,
    legacy: false,
    longName: 'Print Preliminary Notice',
    name: 'Print Preliminary Notice',
    path: '/credit/preliminary-notice-print'
  }),
  FormNgAuthorizedPurchaser: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgAuthorizedPurchaser',
    icon: '',
    id: 9000,
    legacy: false,
    longName: 'Authorized Purchaser',
    name: 'Authorized Purchaser',
    path: ''
  }),
  FormNgJobMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgJobMaintenance',
    icon: 'fa-building-o',
    id: 13000,
    legacy: false,
    longName: 'Job Maintenance',
    name: 'Job Maintenance',
    path: '/jobs/maintenance',
    translationId: 'acs-menu.sales.job-maintenance.label'
  }),
  FormNgPumperMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgPumperMaintenance',
    icon: 'fa-filter',
    id: 63000,
    legacy: false,
    longName: 'Pumper Maintenance',
    name: 'Pumper Maintenance',
    path: '/pumpers/maintenance',
    translationId: 'acs-menu.operations.pumper-maintenance'
  }),
  FormNgTruckMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgTruckMaintenance',
    icon: 'fa-truck',
    id: 39000,
    legacy: false,
    longName: 'Truck Maintenance',
    name: 'Truck Maintenance',
    path: '/trucks/maintenance',
    translationId: 'acs-menu.operations.truck-maintenance'
  }),
  FormNgTruckCompanyMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgTruckCompanyMaintenance',
    icon: 'fa-truck',
    id: 41000,
    legacy: false,
    longName: 'Truck Company Maintenance',
    name: 'Truck Company Maintenance',
    path: '/trucks/company-maintenance'
  }),
  FormNgEmployeeMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgEmployeeMaintenance',
    icon: 'fa-user',
    id: 14000,
    legacy: false,
    longName: 'Employee Maintenance',
    name: 'Employee Maintenance',
    path: '/employees/maintenance',
    translationId: 'acs-menu.operations.employee-maintenance'
  }),
  FormNgPlantMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgPlantMaintenance',
    icon: 'fa-map-marker',
    id: 42000,
    legacy: false,
    longName: 'Plant Maintenance',
    name: 'Plant Maintenance',
    path: '/plants/maintenance',
    translationId: 'acs-menu.operations.plant-maintenance'
  }),
  FormNgQuoteMaintenance: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgQuoteMaintenance',
    icon: 'fa-usd',
    id: 5000,
    legacy: false,
    longName: 'Quote Maintenance',
    name: 'Quote Maintenance',
    path: '/quote/maintenance',
    translationId: 'acs-menu.sales.quote-maintenance.label'
  }),
  FormNgOrderEntryConcrete: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgOrderEntryConcrete',
    icon: 'fa-pencil-square-o',
    id: 15000,
    legacy: false,
    longName: 'Concrete Order Entry',
    name: 'Order Entry',
    path: '/orders/concrete',
    systemTypeId: SystemType.Concrete.id,
    translationId: 'acs-menu.operations.concrete.order-entry.label'
  }),
  FormNgConcreteMixModification: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgConcreteMixModification',
    icon: 'fa-pencil-square-o',
    id: 190000,
    legacy: false,
    longName: 'Concrete Mix Modification',
    name: 'Mix Modification',
    systemTypeId: SystemType.Concrete.id,
    path: '/operations/concrete-mix-modification'
  }),
  FormNgQuickSaleConcrete: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgQuickSaleConcrete',
    icon: 'fa-pencil-square-o',
    id: 253000,
    legacy: false,
    longName: 'Concrete Quick Sale',
    name: 'Quick Sale',
    path: '/orders/concrete-quick-sale',
    systemTypeId: SystemType.Concrete.id,
    translationId: 'acs-menu.operations.concrete.quick-sale.label'
  }),
  FormNgRawMaterialRanking: new Form({
    availableInQuickMenu: false,
    enabled: true,
    enumId: 'FormNgRawMaterialRanking',
    icon: 'fa-angle-double-up',
    id: 210000,
    legacy: false,
    longName: 'Raw Material Ranking',
    name: 'Raw Material Ranking',
    path: '/operations/raw-material-ranking'
  }),
  FormNgMixDesignChange: new Form({
    availableInQuickMenu: false,
    enabled: true,
    enumId: 'FormNgMixDesignChange',
    faIcon: faTools,
    id: 209000,
    legacy: false,
    longName: 'Global Mix Design Change',
    name: 'Mix Design Change',
    path: '/operations/global-mix-design-change',
    systemTypeId: SystemType.Concrete.id
  }),
  FormNgOrderEntryAggregate: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgOrderEntryAggregate',
    icon: 'fa-pencil-square-o',
    id: 15501,
    legacy: false,
    longName: 'Aggregate Order Entry',
    name: 'Order Entry',
    path: '/orders/aggregate',
    systemTypeId: SystemType.Aggregate.id,
    translationId: 'acs-menu.operations.aggregate.order-entry.label'
  }),
  FormNgQuickSaleAggregate: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgQuickSaleAggregate',
    icon: 'fa-pencil-square-o',
    id: 253000,
    legacy: false,
    longName: 'Aggregate Quick Sale',
    name: 'Quick Sale',
    path: '/orders/aggregate-quick-sale',
    systemTypeId: SystemType.Aggregate.id,
    translationId: 'acs-menu.operations.aggregate.quick-sale.label'
  }),
  FormNgDeliveryChargeMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: false,
    enabled: false,
    enumId: 'FormNgDeliveryChargeMaintenance',
    icon: 'fa-usd',
    id: 51000,
    legacy: false,
    isAdmin: false,
    name: 'Delivery Charge Maintenance',
    path: '/operations/delivery-charge-maintenance',
    translationId: 'acs-menu.operations.aggregate.delivery-charge-maintenance.label'
  }),
  FormNgOrderEntryBlock: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgOrderEntryBlock',
    icon: 'fa-pencil-square-o',
    id: 18000,
    legacy: false,
    longName: 'Block Order Entry',
    name: 'Order Entry',
    path: '/orders/block',
    systemTypeId: SystemType.Block.id,
    translationId: 'acs-menu.operations.block.order-entry.label'
  }),
  FormNgBatchLoadScreen: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgBatchLoadScreen',
    icon: 'fa-tasks',
    id: 67000,
    legacy: false,
    longName: 'Batch Load Screen',
    name: 'Batch Load Screen',
    path: '/batch-load-screen',
    translationId: 'acs-menu.operations.batch-load-screen'
  }),
  FormNgBatchLoadScreenBlock: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgBatchLoadScreenBlock',
    icon: 'fa-tasks',
    id: 146000,
    legacy: false,
    longName: 'Batch Load Screen Block',
    name: 'Batch Load Screen Block',
    path: '/batch-load-screen/block'
  }),
  FormNgConcreteTicketing: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgConcreteTicketing',
    icon: 'fa-pencil-square-o',
    id: 26000,
    legacy: false,
    longName: 'Concrete Ticketing BETA',
    name: 'Concrete Ticketing BETA',
    systemTypeId: SystemType.Concrete.id,
    path: '/ticketing/concrete-ticket-entry'
  }),
  FormNgAggregateTicketing: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgAggregateTicketing',
    icon: 'fa-pencil-square-o',
    id: 22000,
    legacy: false,
    longName: 'Aggregate Ticketing',
    name: 'Ticketing',
    path: '/ticketing/aggregate-ticket-entry',
    systemTypeId: SystemType.Aggregate.id,
    translationId: 'acs-menu.operations.aggregate.ticketing.label'
  }),
  FormNgBlockTicketing: new Form({
    availableInQuickMenu: false,
    enabled: true,
    enumId: 'FormNgBlockTicketing',
    icon: 'fa-pencil-square-o',
    id: 25000,
    legacy: false,
    longName: 'Block Ticketing',
    name: 'Ticketing',
    systemTypeId: SystemType.Block.id,
    path: '/ticketing/block-ticket-entry'
  }),
  FormNgEditTickets: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgEditTickets',
    icon: 'fa-eraser',
    id: 50000,
    legacy: false,
    longName: 'Edit Tickets',
    name: 'Edit Tickets',
    path: '/ticketing/edit-tickets',
    translationId: 'acs-menu.ticket-procedures.edit.label'
  }),
  FormNgEditTicketTimes: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgEditTicketTimes',
    icon: 'fa-eraser',
    id: 50001,
    legacy: false,
    longName: 'Edit Ticket Times',
    name: 'Edit Ticket Times',
    path: '/operations/edit-ticket-times',
    translationId: 'acs-menu.operations.edit-ticket-times'
  }),
  FormNgBatchEditTicket: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgBatchEditTicket',
    icon: 'fa-files-o',
    id: 132000,
    legacy: false,
    longName: 'Batch Edit Tickets',
    name: 'Batch Edit Tickets',
    path: '/ticketing/batch-edit-tickets',
    translationId: 'acs-menu.ticket-procedures.batch-edit.label'
  }),
  FormNgExportTicketData: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgExportTicketData',
    icon: 'fa-download',
    id: 111000,
    legacy: false,
    longName: 'Export Ticket Data',
    name: 'Export Ticket Data',
    path: '/accounting/export',
    translationId: 'acs-menu.ticket-procedures.export.label'
  }),
  FormNgDashboard: new Form({
    availableInQuickMenu: true,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgDashboard',
    icon: 'fa-tachometer',
    id: 999999,
    legacy: false,
    longName: 'Dashboard',
    name: 'Dashboard',
    path: 'home',
    translationId: 'acs-menu.dashboard.label'
  }),
  FormNgReports: new Form({
    availableInQuickMenu: true,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgReports',
    faIcon: faFileLines,
    id: 1001000,
    legacy: false,
    longName: 'Reports',
    name: 'Reports',
    path: '/reports',
    translationId: 'acs-menu.reports.reports'
  }),
  FormNgNLQ: new Form({
    availableInQuickMenu: true,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgNLQ',
    icon: 'fa-pencil',
    id: 1001002,
    legacy: false,
    longName: 'ChatGPT',
    name: 'ChatGPT',
    path: '/adhoc-queries/nlq',
    translationId: 'acs-menu.reports.aiAssistedQueries'
  }),
  FormNgAdhocQueries: new Form({
    availableInQuickMenu: false,
    alwaysShow: false,
    enabled: false,
    enumId: 'FormNgAdhocQueries',
    icon: 'fa-table',
    id: 1001001,
    legacy: false,
    longName: 'Queries',
    name: 'Queries',
    path: '/queries',
    translationId: 'acs-menu.reports.queries'
  }),
  FormNgAuditOrderConcrete: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgAuditOrderConcrete',
    icon: 'fa-search',
    id: 46000,
    legacy: false,
    name: 'Concrete Audit Orders',
    path: '/orders/audit/concrete',
    systemTypeId: SystemType.Concrete.id,
    translationId: 'acs-menu.sales.audit-orders.concrete.label'
  }),
  FormNgAuditOrderAggregate: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgAuditOrderAggregate',
    icon: 'fa-search',
    id: 46501,
    legacy: false,
    name: 'Aggregate Audit Orders',
    path: '/orders/audit/aggregate',
    systemTypeId: SystemType.Aggregate.id,
    translationId: 'acs-menu.sales.audit-orders.aggregate.label'
  }),
  FormNgAuditOrderBlock: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgAuditOrderBlock',
    icon: 'fa-search',
    id: 47000,
    legacy: false,
    name: 'Block Audit Orders',
    path: '/orders/audit/block',
    systemTypeId: SystemType.Block.id,
    translationId: 'acs-menu.sales.audit-orders.block.label'
  }),
  FormNgInvoiceFlag: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgInvoiceFlag',
    icon: 'fa-pencil-square-o',
    id: 214000,
    legacy: false,
    name: 'Flag Invoice',
    path: 'orders/flag-invoice',
    translationId: 'acs-menu.ticket-procedures.flag-invoice.label'
  }),
  FormNgTicketInquiry: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgTicketInquiry',
    icon: 'fa-list-alt',
    id: 222000,
    legacy: false,
    longName: 'Ticket Inquiry',
    name: 'Ticket Inquiry',
    path: 'inquiry/ticket',
    translationId: 'acs-menu.ticket-procedures.ticket-inquiry.label'
  }),
  FormNgInvoiceInquiry: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormNgInvoiceInquiry',
    icon: 'fa-list-alt',
    id: 224000,
    legacy: false,
    longName: 'Invoice Inquiry',
    name: 'Invoice Inquiry',
    path: 'inquiry/invoice',
    translationId: 'acs-menu.ticket-procedures.invoice-inquiry.label'
  }),
  // inventory
  FormNgHaulerMaintenance: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgHaulerMaintenance',
    faIcon: faTruckContainer,
    id: 239000,
    legacy: false,
    longName: 'Hauler Maintenance',
    name: 'Hauler Maintenance',
    path: 'inventory/hauler-maintenance'
  }),
  FormNgVendorMaintenance: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgVendorMaintenance',
    faIcon: faTruckContainer,
    id: 163000,
    legacy: false,
    longName: 'Vendor Maintenance',
    name: 'Vendor Maintenance',
    path: 'inventory/vendor-maintenance'
  }),
  FormNgSourceMaintenance: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgSourceMaintenance',
    faIcon: faTruckContainer,
    id: 240000,
    legacy: false,
    longName: 'Source Maintenance',
    name: 'Source Maintenance',
    path: 'inventory/source-maintenance'
  }),
  FormNgMaterialReceiptMaintenance: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgMaterialReceiptMaintenance',
    faIcon: faReceipt,
    id: 164000,
    legacy: false,
    longName: 'Material Receipt Maintenance',
    name: 'Material Receipt Maintenance',
    path: 'inventory/material-receipt-maintenance'
  }),
  FormNgReceiveMaterialReceipt: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgReceiveMaterialReceipt',
    faIcon: faReceipt,
    id: 165000,
    legacy: false,
    longName: 'Receive Material Receipt',
    name: 'Receive Material Receipt',
    path: 'inventory/receive-material-receipts'
  }),
  FormNgInventoryAdjustments: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgInventoryAdjustments',
    faIcon: faSlidersH,
    id: 169000,
    legacy: false,
    longName: 'Adjustments',
    name: 'Adjustments',
    path: 'inventory/adjustments'
  }),
  FormNgInvoiceMaterialReceipt: new Form({
    availableInQuickMenu: false,
    enabled: false,
    enumId: 'FormNgInvoiceMaterialReceipt',
    faIcon: faReceipt,
    id: 170000,
    legacy: false,
    longName: 'Invoice Material Receipt',
    name: 'Invoice Material Receipt',
    path: 'inventory/invoice-material-receipts'
  }),
  FormNgCreditTickets: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgCreditTickets',
    icon: 'fas fa-exclamation-circle',
    id: 202000,
    legacy: false,
    isAdmin: false,
    name: 'CreditTickets',
    path: ''
  }),
  FormNgScaleMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: false,
    enabled: false,
    enumId: 'FormNgScaleMaintenance',
    icon: 'fas fa-exclamation-circle',
    id: 65000,
    legacy: false,
    isAdmin: false,
    name: 'Scale Maintenance',
    path: ''
  }),
  FormNgHaulRatesMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: false,
    enabled: false,
    enumId: 'FormNgHaulRatesMaintenance',
    icon: 'fas fa-exclamation-circle',
    id: 148100,
    legacy: false,
    isAdmin: false,
    name: 'Hauler Rate Maintenance',
    path: ''
  }),
  //------------------------------------------------------------------------------------------------\\
  // Below this line admin forms
  //------------------------------------------------------------------------------------------------\\
  FormNgReportSchedule: new Form({
    availableInQuickMenu: true,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgReportSchedule',
    faIcon: faCalendarDays,
    id: 999999,
    legacy: false,
    longName: 'Report Scheduling',
    name: 'Report Scheduling',
    path: 'administration/report-scheduling',
    translationId: 'acs-menu.administration.report-scheduling.label'
  }),
  FormNgCreditStatus: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgCreditStatus',
    icon: 'fas fa-exclamation-circle',
    id: 999999,
    legacy: false,
    isAdmin: true,
    name: 'Credit Status',
    path: 'administration/credit-status',
    translationId: 'acs-menu.administration.credit-status.label'
  }),
  FormNgTruckTypeMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgTruckTypeMaintenance',
    icon: 'fa-truck',
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'Truck Type Maintenance',
    path: 'administration/truck-type-maintenance',
    translationId: 'acs-menu.administration.truck-type-maintenance.label'
  }),
  FormNgGroupMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgGroupMaintenance',
    icon: 'fa-file',
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'Group Maintenance',
    path: 'administration/group-maintenance',
    translationId: 'acs-menu.administration.group-maintenance.label'
  }),
  FormNgTermsDiscount: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgTermsDiscount',
    icon: 'fa-usd',
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'Terms Discount',
    path: 'administration/terms-discount',
    translationId: 'acs-menu.administration.terms-discount.label'
  }),
  FormNgUserMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgUserMaintenance',
    icon: 'fa-users',
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'User Maintenance',
    path: 'administration/user-maintenance',
    translationId: 'acs-menu.administration.user-maintenance.label'
  }),
  FormNgGLCategoryMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgGLCategoryMaintenance',
    icon: 'fa-usd',
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'G/L Category Maintenance',
    path: 'administration/gl-category-maintenance',
    translationId: 'acs-menu.administration.gl-category-maintenance.label'
  }),
  FormNgShortLoadMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgShortLoadMaintenance',
    icon: 'fa-truck',
    id: 21000,
    isAdmin: true,
    legacy: false,
    name: 'Short Load Maintenance',
    path: 'administration/short-load-maintenance',
    translationId: 'acs-menu.administration.short-load-maintenance.label'
  }),
  FormNgStaticProducts: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgStaticProducts',
    icon: 'fa-cogs',
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'Static Products',
    path: 'administration/static-products',
    translationId: 'acs-menu.administration.static-products.label'
  }),
  FormNgTaxLevelMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgTaxLevelMaintenance',
    icon: 'fa-money',
    id: 58000,
    isAdmin: true,
    legacy: false,
    name: 'Tax Level Maintenance',
    path: 'administration/tax-level-maintenance',
    translationId: 'acs-menu.administration.tax-level-maintenance.label'
  }),
  FormNgTaxScheduleMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgTaxScheduleMaintenance',
    icon: 'fa-money',
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'Tax Schedule Maintenance',
    path: 'administration/tax-schedule-maintenance',
    translationId: 'acs-menu.administration.tax-schedule-maintenance.label'
  }),
  FormNgProductPriceEscalation: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgProductPriceEscalation',
    icon: 'fa-usd',
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'Product Price Escalation',
    path: 'administration/product-price-escalation',
    translationId: 'acs-menu.administration.product-price-escalation.label'
  }),
  FormNgQuotePriceEscalation: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgQuotePriceEscalation',
    icon: 'fa-usd',
    id: 64000,
    isAdmin: true,
    legacy: false,
    name: 'Quote Price Escalation',
    path: 'administration/quote-price-escalation',
    translationId: 'acs-menu.administration.quote-price-escalation.label'
  }),
  FormNgSystemParameters: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgSystemParameters',
    icon: 'fa-file-text',
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'System Parameters',
    path: 'administration/system-parameters',
    translationId: 'acs-menu.administration.system-parameters.label'
  }),
  FormNgExternalUserMaintenance: new Form({
    availableInQuickMenu: false,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormNgExternalUserMaintenance',
    faIcon: faPersonWalkingArrowRight,
    id: 999999,
    isAdmin: true,
    legacy: false,
    name: 'External User Maintenance',
    path: 'administration/external-users',
    translationId: 'acs-menu.administration.external-user-maintenance.label'
  }),
  //------------------------------------------------------------------------------------------------\\
  // Below this line classic forms
  // ------------------------------------------------------------------------------------------------\\
  FormAbout: new Form({
    alwaysShow: true,
    enabled: true,
    enumId: 'FormAbout',
    icon: 'fa-info-circle',
    id: 1000,
    legacy: true,
    name: 'About'
  }),
  FormAdmixDosingByTravelMinutes: new Form({
    enabled: true,
    enumId: 'FormAdmixDosingByTravelMinutes',
    icon: '',
    id: 237000,
    legacy: true,
    name: 'Admix Dosing by Travel Minutes'
  }),
  FormAuditOrderConcrete: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormAuditOrderConcrete',
    icon: 'fa-adn',
    id: 46000,
    legacy: true,
    longName: 'Classic Concrete Audit Orders',
    name: 'Concrete'
  }),
  FormAuditOrderAggregate: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormAuditOrderAggregate',
    icon: 'fa-adn',
    id: 46501,
    legacy: true,
    longName: 'Classic Aggregate Audit Orders',
    name: 'Aggregate'
  }),
  FormAuditOrderBlock: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormAuditOrderBlock',
    icon: 'fa-adn',
    id: 47000,
    legacy: true,
    longName: 'Classic Block Audit Orders',
    name: 'Block'
  }),
  FormBatchLoadScreen: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormBatchLoadScreen',
    icon: 'fa-tasks',
    id: 67000,
    legacy: true,
    name: 'Classic Batch Load Screen'
  }),
  FormBatchLoadScreenBlock: new Form({
    enabled: true,
    enumId: 'FormBatchLoadScreenBlock',
    icon: '',
    id: 146000,
    legacy: true,
    name: 'Batch Load Screen Block'
  }),
  FormBid: new Form({
    enabled: true,
    enumId: 'FormBid',
    icon: '',
    id: 133000,
    legacy: true,
    name: 'Bid Worksheet'
  }),
  FormCalculatorBlock: new Form({
    enabled: true,
    enumId: 'FormCalculatorBlock',
    icon: '',
    id: 104001,
    legacy: true,
    name: 'Block Calculator'
  }),
  FormCalculatorConcrete: new Form({
    enabled: true,
    enumId: 'FormCalculatorConcrete',
    icon: '',
    id: 104000,
    legacy: true,
    name: 'Concrete Calculator'
  }),
  FormCustomer: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormCustomer',
    icon: 'fa-address-card-o',
    id: 2000,
    legacy: true,
    name: 'Classic Customer Maintenance'
  }),
  FormDeliveryCharge: new Form({
    enabled: true,
    enumId: 'FormDeliveryCharge',
    icon: '',
    id: 51000,
    legacy: true,
    name: 'Delivery Charge Maintenance'
  }),
  FormDirections: new Form({
    enabled: true,
    enumId: 'FormDirections',
    icon: '',
    id: 166000,
    legacy: true,
    name: 'Directions'
  }),
  FormEmployee: new Form({
    availableInQuickMenu: false,
    enabled: true,
    enumId: 'FormEmployee',
    icon: 'fa-user-circle-o',
    id: 14000,
    legacy: true,
    name: 'Classic Employee Maintenance'
  }),
  FormEmployeeTimes: new Form({
    enabled: true,
    enumId: 'FormEmployeeTimes',
    icon: '',
    id: 147000,
    legacy: true,
    name: 'Edit Employee Times'
  }),
  FormInvoiceFlag: new Form({
    enabled: true,
    enumId: 'FormInvoiceFlag',
    icon: '',
    id: 214000,
    legacy: true,
    name: 'Classic Flag Invoice'
  }),
  FormHauler: new Form({
    enabled: true,
    enumId: 'FormHauler',
    icon: '',
    id: 163000,
    legacy: true,
    name: 'Hauler Maintenance'
  }),
  FormInventoryAdjustment: new Form({
    enabled: true,
    enumId: 'FormInventoryAdjustment',
    icon: '',
    id: 169000,
    legacy: true,
    name: 'Adjustments'
  }),
  FormInventoryPhysicalCount: new Form({
    enabled: true,
    enumId: 'FormInventoryPhysicalCount',
    icon: '',
    id: 195000,
    legacy: true,
    name: 'Physical Count Entry'
  }),
  FormInventoryPhysicalCountBatch: new Form({
    enabled: true,
    enumId: 'FormInventoryPhysicalCountBatch',
    icon: '',
    id: 196000,
    legacy: true,
    name: 'Batch Physical Counts'
  }),
  FormInvoiceInquiry: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormInvoiceInquiry',
    icon: 'fa-list-alt',
    id: 224000,
    legacy: true,
    name: 'Classic Invoice Inquiry'
  }),
  FormJob: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormJob',
    icon: 'fa-building-o',
    id: 13000,
    legacy: true,
    longName: 'Classic Job Maintenance',
    name: 'Classic Job Maintenance'
  }),
  FormLabels: new Form({
    enabled: true,
    enumId: 'FormLabels',
    icon: '',
    id: 144000,
    legacy: true,
    name: 'Customer Labels'
  }),
  FormLetter: new Form({
    enabled: true,
    enumId: 'FormLetter',
    icon: '',
    id: 69000,
    legacy: true,
    name: 'Form Letters'
  }),
  FormLetterMaintenance: new Form({
    enabled: true,
    enumId: 'FormLetterMaintenance',
    icon: '',
    id: 70000,
    legacy: true,
    name: 'Form Letter Maintenance'
  }),
  FormLoadDemand: new Form({
    enabled: true,
    enumId: 'FormLoadDemand',
    icon: '',
    id: 167000,
    legacy: true,
    name: 'Load Demand'
  }),
  FormLoadSchedulingScreen: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormLoadSchedulingScreen',
    icon: 'fa-road',
    id: 17000,
    legacy: true,
    name: 'Classic Load Scheduling'
  }),
  FormMapTravelTimes: new Form({
    enabled: true,
    enumId: 'FormMapTravelTimes',
    icon: '',
    id: 7000,
    legacy: true,
    name: 'Edit Map Travel Times'
  }),
  FormMaterialInvoice: new Form({
    enabled: true,
    enumId: 'FormMaterialInvoice',
    icon: '',
    id: 170000,
    legacy: true,
    name: 'Invoice Material Receipts'
  }),
  FormMaterialReceipt: new Form({
    enabled: true,
    enumId: 'FormMaterialReceipt',
    icon: '',
    id: 164000,
    legacy: true,
    name: 'Material Receipt Maintenance'
  }),
  FormMaterialReceiptsReceive: new Form({
    enabled: true,
    enumId: 'FormMaterialReceiptsReceive',
    icon: '',
    id: 165000,
    legacy: true,
    name: 'Receive Material Receipts'
  }),
  FormMixDesignAggregate: new Form({
    enabled: true,
    enumId: 'FormMixDesignAggregate',
    icon: '',
    id: 209000,
    legacy: true,
    name: 'Mix Maintenance'
  }),
  FormMixDesignChange: new Form({
    enabled: true,
    enumId: 'FormMixDesignChange',
    icon: '',
    id: 209000,
    legacy: true,
    name: 'Global Mix Design Change'
  }),
  FormMixDesignModification: new Form({
    enabled: true,
    enumId: 'FormMixDesignModification',
    icon: '',
    id: 190000,
    legacy: true,
    name: 'Mix Modification'
  }),
  FormOrderEntryAggregate: new Form({
    enabled: true,
    enumId: 'FormOrderEntryAggregate',
    icon: 'fa-pencil-square-o',
    id: 15501,
    legacy: true,
    longName: 'Classic Agg Order Entry',
    name: 'Classic Order Entry',
    availableInQuickMenu: true
  }),
  FormOrderEntryBlock: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormOrderEntryBlock',
    icon: 'fa-pencil-square-o',
    id: 18000,
    legacy: true,
    longName: 'Classic Block Order Entry',
    name: 'Classic Order Entry'
  }),
  FormOrderEntryConcrete: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormOrderEntryConcrete',
    icon: 'fa-pencil-square-o',
    id: 15000,
    legacy: true,
    longName: 'Concrete Order Entry Ex',
    name: 'Order Entry Ex'
  }),
  FormOrderEntryConcreteBasic: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormOrderEntryConcreteBasic',
    icon: 'fa-pencil-square-o',
    id: 236000,
    legacy: true,
    longName: 'Classic Concrete Order Entry',
    name: 'Classic Order Entry'
  }),
  FormOrderEntryRail: new Form({
    enabled: true,
    enumId: 'FormOrderEntryRail',
    icon: 'fa-pencil-square-o',
    id: 228000,
    legacy: true,
    name: 'Rail Order Entry'
  }),
  FormPlant: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormPlant',
    icon: 'fa-map-marker',
    id: 42000,
    legacy: true,
    name: 'Classic Plant Maintenance'
  }),
  FormPrelim: new Form({
    enabled: true,
    enumId: 'FormPrelim',
    icon: '',
    id: 71000,
    legacy: true,
    name: 'Prelim Input'
  }),
  FormPrelimPrint: new Form({
    enabled: true,
    enumId: 'FormPrelimPrint',
    icon: '',
    id: 72000,
    legacy: true,
    name: 'Prelim Print/Update'
  }),
  FormProduct: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormProduct',
    icon: 'fa-cogs',
    id: 11000,
    legacy: true,
    name: 'Classic Product Maintenance'
  }),
  FormPumper: new Form({
    availableInQuickMenu: false,
    enabled: true,
    enumId: 'FormPumper',
    icon: '',
    id: 63000,
    legacy: true,
    name: 'Classic Pumper Maintenance'
  }),
  FormQuote: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormQuote',
    icon: 'fa-usd',
    id: 5000,
    legacy: true,
    name: 'Classic Quote Maintenance'
  }),
  FormReports: new Form({
    availableInQuickMenu: true,
    alwaysShow: true,
    enabled: true,
    enumId: 'FormReports',
    icon: 'fa-file-text-o',
    id: 92000,
    legacy: true,
    name: 'Classic Reports'
  }),
  FormCommodityCodeRail: new Form({
    enabled: true,
    enumId: 'FormCommodityCodeRail',
    icon: '',
    id: 234000,
    legacy: true,
    name: 'Rail Commodity Code'
  }),
  FormScheduleRail: new Form({
    enabled: true,
    enumId: 'FormScheduleRail',
    icon: '',
    id: 226000,
    legacy: true,
    name: 'Rail Schedule'
  }),
  FormRawMaterialRanking: new Form({
    enabled: true,
    enumId: 'FormRawMaterialRanking',
    icon: '',
    id: 210000,
    legacy: true,
    name: 'Raw Material Ranking'
  }),
  FormSource: new Form({
    enabled: true,
    enumId: 'FormSource',
    icon: '',
    id: 240000,
    legacy: true,
    name: 'Source Maintenance'
  }),
  FormTicketAggregate: new Form({
    enabled: true,
    enumId: 'FormTicketAggregate',
    icon: '',
    id: 22000,
    legacy: true,
    name: 'Classic Ticketing'
  }),
  FormTicketAggregateQuick: new Form({
    enabled: true,
    enumId: 'FormTicketAggregateQuick',
    icon: '',
    id: 219000,
    legacy: true,
    name: 'Quick Ticket'
  }),
  FormTicketBlock: new Form({
    enabled: true,
    enumId: 'FormTicketBlock',
    icon: '',
    id: 25000,
    legacy: true,
    name: 'Ticketing'
  }),
  FormTicketConcrete: new Form({
    availableInQuickMenu: false,
    enabled: true,
    enumId: 'FormTicketConcrete',
    icon: 'fa-book',
    id: 26000,
    legacy: true,
    name: 'Classic Concrete Ticketing'
  }),
  FormTicketEdit: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormTicketEdit',
    icon: 'fa-eraser',
    id: 50000,
    legacy: true,
    name: 'Classic Edit Tickets'
  }),
  FormTicketEditBatch: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormTicketEditBatch',
    icon: 'fa-bold',
    id: 132000,
    legacy: true,
    name: 'Classic Batch Edit Tickets'
  }),
  FormTicketEditTimes: new Form({
    enabled: true,
    enumId: 'FormTicketEditTimes',
    icon: '',
    id: 50001,
    legacy: true,
    name: 'Edit Ticket Times'
  }),
  FormTicketInquiry: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormTicketInquiry',
    icon: 'fa-list-alt',
    id: 222000,
    legacy: true,
    name: 'Classic Ticket Inquiry'
  }),
  FormTicketsExport: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormTicketsExport',
    icon: 'fa-random',
    id: 111000,
    legacy: true,
    name: 'Export Ticket Data...'
  }),
  FormTicketsReceive: new Form({
    enabled: true,
    enumId: 'FormTicketsReceive',
    icon: '',
    id: 61000,
    legacy: true,
    name: 'Receive Tickets'
  }),
  FormTrailer: new Form({
    enabled: true,
    enumId: 'FormTrailer',
    icon: '',
    id: 40000,
    legacy: true,
    name: 'Trailer Maintenance'
  }),
  FormTruck: new Form({
    availableInQuickMenu: false,
    enabled: true,
    enumId: 'FormTruck',
    icon: 'fa-truck',
    id: 39000,
    legacy: true,
    name: 'Classic Truck Maintenance'
  }),
  FormTruckCompany: new Form({
    enabled: true,
    enumId: 'FormTruckCompany',
    icon: 'fa-truck',
    id: 41000,
    legacy: true,
    name: 'Truck Company Maintenance'
  }),
  FormTruckDemand: new Form({
    availableInQuickMenu: true,
    enabled: true,
    enumId: 'FormTruckDemand',
    icon: '',
    id: 49000,
    legacy: true,
    name: 'Truck Demand'
  }),
  FormTruckDemandManager: new Form({
    enabled: true,
    enumId: 'FormTruckDemandManager',
    icon: '',
    id: 238000,
    legacy: true,
    name: 'Truck Demand Manager'
  }),
  FormVendor: new Form({
    enabled: true,
    enumId: 'FormVendor',
    icon: '',
    id: 163000,
    legacy: true,
    name: 'Vendor Maintenance'
  }),
  ReportMarketSegments: new Form({
    enabled: true,
    enumId: 'ReportMarketSegments',
    icon: '',
    id: 247000,
    legacy: false,
    name: 'Market Segments'
  }),
  ReportMaterialDemand: new Form({
    enabled: true,
    enumId: 'ReportMaterialDemand',
    icon: '',
    id: 242000,
    legacy: false,
    name: 'Material Demand'
  }),
  ReportSalesQuantity: new Form({
    enabled: true,
    enumId: 'ReportSalesQuantity',
    icon: '',
    id: 244000,
    legacy: false,
    name: 'Sales Quantity'
  }),
  ReportShippedOrders: new Form({
    enabled: true,
    enumId: 'ReportShippedOrders',
    icon: '',
    id: 245000,
    legacy: false,
    name: 'Shipped Orders'
  }),
  ReportTruckDemand: new Form({
    enabled: true,
    enumId: 'ReportTruckDemand',
    icon: '',
    id: 246000,
    legacy: false,
    name: 'Truck Demand'
  }),
  ProductPrice: new Form({
    enabled: true,
    enumId: 'ProductPrice',
    icon: '',
    id: 145000,
    legacy: false,
    name: 'Product Price'
  }),
  CopyProduct: new Form({
    enabled: true,
    enumId: 'CopyProduct',
    icon: '',
    id: 23000,
    legacy: false,
    name: 'Copy Product'
  }),
  CopyOrder: new Form({
    enabled: true,
    enumId: 'CopyOrder',
    icon: '',
    id: 107000,
    legacy: false,
    name: 'Copy Order'
  })
};
