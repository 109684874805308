import { AppConfig } from '@app/app.config';
import { BehaviorSubject, Observable, Subject, merge, of } from 'rxjs';
import {
  CustomReportNotification,
  DISPATCHER_EVENTS,
  IDispatcher,
  IScaleUpdate,
  LoadUpdate,
  OrderUpdate,
  PlantUpdate,
  QuickLaunchFormUpdate,
  ScheduleStatusAveragesUpdate,
  TicketPrintReply,
  TicketUpdate,
  TruckSequenceUpdate,
  TruckUpdate,
  IForceLogoutMessage,
  LogoutTypeEnum,
  AccountingExportFailed,
  AccountingExportComplete,
  TicketModifiedNotification,
  LoadScheduleUpdateNotification
} from '@app/dispatcher/dispatch.model';
import { DispatchConnectionFlag } from '@app/dispatcher/dispatch-connection-flag.enum';
import {
  HUB_CONNECTION_STATES,
  HUB_CONNECTION_EVENTS,
  SignalRHubService
} from '@app/server/shared/signalr-hub.service';
import { Hub, HubConnectionEventArgs } from '@app/server/shared/hub.model';
import { Injectable, NgZone } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { OrderUpdateModel } from '@app/dispatcher/models/order-update.model';
import { Plant } from '@app/plants/shared/plant.model';
import { QuickLaunchSetting } from '@app/shared/models/quick-launch-setting.model';
import { RecordLock } from '@app/record-locks/shared/record-locks.model';
import { ScheduleLoad } from '@app/schedules/shared/schedule-load.model';
import { TicketUpdateModel } from '@app/dispatcher/models/ticket-update.model';
import { TruckSequence } from '@app/truck-tracking/trucks-in-yard/truck-sequence.model';
import { TruckUpdateModel } from '@app/dispatcher/models/truck-update.model';
import { filter, first, mergeMap } from 'rxjs/operators';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

const lgSfx = '\t(dispatch.service)';

const HUB_CALLS = {
  LOCK_RECORD: 'LockRecord',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  SUBSCRIBE_TO_SCALE: 'SubsribeToScale',
  UNSUBSCRIBE_FROM_SCALE: 'UnsubscribeFromScale',
  PRINT_TICKET: 'PrintTicket',
  PING: 'Ping',
  SET_CONNECTION_ACC_FLAG: 'SetConnectionAccessFlag',
  REMOVE_CONNECTION_ACC_FLAG: 'RemoveConnectionAccessFlag'
};

export const enum SCALE_STATUS {
  Unknown = 'Unknown',
  Ok = 'Ok',
  Motion = 'Motion',
  Invalid = 'Invalid'
}

/**
 * To use the DispatchService, you need to declare the `DispatchModule` as a
 * dependency. Then inject the BraodcastService where you need it. When you
 * are ready to use it call `connect()` to initiate the connection.
 * `connect()` can safely be called multiple times and will only connect to
 * the dispatch hub once. After calling `connect()`, subscribe to the events
 * that you want to listen to changes for.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class DispatchService implements IDispatcher {
  // Public observables
  public AccountingExportComplete: Observable<AccountingExportComplete>;
  public AccountingExportFailed: Observable<AccountingExportFailed>;
  public customReportEvents: Observable<CustomReportNotification>;
  public dispatchForceLogout: Observable<IForceLogoutMessage>;
  public loadEvents: Observable<LoadUpdate>;
  public loadScheduleNotif$: Observable<LoadScheduleUpdateNotification>;
  public orderEvents: Observable<OrderUpdate>;
  public plantEvents: Observable<PlantUpdate>;
  public quickLaunchEvents: Observable<QuickLaunchFormUpdate>;
  public recordLockEvents: Observable<RecordLock>;
  public recordLockErrorEvents: Observable<any>;
  public scaleUpdateEvents: Observable<IScaleUpdate>;
  public scheduleStatusAverageEvents: Observable<ScheduleStatusAveragesUpdate>;
  public statusEvents: Observable<string>;
  public ticketEvents: Observable<TicketUpdate>;
  public ticketModifiedEvents$: Observable<TicketModifiedNotification>;
  public ticketPrintEvents: Observable<TicketPrintReply>;
  public truckEvents: Observable<TruckUpdate>;
  public truckSequenceEvents: Observable<TruckSequenceUpdate>;

  // Subjects
  public dispatchForceLogoutSub: Subject<IForceLogoutMessage>;
  private accountingExportCompleteSub: Subject<AccountingExportComplete>;
  private accountingExportFailedSub: Subject<AccountingExportFailed>;
  private customReportEventsSub: Subject<CustomReportNotification>;
  private loadEventsSub: Subject<LoadUpdate>;
  private loadScheduleUpdateNotifSub: Subject<LoadScheduleUpdateNotification>;
  private orderEventsSub: Subject<OrderUpdate>;
  private plantEventsSub: Subject<PlantUpdate>;
  private quickLaunchEventsSub: Subject<QuickLaunchFormUpdate>;
  private recordLockSub: Subject<RecordLock>;
  private recordLockErrorSub: Subject<any>;
  private scaleUpdateEventsSub: Subject<IScaleUpdate>;
  private scheduleStatusAverageEventsSub: Subject<ScheduleStatusAveragesUpdate>;
  private statusChangedSub: BehaviorSubject<DISPATCHER_EVENTS>;
  private ticketEventsSub: Subject<TicketUpdate>;
  private ticketModifiedNotificationSub: Subject<TicketModifiedNotification>;
  private ticketPrintSub: Subject<TicketPrintReply>;
  private truckEventsSub: Subject<TruckUpdate>;
  private truckSequenceEventsSub: Subject<TruckSequenceUpdate>;

  // Other
  private connected = false;
  private hub: Hub;
  private HUB_NAME = 'DispatchHub';
  // private hubPingInterval: NodeJS.Timeout;

  constructor(
    private appConfig: AppConfig,
    private log: Logger,
    private loginService: LoginStatusService,
    private ngZone: NgZone,
    private signalRHubProxy: SignalRHubService
  ) {
    this.accountingExportCompleteSub = new Subject<AccountingExportComplete>();
    this.AccountingExportComplete = this.accountingExportCompleteSub.asObservable();
    this.accountingExportFailedSub = new Subject<AccountingExportFailed>();
    this.AccountingExportFailed = this.accountingExportFailedSub.asObservable();

    this.customReportEventsSub = new Subject<CustomReportNotification>();
    this.customReportEvents = this.customReportEventsSub.asObservable();

    this.dispatchForceLogoutSub = new Subject<IForceLogoutMessage>();
    this.dispatchForceLogout = this.dispatchForceLogoutSub.asObservable();

    this.loadEventsSub = new Subject<LoadUpdate>();
    this.loadEvents = this.loadEventsSub.asObservable();

    this.loadScheduleUpdateNotifSub = new Subject<LoadScheduleUpdateNotification>();
    this.loadScheduleNotif$ = this.loadScheduleUpdateNotifSub.asObservable();

    this.orderEventsSub = new Subject<OrderUpdate>();
    this.orderEvents = this.orderEventsSub.asObservable();

    this.quickLaunchEventsSub = new Subject<QuickLaunchFormUpdate>();
    this.quickLaunchEvents = this.quickLaunchEventsSub.asObservable();

    this.recordLockSub = new Subject<RecordLock>();
    this.recordLockEvents = this.recordLockSub.asObservable();

    this.recordLockErrorSub = new Subject<RecordLock>();
    this.recordLockErrorEvents = this.recordLockErrorSub.asObservable();

    this.scaleUpdateEventsSub = new Subject<IScaleUpdate>();
    this.scaleUpdateEvents = this.scaleUpdateEventsSub.asObservable();

    this.scheduleStatusAverageEventsSub = new Subject<ScheduleStatusAveragesUpdate>();
    this.scheduleStatusAverageEvents = this.scheduleStatusAverageEventsSub.asObservable();

    this.ticketEventsSub = new Subject<TicketUpdate>();
    this.ticketEvents = this.ticketEventsSub.asObservable();

    this.ticketModifiedNotificationSub = new Subject<TicketModifiedNotification>();
    this.ticketModifiedEvents$ = this.ticketModifiedNotificationSub.asObservable();

    this.ticketPrintSub = new Subject<TicketPrintReply>();
    this.ticketPrintEvents = this.ticketPrintSub.asObservable();

    this.truckEventsSub = new Subject<TruckUpdate>();
    this.truckEvents = this.truckEventsSub.asObservable();

    this.truckSequenceEventsSub = new Subject<TruckSequenceUpdate>();
    this.truckSequenceEvents = this.truckSequenceEventsSub.asObservable();

    this.plantEventsSub = new Subject<PlantUpdate>();
    this.plantEvents = this.plantEventsSub.asObservable();

    this.statusChangedSub = new BehaviorSubject<DISPATCHER_EVENTS>(DISPATCHER_EVENTS.DISCONNECTED);
    this.statusEvents = this.statusChangedSub.asObservable();

    this.loginService.loginStatus.subscribe((loginStatus: LoginStatusModel) =>
      this.onLoginStatusChanged(loginStatus)
    );
  }

  public get SignalRDispatchHub(): Hub {
    return this.hub;
  }

  public connect(): Observable<any> {
    this.statusChangedSub.next(DISPATCHER_EVENTS.CONNECTING);
    const dispatchUri = this.appConfig.getDispatchUri();
    this.log.info(`Connecting to Dispatch HUB at URI (${dispatchUri}) ${lgSfx}`);

    if (this.connected === true) {
      this.log.log(`Attempted to connect to Dispatch after already connected. ${lgSfx}`);
      return of(true);
    }

    if (!this.hub) {
      this.hub = this.signalRHubProxy.createHub(this.HUB_NAME, {
        connectionPath: dispatchUri,
        loggingEnabled: this.appConfig.getEnableSignalRLogging()
      });

      this.addWatchEventsToHub();
      this.watchConnectionEvents();
    }

    return this.hub.start();
  }

  public getConnectionId(): string {
    if (typeof this.hub !== 'undefined') {
      return this.hub.getConnectionId();
    } else {
      return '';
    }
  }

  public getConnectionIdAsync(): Observable<string> {
    if (!hasValue(this.hub?.connection?.socket) || !hasValue(this.hub?.getConnectionId())) {
      this.log.log(
        'Connection Id requested but Hub did not have an active connection. Attempting connection..'
      );
      return this.connect()
        .pipe(mergeMap((result: any) => result))
        .pipe(
          mergeMap((connectionResult: any) => {
            this.log.log('Hub connection request finished with result', connectionResult);
            return of(this.hub.getConnectionId());
          })
        );
    } else {
      return of(this.hub.getConnectionId());
    }
  }

  public getStatus(): DISPATCHER_EVENTS {
    return this.statusChangedSub.value;
  }

  public lockRecord(table: string, id: number): Observable<any> {
    this.log.log(`Locking record id ${id} on table ${table} ${lgSfx}`);

    if (typeof this.hub !== 'undefined') {
      return this.hub.send(HUB_CALLS.LOCK_RECORD, table, id);
    } else {
      return of(undefined);
    }
  }

  public logoff(): void {
    this.log.log(`Logging off dispatcher. ${lgSfx}`);
    if (typeof this.hub !== 'undefined') {
      this.hub.send(HUB_CALLS.LOGOUT);
    }
  }

  public subscribeToScale(id: string): void {
    this.log.log(`Subscribing to scale(${id}) ${lgSfx}`);
    this.hub?.send(HUB_CALLS.SUBSCRIBE_TO_SCALE, id);
  }

  public unSubscribeToScale(id: string): void {
    this.log.log(`Unsubscribing to scale(${id}) ${lgSfx}`);
    this.hub?.send(HUB_CALLS.UNSUBSCRIBE_FROM_SCALE, id);
  }

  public setConnectionStatus(val: DispatchConnectionFlag): void {
    this.log.log(`Updating LSS Access status (${val}) ${lgSfx}`);
    this.hub?.send(HUB_CALLS.SET_CONNECTION_ACC_FLAG, val);
  }

  public removeConnectionStatus(val: DispatchConnectionFlag): void {
    this.log.log(`Updating LSS Access status (${val}) ${lgSfx}`);
    this.hub?.send(HUB_CALLS.REMOVE_CONNECTION_ACC_FLAG, val);
  }

  public watchAllDispatchEvents(): Observable<any> {
    return merge(
      this.loadEvents,
      this.orderEvents,
      this.scaleUpdateEvents,
      this.scheduleStatusAverageEvents,
      this.ticketEvents,
      this.truckEvents,
      this.plantEvents,
      this.quickLaunchEvents
    );
  }

  private addWatchEventsToHub(): void {
    this.log.log(`Watching for Dispatch events. ${lgSfx}`);
    this.hub.on('ping', (ping: string) => this.onPing(ping));
    this.hub.on('loadUpdate_v1', (update: string) => this.onLoadUpdate_v1(JSON.parse(update)));
    this.hub.on('orderUpdate_v1', (update: string) => this.onOrderUpdate_v1(JSON.parse(update)));
    this.hub.on('updateScale_v1', (update: string) => this.onScaleUpdate(JSON.parse(update)));
    this.hub.on('scheduleStatusAverageUpdate_v1', (update: string) =>
      this.onScheduleStatusAverageUpdate_v1(JSON.parse(update))
    );
    this.hub.on('ticketPrintReply_v1', (update: string) =>
      this.onTicketPrintReply_v1(JSON.parse(update))
    );
    this.hub.on('ticketUpdate_v1', (update: string) => this.onTicketUpdate_v1(JSON.parse(update)));
    this.hub.on('truckUpdate_v1', (update: string) => this.onTruckUpdate_v1(JSON.parse(update)));
    this.hub.on('truckSequenceUpdate_v1', (update: string) =>
      this.onTruckSequenceUpdate_v1(JSON.parse(update))
    );
    this.hub.on('plantUpdate_v1', (update: string) => this.onPlantUpdate_v1(JSON.parse(update)));
    this.hub.on('quickLaunchFormUpdate_v1', (update: string) =>
      this.onQuickLaunchFormUpdate_v1(JSON.parse(update))
    );
    this.hub.on('reportComplete_v1', (notification: string) =>
      this.onReportComplete_v1(JSON.parse(notification))
    );
    this.hub.on('accountingExportComplete_v1', (notification: string) =>
      this.accountingExportComplete_v1(JSON.parse(notification))
    );
    this.hub.on('accountingExportFailed_v1', (notification: string) =>
      this.accountingExportFailed_v1(JSON.parse(notification))
    );
    this.hub.on('logoutEvent_v1', (update: string) => this.onLogoutEvent_v1(update));
    this.hub.on('ticketModifiedNotification_v1', (notification: string) =>
      this.onTicketModifiedNotification_v1(JSON.parse(notification))
    );
    this.hub.on('loadScheduleUpdatedNotification_v1', (update: string) =>
      this.onLoadScheduleUpdatedNotification_v1(JSON.parse(update))
    );
  }

  private prepareLoginRequest(
    username: string,
    authToken: string,
    authTokenExpirationDate: string,
    database: string
  ): void {
    this.log.log(
      `When connection ready, will log in user ${username} to database ${database}. ${lgSfx}`
    );
    if (this.hub.connectionStatus.isConnected()) {
      this.sendLoginRequest(username, authToken, authTokenExpirationDate, database);
    } else {
      this.statusEvents
        .pipe(
          filter((data) => {
            this.log.log(`Received status event ${data} ${lgSfx}`);
            return data === DISPATCHER_EVENTS.CONNECTED;
          }),
          first()
        )
        .subscribe(() => {
          setTimeout(() => {
            this.log.log(`Logging in user ${username} to database ${database}. ${lgSfx}`);
            this.sendLoginRequest(username, authToken, authTokenExpirationDate, database);
          });
        });
    }
  }

  private sendLoginRequest(
    username: string,
    authToken: string,
    authTokenExpirationDate: string,
    database: string
  ): void {
    this.hub.send(HUB_CALLS.LOGIN, username, authToken, authTokenExpirationDate, database);
  }

  // ! Currently pinging from sever as well, might be able to drop these
  private setHubPingInterval(): void {
    // this.hubPingInterval = setInterval(() => {
    //   this.hub.send(HUB_CALLS.PING)
    // }, 30000)
  }

  private clearHubPingInterval(): void {
    // clearInterval(this.hubPingInterval);
  }

  // region Hub Events

  private onLoginStatusChanged(loginStatus: LoginStatusModel): void {
    this.log.log(
      `Received login status change to logged in (${loginStatus.loggedIn})
       for user (${loginStatus.username}) on database (${loginStatus.database}). ${lgSfx}`
    );
    if (loginStatus.loggedIn === true) {
      this.prepareLoginRequest(
        loginStatus.username,
        loginStatus.authToken,
        loginStatus.authTokenExpirationDate,
        loginStatus.database
      );
    } else {
      this.logoff();
    }
  }

  private onLoadUpdate_v1(update: LoadUpdate): void {
    this.ngZone.run(() => {
      this.log.log(`Received new load update from dispatch. ${lgSfx}`, update);
      update.data = Object.assign(new ScheduleLoad(), update.data);
      this.loadEventsSub.next(update);
    });
  }

  private onLogoutEvent_v1(data: string): void {
    this.log.log('user log out event received from dispatcher:', data);

    const msgObj = {} as IForceLogoutMessage;
    msgObj.logoutType = LogoutTypeEnum.none;
    msgObj.identifier = undefined;
    this.dispatchForceLogoutSub.next(msgObj);
  }

  private onPing(ping: string): void {
    this.ngZone.run(() => {
      this.log.log(`Received ping from dispatch. ${lgSfx}`, ping);
    });
  }

  private onOrderUpdate_v1(update: OrderUpdate): void {
    this.ngZone.run(() => {
      this.log.log(`Received new order update from dispatch. ${lgSfx}`, update);
      update.data = Object.assign(new OrderUpdateModel(), update.data);
      this.orderEventsSub.next(update);
    });
  }

  private onPlantUpdate_v1(update: PlantUpdate): void {
    this.ngZone.run(() => {
      this.log.log(`Received new plant update from dispatcher. ${lgSfx}`, update);
      update.data = Object.assign(new Plant(), update.data);
      this.plantEventsSub.next(update);
    });
  }

  private onReportComplete_v1(notification: CustomReportNotification): void {
    this.ngZone.run(() => {
      this.log.log(
        `Received new custom report notification from dispatcher. ${lgSfx}`,
        notification
      );
      this.customReportEventsSub.next(notification);
    });
  }

  private accountingExportComplete_v1(notification: AccountingExportComplete): void {
    this.ngZone.run(() => {
      this.log.log(
        `Received accounting export complete notification from dispatcher. ${lgSfx}`,
        notification
      );
      this.accountingExportCompleteSub.next(notification);
    });
  }

  private accountingExportFailed_v1(notification: AccountingExportFailed): void {
    this.ngZone.run(() => {
      this.log.log(
        `Received accounting export failed notification from dispatcher. ${lgSfx}`,
        notification
      );
      this.accountingExportFailedSub.next(notification);
    });
  }

  private onQuickLaunchFormUpdate_v1(update: QuickLaunchFormUpdate): void {
    this.ngZone.run(() => {
      this.log.log(`Received new quick launch form update from dispatcher. ${lgSfx}`, update);
      update.data = Object.assign(new QuickLaunchSetting(), update.data);
      this.quickLaunchEventsSub.next(update);
    });
  }

  private onScaleUpdate(update: IScaleUpdate): void {
    this.ngZone.run(() => {
      const time = new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
      this.log.log(`${time} Received new scale update from dispatcher. ${lgSfx}`, update);
      this.scaleUpdateEventsSub.next(update);
    });
  }

  private onScheduleStatusAverageUpdate_v1(update: ScheduleStatusAveragesUpdate): void {
    this.ngZone.run(() => {
      this.log.log(`Received new averages update from dispatcher. ${lgSfx}`, update);
      this.scheduleStatusAverageEventsSub.next(update);
    });
  }

  private onTicketPrintReply_v1(update: TicketPrintReply): void {
    this.ngZone.run(() => {
      this.log.log(`Received new ticketPrintReply from dispatcher. ${lgSfx}`, update);
      this.ticketPrintSub.next(update);
    });
  }

  private onTicketUpdate_v1(update: TicketUpdate): void {
    this.ngZone.run(() => {
      this.log.log(`Received new ticket update from dispatcher. ${lgSfx}`, update);
      update.data = Object.assign(new TicketUpdateModel(), update.data);
      this.ticketEventsSub.next(update);
    });
  }

  private onTruckUpdate_v1(update: TruckUpdate): void {
    this.ngZone.run(() => {
      this.log.log(`Received new truck update from dispatcher. ${lgSfx}`, update);
      update.data = Object.assign(new TruckUpdateModel(), update.data);
      this.truckEventsSub.next(update);
    });
  }

  private onTruckSequenceUpdate_v1(update: TruckSequenceUpdate): void {
    this.ngZone.run(() => {
      this.log.log(`Received new truck sequence update from dispatcher. ${lgSfx}`, update);

      update.data.trucks = update.data.trucks.map((x) => Object.assign(new TruckSequence(), x));
      update.data.plantId = parseInt(update.data.plantId.toString(), 10);

      this.truckSequenceEventsSub.next(update);
    });
  }

  private onTicketModifiedNotification_v1(notification: TicketModifiedNotification) {
    this.ngZone.run(() => {
      this.log.log(`Received ticket modified notification. ${lgSfx}`, notification);
      this.ticketModifiedNotificationSub.next(notification);
    });
  }

  private onLoadScheduleUpdatedNotification_v1(notif: LoadScheduleUpdateNotification) {
    this.ngZone.run(() => {
      this.log.log(`Received ticket modified notification. ${lgSfx}`, notif);
      this.loadScheduleUpdateNotifSub.next(notif);
    });
  }

  // endregion Hub Events

  private watchConnectionEvents(): void {
    this.hub.connectionEvents
      .pipe(filter((args: HubConnectionEventArgs) => args.event === HUB_CONNECTION_EVENTS.change))
      .subscribe((args: HubConnectionEventArgs) => {
        if (args.hubUrl) {
          this.ngZone.run(() => {
            const newState = HUB_CONNECTION_STATES[args.state.newState];
            switch (newState) {
              case 'connecting':
                this.connected = true;
                this.log.log(`Connecting to Dispatch Service... ${args.connectionId} ${lgSfx}`);
                this.statusChangedSub.next(DISPATCHER_EVENTS.CONNECTING);
                break;
              case 'connected':
                this.connected = true;
                this.log.log(`Dispatch Service connected. ${args.connectionId} ${lgSfx}`);
                const loginStatus = this.loginService.getCurrent();
                if (loginStatus.loggedIn) {
                  this.prepareLoginRequest(
                    loginStatus.username,
                    loginStatus.authToken,
                    loginStatus.authTokenExpirationDate,
                    loginStatus.database
                  );
                }
                this.setHubPingInterval();
                this.statusChangedSub.next(DISPATCHER_EVENTS.CONNECTED);
                break;
              case 'reconnecting':
                this.connected = true;
                this.log.log(`Reconnecting to Dispatch Service... ${args.connectionId} ${lgSfx}`);
                this.statusChangedSub.next(DISPATCHER_EVENTS.CONNECTING);
                break;
              case 'disconnected':
                this.connected = false;
                this.log.log(`Dispatch Service disconnected ${args.connectionId} ${lgSfx}`);
                this.clearHubPingInterval();
                this.statusChangedSub.next(DISPATCHER_EVENTS.DISCONNECTED);
                break;
              default:
                this.log.error(
                  `Unrecognized hub connection state: ${args.state.newState} ${args.connectionId} ${lgSfx}`
                );
            }
          });
        }
      });
  }
}
