export class HubOption {
  loggingEnabled: boolean;
  connectionPath: string;

  constructor() {
    // Enable hub logging events
    this.loggingEnabled = false;

    // Connection path to use
    this.connectionPath = '.';
  }
}
