import { Moment } from 'moment';
import {
  convertIso8601ToDate,
  convertDateToIsoString
} from '@app/shared/utilities/date-helpers.utility';

export class CashTransaction {
  object: string;
  orderId: number;
  cashNotes: string;
  checkApprovalCode: string;
  checkNumber: string;
  cashPaymentAmount: number;
  cashPaymentFlag: boolean;
  checkPaymentAmount: number;
  checkPaymentFlag: boolean;
  creditCardApprovalCode: string;
  creditCardBillingAddress: string;
  creditCardNumber: string;
  creditCardPaymentAmount: number;
  creditCardPaymentFlag: boolean;
  creditCardSecurityValue: string;

  private _creditCardExpirationDate: Moment;
  get creditCardExpirationDate(): Moment {
    return this._creditCardExpirationDate;
  }
  set creditCardExpirationDate(val) {
    this._creditCardExpirationDate = convertIso8601ToDate(val);
  }

  constructor() {
    this.object = 'cashTransaction';
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}
