import { Injectable } from '@angular/core';
import { ILegacy } from '@app/legacy/legacy.interface';
import { LegacyIntercomService } from '@app/legacy/legacy-intercom.service';
import { LegacySkipperService } from '@app/legacy/legacy-skipper.service';

@Injectable({
  providedIn: 'root'
})
export class LegacyService {
  public hideLegacyForms = false;
  public service: ILegacy;

  constructor(
    private legacyIntercomService: LegacyIntercomService,
    private legacySkipperService: LegacySkipperService
  ) {
    this.useLegacyIntercom();
  }

  public isConnectingToClassic(): boolean {
    return this.service instanceof LegacyIntercomService;
  }

  public useLegacyIntercom(): void {
    this.service = this.legacyIntercomService;
    this.hideLegacyForms = false;
  }

  public skipLegacyIntercom(): void {
    this.legacyIntercomService.disconnect(false, true);
    this.service = this.legacySkipperService;
    this.hideLegacyForms = true;
  }
}
