import { IModal } from '@app/shared/dialogs/modal.interface';
import { IModalButton } from '@app/shared/dialogs/modal-button.interface';
import { Injectable } from '@angular/core';
import { IStatusItem } from '@app/shared/dialogs/status-item.interface';
import { Modal } from '@app/shared/dialogs/modal.model';
import { ModalButton } from '@app/shared/dialogs/modal-button.model';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StatusModalComponent } from '@app/shared/dialogs/status-modal.component';
import { StatusItem } from '@app/shared/dialogs/status-item.model';

@Injectable({ providedIn: 'root' })
export class StatusModalService {
  constructor(public matDialog: MatDialog) {}

  public getNewButton(action: any, title: string): IModalButton {
    return new ModalButton(action, title);
  }

  public getNewModal(title: string): IModal {
    return new Modal(title);
  }

  public getNewStatusItem(title: string, progress: number): IStatusItem {
    return new StatusItem(title, progress);
  }

  public open(modal: IModal): MatDialogRef<StatusModalComponent> {
    const data = modal.getDataObject();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = data;

    modal.modalInstance = this.matDialog.open(StatusModalComponent, dialogConfig);
    modal.isOpen = true;

    return modal.modalInstance;
  }
}
