import { AppConfig } from '@app/app.config';
import { HttpService } from '@app/security/shared/http.service';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { Observable, of } from 'rxjs';
import { OrderStatus } from '@app/orders/models/order-status.model';

import {
  faArrowCircleLeft,
  faBan,
  faFlagCheckered,
  faHandPaper,
  faPhoneVolume,
  faShippingTimed,
  faShippingFast,
  faTrashAlt,
  faTruckLoading
} from '@fortawesome/pro-regular-svg-icons';
import { faStopCircle } from '@fortawesome/pro-solid-svg-icons';
import { map } from 'rxjs/operators';

const ORDER_STATUS_API_ENDPOINT = '/api/OrderStatus';
const lgSfx = '\t(order-status.service)';

export enum ORDER_STATUS {
  DELETED = 0,
  TO_BE_SHIPPED = 1,
  FOB = 2,
  WILL_CALL = 3,
  ON_HOLD = 4,
  COMPLETE = 5,
  SHIPPING = 6,
  STOP_SHIPPING = 7,
  BACK_ORDER = 8,
  CANCELLED = 9
}

export const SYMBOLS = {
  B: { id: 8, symbol: 'B', description: 'Back Order', icon: faArrowCircleLeft },
  C: { id: 5, symbol: 'C', description: 'Complete', icon: faFlagCheckered },
  D: { id: 0, symbol: 'D', description: 'Deleted', icon: faTrashAlt },
  F: { id: 2, symbol: 'F', description: 'FOB', icon: faTruckLoading },
  H: { id: 4, symbol: 'H', description: 'On Hold', icon: faStopCircle },
  M: { id: 9, symbol: 'M', description: 'Cancelled', icon: faBan },
  P: { id: 6, symbol: 'P', description: 'Shipping', icon: faShippingFast },
  S: { id: 1, symbol: 'S', description: 'To Be Shipped', icon: faShippingTimed },
  W: { id: 3, symbol: 'W', description: 'Will Call', icon: faPhoneVolume },
  X: { id: 7, symbol: 'X', description: 'Stop Shipping', icon: faHandPaper }
};

@Injectable({ providedIn: 'root' })
export class OrderStatusService {
  public STATUSES = {
    backOrder: 'B',
    cancelled: 'M',
    complete: 'C',
    deleted: 'D',
    fob: 'F',
    onHold: 'H',
    shipping: 'P',
    stopShipping: 'X',
    toBeShipped: 'S',
    willCall: 'W'
  };

  private orderStatuses: Array<OrderStatus>;

  constructor(private config: AppConfig, private http: HttpService, private log: Logger) {}

  public getOrderStatuses(): Observable<Array<OrderStatus>> {
    const endpoint = this.config.getServerUri() + ORDER_STATUS_API_ENDPOINT;

    if (this.orderStatuses && this.orderStatuses.length > 0) {
      return of(this.orderStatuses);
    } else {
      this.log.info(`loading order statuses from the server ${lgSfx}`);

      return this.http.getAny(endpoint).pipe(map((s) => (this.orderStatuses = s)));
    }
  }

  public getSymbolFromId(id: number): string {
    for (const prop in SYMBOLS) {
      if (SYMBOLS[prop].id === id) {
        return SYMBOLS[prop].symbol;
      }
    }
  }

  public resolve(): Observable<OrderStatus[]> {
    return this.getOrderStatuses();
  }
}
