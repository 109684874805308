import { IStatusItem } from '@app/shared/dialogs/status-item.interface';

export class StatusItem implements IStatusItem {
  public progress: number;
  public status: string;
  public title: string;

  constructor(title?: string, progress?: number) {
    this.progress = progress;
    this.status = 'waiting'; // active, error, success, waiting
    this.title = title;
  }

  public updateProgress(progress: number): void {
    if (progress === -1) {
      this.status = 'error';
    } else if (progress > 0 && progress < 100) {
      this.status = 'active';
    } else if (progress === 100) {
      this.status = 'success';
    } else {
      progress = 0;
      this.status = 'waiting';
    }

    this.progress = progress;
  }
}
