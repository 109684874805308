export enum FORM_PERMISSIONS {
  None = 1,
  ReadOnlyNoPrices = 2,
  ReadOnlyWithPrices = 3,
  FullNoPrices = 4,
  FullWithPrices = 5,
  FullWithReadOnlyPrices = 6
}

export enum FORM_PERMISSIONS_SYMBOL {
  N = 1,
  R = 2,
  RP = 3,
  F = 4,
  FP = 5,
  FR = 6
}

export interface IFormPermission {
  formId: number;
  permission: number;

  userCanView(): boolean;
}

export class FormPermission implements IFormPermission {
  public formId: number;
  public permission: number;

  constructor(formId: number, permissionId: number) {
    this.formId = formId;
    this.permission = permissionId;
  }

  public userCanView(): boolean {
    return this.permission > FORM_PERMISSIONS.None;
  }
}
