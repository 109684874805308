import { AggregatePit } from '@app/plants/shared/aggregate-pit.model';
import { AggregateQuote } from '@app/quotes/models/aggregate-quote.model';
import { BlockQuote } from '@app/quotes/models/block-quote.model';
import { CashTransaction } from '@app/orders/cash-transaction/cash-transaction.model';
import { ConcretePlant } from '@app/plants/shared/concrete-plant.model';
import { ConcreteQuote } from '@app/quotes/models/concrete-quote.model';
import { Driver } from '@app/driver/shared/driver.model';
import { Employee } from '@app/employees/shared/employee.model';
import { FormulaProduct } from '@app/products/models/formula-product.model';
import { Job } from '@app/jobs/models/job.model';
import { Order } from '@app/orders/models/order.model';
import { Plant } from '@app/plants/shared/plant.model';
import { ProductEntryViewModel } from '@app/products/models/product-entry.model';
import { Schedule } from '@app/schedules/shared/schedule.model';
import { ScheduleForm } from '@app/schedules/shared/schedule-form.model';
import { ServerError } from '@app/shared/models/server-error.model';
import { Ticket } from '@app/ticketing/models/ticket.model';
import { TicketProduct } from '@app/products/models/ticket-product.model';
import { Truck } from '@app/trucks/shared/truck.model';
import { UnitOfMeasure } from '@app/products/models/unit-of-measure.model';

export const handleOrderForm = (data) => {
  for (const property in data) {
    if (data.hasOwnProperty(property)) {
      if (typeof data[property] === 'object') {
        const propertyObject = data[property];
        const objectType = data[property].object;

        if (objectType === 'job') {
          data[property] = Object.assign(new Job(), propertyObject);
        } else if (objectType === 'order') {
          data[property] = Object.assign(new Order(), propertyObject);
        } else if (objectType === 'cashTransaction') {
          data[property] = Object.assign(new CashTransaction(), propertyObject);
        } else if (propertyObject.length > 0) {
          propertyObject.map((sf: ScheduleForm) => {
            data[property][propertyObject.indexOf(sf)]['schedule'] = Object.assign(
              new Schedule(),
              sf['schedule']
            );
            data[property][propertyObject.indexOf(sf)]['primaryProduct'] = Object.assign(
              new ProductEntryViewModel(),
              sf['primaryProduct']
            );
            data[property][propertyObject.indexOf(sf)]['primaryProduct'][
              'unitOfMeasure'
            ] = Object.assign(new UnitOfMeasure(), sf['primaryProduct']['unitOfMeasure']);

            const extraProducts = data[property][propertyObject.indexOf(sf)]['extraProducts'];

            extraProducts.map((ep: ProductEntryViewModel) => {
              data[property][propertyObject.indexOf(sf)]['extraProducts'][
                extraProducts.indexOf(ep)
              ]['unitOfMeasure'] = Object.assign(new UnitOfMeasure(), ep['unitOfMeasure']);
              data[property][propertyObject.indexOf(sf)]['extraProducts'][
                extraProducts.indexOf(ep)
              ] = Object.assign(new ProductEntryViewModel(), ep);
            });

            data[property][propertyObject.indexOf(sf)] = Object.assign(new ScheduleForm(), sf);
          });
        }
      }
    }
  }

  return data;
};

export const handleEmployeeForm = (data) => {
  for (const property in data) {
    if (data.hasOwnProperty(property)) {
      if (typeof data[property] === 'object') {
        const propertyObject = data[property];
        const objectType = data[property].object;

        if (objectType === 'employee') {
          data[property] = Object.assign(new Employee(), propertyObject);
        } else if (objectType === 'driver') {
          data[property] = Object.assign(new Driver(), propertyObject);
        }
      }
    }
  }

  return data;
};

export const handlePlantForm = (data) => {
  for (const property in data) {
    if (data.hasOwnProperty(property)) {
      if (typeof data[property] === 'object') {
        const propertyObject = data[property];
        const objectType = data[property]?.object;
        if (objectType === 'plant') {
          data[property] = Object.assign(new Plant(), propertyObject);
        } else if (objectType === 'concretePlant') {
          data[property] = Object.assign(new ConcretePlant(), propertyObject);
        } else if (objectType === 'aggregatePit') {
          data[property] = Object.assign(new AggregatePit(), propertyObject);
        }
      }
    }
  }

  return data;
};

export const handleQuote = (data) => {
  for (const property in data) {
    if (data.hasOwnProperty(property)) {
      if (typeof data[property] === 'object' && data[property] !== null) {
        const propertyObject = data[property];

        if (propertyObject && propertyObject.object === 'concreteQuote') {
          data[property] = Object.assign(new ConcreteQuote(), propertyObject);
        }
        if (propertyObject && propertyObject.object === 'aggregateQuote') {
          data[property] = Object.assign(new AggregateQuote(), propertyObject);
        }
        if (propertyObject && propertyObject.object === 'blockQuote') {
          data[property] = Object.assign(new BlockQuote(), propertyObject);
        }
      }
    }
  }

  return data;
};

export const handleProduct = (data) => {
  for (const property in data) {
    if (data.hasOwnProperty(property)) {
      if (typeof data[property] === 'object' && data[property] !== null) {
        const propertyObject = data[property];

        if (propertyObject && propertyObject.object === 'formulaProduct') {
          data[property] = Object.assign(new FormulaProduct(), propertyObject);
        }
      }
    }
  }

  return data;
};

export const handleTicketUpdate = (data) => {
  for (const property in data) {
    if (
      data.hasOwnProperty(property) &&
      typeof data[property] === 'object' &&
      data[property] !== null
    ) {
      const propertyObject = data[property];
      if (data[property].object === 'ticket') {
        data[property] = Object.assign(new Ticket(), propertyObject);
      } else if (propertyObject.length) {
        propertyObject.map((ticketProduct: TicketProduct, index: number) => {
          data[property][index] = Object.assign(new TicketProduct(), ticketProduct);
        });
      }
    }
  }
};

export const handleTrucksInYard = (data) => {
  for (const property in data) {
    if (data.hasOwnProperty(property)) {
      if (typeof data[property] === 'object') {
        const propertyObject = data['trucks'];
        if (propertyObject.length > 0) {
          propertyObject.map((t: Truck, index) => {
            data[property][index] = Object.assign(new Truck(), t);
          });
        }
      }
    }
  }

  return data;
};

export const checkResponseAndReturnSingle = (object, objectResp, rethrowErrors: boolean) => {
  if (objectResp.error) {
    console.warn('The server responded with a response error: ' + objectResp.error.message);

    if (rethrowErrors === true) {
      throw new ServerError(objectResp.error);
    } else {
      return undefined;
    }
  }
  if (objectResp && objectResp.count > 0) {
    const data = Object.assign(new object(), objectResp.data[0]);
    if (data.object === 'orderForm') {
      handleOrderForm(data);
    } else if (data.object === 'employeeForm') {
      handleEmployeeForm(data);
    } else if (data.object === 'ticketUpdate') {
      handleTicketUpdate(data);
    } else if (data.object === 'quote') {
      handleQuote(data);
    } else if (data.object === 'product') {
      handleProduct(data);
    } else if (data.object === 'plantForm') {
      handlePlantForm(data);
    }

    return data;
  } else {
    return undefined;
  }
};

export const checkResponseAndReturnList = (object, list, rethrowErrors: boolean) => {
  if (list.error) {
    console.warn('The server responded with a response error: ' + list.error.message);

    if (rethrowErrors === true) {
      throw new ServerError(list.error);
    } else {
      return [];
    }
  }

  if (!list || !list.data) {
    return [];
  }

  let data = list.data.map((o) => Object.assign(new object(), o));

  data = data.map((o) => {
    if (o.object === 'orderForm') {
      return handleOrderForm(o);
    } else if (o.object === 'trucksInYard') {
      return handleTrucksInYard(o);
    } else if (o.object === 'quote') {
      return handleQuote(o);
    } else if (o.object === 'product') {
      return handleProduct(o);
    } else if (o.object === 'plantForm') {
      return handlePlantForm(o);
    } else {
      return o;
    }
  });

  return data;
};
