import { Moment } from 'moment';
import { convertIso8601ToDate } from '@app/shared/utilities/date-helpers.utility';

export class OrderUpdateModel {
  id: number;
  orderStatusId: number;
  schedules: OrderUpdateModelSchedule[];
  plantId: number;
  customerName: string;
  dispatchNotes: string;
  orderFlagId: number;
  number: number;

  private _shipDate: Moment; // datetime NOT NULL,
  get shipDate(): Moment {
    return this._shipDate;
  }
  set shipDate(val) {
    this._shipDate = convertIso8601ToDate(val);
  }
}

export class OrderUpdateModelSchedule {
  id: number;
  plantId: number;
  primaryProductQuantity: number;
  private _firstLoadOnJobTime: Moment; // datetime NULL,
  get firstLoadOnJobTime(): Moment {
    return this._firstLoadOnJobTime;
  }
  set firstLoadOnJobTime(val) {
    this._firstLoadOnJobTime = convertIso8601ToDate(val);
  }
}
