import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlSafe'
})
@Injectable({
  providedIn: 'root'
})
export class HtmlSafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(value: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
