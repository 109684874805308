import { AO_SYSTEM_TYPE_FORM_MAP, ORDER_SYSTEM_TYPE_FORM_MAP } from '@app/forms/shared/form-maps';
import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { Form } from '@app/forms/form.model';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

@Injectable({ providedIn: 'root' })
export class UriService {
  constructor(private log: Logger) {}

  public static getUrlFromString(urlString: string): URL {
    try {
      const url = new URL(urlString);
      return url;
    } catch {
      console.error(`Invalid url string ${urlString}`);
      return undefined;
    }
  }

  public getQueryParamsObjectFromUrl(url: string): object {
    const queryString = this.getQueryStringFromUrl(url);

    return this.convertQuerysringToObject(queryString);
  }

  public convertQuerysringToObject(queryString: string): object {
    const queryObject = {};
    if (!queryString) {
      return queryObject;
    }

    const parts = queryString.split('&');
    parts.forEach((value) => {
      const pair = value.split('=');
      queryObject[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    });

    return queryObject;
  }

  public getQueryStringFromUrl(url: string): string {
    if (!url) {
      return '';
    }

    const indexOfQ = url.indexOf('?');
    if (indexOfQ >= 0) {
      return url.substring(indexOfQ + 1);
    } else {
      return '';
    }
  }

  public appendParameterToUrl(url: string, key: string, value: string): string {
    if (!url || !key) {
      return url;
    }

    if (url.indexOf('?') === -1) {
      url += '?';
    } else {
      url += '&';
    }

    url += `${key}=${encodeURIComponent(value)}`;

    return url;
  }

  public processFormFromUrl(url: string, systemTypeParam: string): Form {
    if (url.includes('orders/')) {
      const type = this.getQueryParamsObjectFromUrl(url)[systemTypeParam];

      if (!type) {
        this.log.warn(`Invalid Hyperlink for order. No systemType param available ${url}`);
        return undefined;
      }

      const form = url.includes('audit')
        ? AO_SYSTEM_TYPE_FORM_MAP.get(type.toString())
        : ORDER_SYSTEM_TYPE_FORM_MAP.get(type.toString());

      if (!hasValue(form)) {
        this.log.error(`Invalid Hyperlink ${url}`);
        throw Error('Invalid hyperlink cannot navigate to item');
      }

      return form;
    } else if (url.includes('products/maintenance')) {
      return AUJS_FORMS.FormNgProductMaintenance;
    } else if (url.includes(AUJS_FORMS.FormNgEditTickets.path)) {
      return AUJS_FORMS.FormNgEditTickets;
    } else if (url.includes(AUJS_FORMS.FormNgTruckMaintenance.path)) {
      return AUJS_FORMS.FormNgTruckMaintenance;
    } else if (url.includes(AUJS_FORMS.FormNgCustomerMaintenance.path)) {
      return AUJS_FORMS.FormNgCustomerMaintenance;
    } else if (url.includes(AUJS_FORMS.FormNgEmployeeMaintenance.path)) {
      return AUJS_FORMS.FormNgEmployeeMaintenance;
    } else if (url.includes(AUJS_FORMS.FormNgQuoteMaintenance.path)) {
      return AUJS_FORMS.FormNgQuoteMaintenance;
    }

    return undefined;
  }
}
