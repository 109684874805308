import { IModal } from '@app/shared/dialogs//modal.interface';
import { IModalButton } from '@app/shared/dialogs/modal-button.interface';
import { IStatusItem } from '@app/shared/dialogs/status-item.interface';
import { MatDialogRef } from '@angular/material/dialog';

export class Modal implements IModal {
  public autoCloseDelay: number;
  public buttons: Array<IModalButton>;
  public isOpen: boolean;
  public legacyFailed: boolean;
  public modalInstance: MatDialogRef<any>;
  public parent: IModal;
  public result: any;
  public statusItems: Array<IStatusItem>;
  public title: string;

  constructor(title: string) {
    this.autoCloseDelay = 500;
    this.buttons = [];
    this.isOpen = false;
    this.modalInstance = null;
    this.parent = null;
    this.result = null;
    this.statusItems = [];
    this.title = title;
  }

  public addButton(button: IModalButton): IModalButton {
    const index = this.buttons.indexOf(button);
    if (index === -1) {
      button.parentModal = this;
      this.buttons.push(button);
    }
    return button;
  }

  public close(): void {
    if (this.modalInstance) {
      this.modalInstance.close();
    }
    this.isOpen = false;
  }

  public getDataObject(): any {
    return {
      autoCloseDelay: this.autoCloseDelay,
      buttons: this.buttons,
      parent: this,
      statusItems: this.statusItems,
      title: this.title
    };
  }

  public reCheckStatus(): boolean {
    if (typeof this.modalInstance?.componentInstance?.reCheckStatus === 'function') {
      this.modalInstance.componentInstance.reCheckStatus();
      return true;
    }

    return false;
  }

  public removeButton(button: IModalButton): void {
    const index = this.buttons.indexOf(button);
    if (index >= 0) {
      this.buttons.splice(index, 1);
    }
  }
}
