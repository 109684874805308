import { Moment } from 'moment';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';

export class AggregateQuote {
  id: number;
  freeShortLoadId: number;
  nonworkdayChargeFlag: boolean;
  object: string;
  priceAdjustment: number;
  priceAdjustmentPercentFlag: boolean;
  printMaterialTypeId: number;
  salesmanEmployeeId: number;
  salesPlantId: number;
  shortLoadTypeId: number;
  standbyChargeTypeId: number;
  standbyMinPerQty: number;
  standbyPrice: number;
  requireQuotedDelChargeFlag: boolean;
  overrideFuelSurchargeFlag: boolean;
  termsDiscountId: number;
  salesmanName: string;

  private _startDate: Moment;
  get startDate(): Moment {
    return this._startDate;
  }
  set startDate(val) {
    this._startDate = convertIso8601ToDate(val);
  }

  private _terminationDate: Moment;
  get terminationDate(): Moment {
    return this._terminationDate;
  }
  set terminationDate(val) {
    this._terminationDate = convertIso8601ToDate(val);
  }

  constructor() {}

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}
