import { Observable, Observer } from 'rxjs';

export class ConnectionStatus {
  disconnected: boolean;
  reconnecting: boolean;

  constructor() {
    this.disconnected = false;
    this.reconnecting = false;
  }

  /**
   * Determine if the hub is connected
   *
   * @return boolean
   */
  public isConnected(): boolean {
    return !this.disconnected && !this.reconnecting;
  }

  /**
   * Is connection disconnected
   *
   * @return boolean
   */
  public isDisconnected(): boolean {
    return this.disconnected;
  }

  /**
   * Determine if the hub connection is down
   *
   * @return boolean
   */
  public isDown(): boolean {
    return this.disconnected || this.reconnecting;
  }

  /**
   * Is Reconnecting
   *
   * @return boolean
   */
  public isReconnecting(): boolean {
    return this.reconnecting;
  }

  /**
   * Update the connection status
   */
  public setConnection(
    reconnectVal: boolean,
    disconnectVal: boolean
  ): Observable<ConnectionStatus> {
    return new Observable<ConnectionStatus>((observer: Observer<ConnectionStatus>) => {
      this.reconnecting = reconnectVal;
      this.disconnected = disconnectVal;
      observer.next(this);
      observer.complete();
    });
  }
}
